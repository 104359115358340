'use strict';

const componentController = require('./alteracao-dados.controller');
const componentApi = require('./alteracao-dados.service');
const routesConfiguration = require('./alteracao-dados.routes');

const formControlService = require('./alteracao-dados.form.service');

const detailController = require('./alteracao-dados-detail/detail.controller');
const detailApiService = require('./alteracao-dados-detail/detail.service');

const newController = require('./alteracao-dados-new/new.controller');
const newApiService = require('./alteracao-dados-new/api.service');

const uploadController = require('./alteracao-dados-annex/upload.controller');
const uploadApiService = require('./alteracao-dados-annex/api.service');

const alteracaoImovelController = require('./alteracao-dados-data/alteracao-imovel.controller');
const alteracaoImovelService = require('./alteracao-dados-data/alteracao-imovel.service');

const mudancaLocadorController = require('./alteracao-dados-data/mudanca-locador.controller');
const mudancaLocadorService = require('./alteracao-dados-data/mudanca-locador.service');

const alteracaoDadosClienteController = require('./alteracao-dados-data/alteracao-dados-cliente.controller');
const alteracaoDadosClienteService = require('./alteracao-dados-data/alteracao-dados-cliente.service');

const transferenciaTitularidadeController = require('./alteracao-dados-data/transferencia-titularidade.controller');
const transferenciaTitularidadeService = require('./alteracao-dados-data/transferencia-titularidade.service');

const inclusaoCaucaoController = require('./alteracao-dados-data/inclusao-caucao.controller');
const inclusaoCaucaoService = require('./alteracao-dados-data/inclusao-caucao.service');

const formValidationService = require('./alteracao-dados-form-validation.service');

const alteracaoDadosSetupAccountService = require('./alteracao-dados-setup-account/alteracao-dados-setup-account.service');

const componentModule = angular
	.module('cap.secured.case.alteracao-dados', ['ui.router'])
	.controller('alteracaoDadosController', componentController)
	.service('alteracaoDadosApi', componentApi)

	.service('alteracaoDadosFormValidationService', formValidationService)

	.service('alteracaoDadosFormControlService', formControlService)

	.controller('alteracaoDadosDetailController', detailController)
	.service('alteracaoDadosDetailApi', detailApiService)

	.controller('alteracaoDadosNewController', newController)
	.service('alteracaoDadosNewApi', newApiService)

	.controller('alteracaoImovelController', alteracaoImovelController)
	.service('alteracaoImovelService', alteracaoImovelService)

	.controller('mudancaLocadorController', mudancaLocadorController)
	.service('mudancaLocadorService', mudancaLocadorService)

	.controller('alteracaoDadosClienteController', alteracaoDadosClienteController)
	.service('alteracaoDadosClienteService', alteracaoDadosClienteService)

	.controller('transferenciaTitularidadeController', transferenciaTitularidadeController)
	.service('transferenciaTitularidadeService', transferenciaTitularidadeService)

	.controller('inclusaoCaucaoController', inclusaoCaucaoController)
	.service('inclusaoCaucaoService', inclusaoCaucaoService)

	.service('alteracaoDadosSetupAccountService', alteracaoDadosSetupAccountService)

	.controller('alteracaoDadosAnnexController', uploadController)
	.service('alteracaoDadosAnnexApi', uploadApiService)

	.config(routesConfiguration);

module.exports = componentModule;
