'use strict';

class propostaPaymentController {
	/* @ngInject */
	constructor(
		$scope,
		$rootScope,
		$uibModal,
		$window,
		$timeout,
		propostaPaymentService,
		formControlService,
		validationFormFields,
		autoCompleteService,
		propostaSetupAccountService,
		sfConfig,
		dadosBancariosHelper,
		accountUtilService,
		crivoHelper,
		feedbackMessages
	) {
		console.log('propostaPaymentController');

		this.propostaPaymentService = propostaPaymentService;
		this.formControl = formControlService;
		this.formField = this.formControl.field;
		this.valid = validationFormFields;
		this.autoComplete = autoCompleteService;
		this.messages = feedbackMessages;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$window = $window;
		this.propostaSetupAccountService = propostaSetupAccountService;
		this.$uibModal = $uibModal;
		this.sfConfig = sfConfig;
		this.dadosBancariosHelper = dadosBancariosHelper;
		this.accountUtilService = accountUtilService;
		this.crivoHelper = crivoHelper;
		this.parametrosValidados = null;
		this.dataClienteOK = false;
		this.showDocumentoProprietarioCartao = true;
		this.erroParcelas = null;
		this.responseBestDateService = null;

		this.required = {};
		this.validated = {};
		this.parcelaSelecionada = null;

		this.bankListDebit = this.propostaPaymentService.getBankList();
		this.bestDayListDebit = this.propostaPaymentService.getBestDayList();
		this.formField.formStep = 'proposta.payment';

		this.debito = 'Débito em Conta Corrente';

		if (this.isOrigemResgate() || this.formField.paymentType == 'Recuperação de Crédito') {
			this.choosePayment('Recuperação de Crédito');
			this.formField.stepStatusPropostaPayment = 'Concluído';
			this.cleanDebitFields();
		}

		// Forma de pagamento padrão DEBITO EM CONTA
		// desativado para subida de DCC
		//if (this.formField.Id == null && this.formField.paymentType == null) {
		//	this.choosePayment(this.debito);
		//}

		// Forma de pagamento padrão BOLETO
		// ativado para subida de DCC
		if (this.formField.paymentType == null || this.formField.paymentType == this.debito) {
			this.choosePayment('Boleto');
			this.cleanDebitFields();
		}

		this.checkIfHasUserInfo();
		this.setFromPropostaPayment(false);
		this.listenRequiredRequest();
		this.listenValidationRequest();
		this.listenEditBestDayDebit();
		this.checkOnLoadRequiredFields();
		this.getParcelas();

		console.log('TODOS OS CAMPOS PÓS TRATAMENTOS', this.formField);

		//NUM_QTD_TITULOS_1__c*NUM_VAL_UNITARIO_1__c+NUM_QTD_TITULOS_2__c*NUM_VAL_UNITARIO_2__c+NUM_QTD_TITULOS_3__c*NUM_VAL_UNITARIO_3__c

		/*
		objProposta.NUM_QTD_TITULOS_1__c                = request.propostaSave.title1 != null ? request.propostaSave.title1.titleSQuantity  : null;           
		objProposta.NUM_QTD_TITULOS_2__c                = request.propostaSave.title2 != null ? request.propostaSave.title2.titleSQuantity  : null;  
		objProposta.NUM_QTD_TITULOS_3__c                = request.propostaSave.title3 != null ? request.propostaSave.title3.titleSQuantity  : null;    
		objProposta.NUM_VAL_UNITARIO_1__c               = request.propostaSave.title1 != null ? request.propostaSave.title1.value           : null;          
		objProposta.NUM_VAL_UNITARIO_2__c               = request.propostaSave.title2 != null ? request.propostaSave.title2.value           : null;         
		objProposta.NUM_VAL_UNITARIO_3__c               = request.propostaSave.title3 != null ? request.propostaSave.title3.value           : null;   
		*/
	}

	listenValidationRequest() {
		this.$scope.$on('stepValidate', () => {
			this.$scope.$emit('stepValidateResponse', this.formValidation());
		});
	}

	getParametersForValidBank() {
		const parameter = {};
		parameter.tipoConta = 'Conta Corrente';
		parameter.banco = this.formField.bankDebit;
		parameter.agencia = this.formField.agencyDebit;
		parameter.agenciaDV = this.formField.agencyDVDebit;
		parameter.conta = this.formField.accountNumberDebit;
		parameter.contaDV = this.formField.accountNumberDVDebit;
		parameter.cpfCnpj = this.formField.accountOwnerCpfCnpjDebit;
		return parameter;
	}

	setFromPropostaPayment(value) {
		this.propostaSetupAccountService.setFromPropostaPayment(value);
	}

	clearFieldsDebit() {
		this.formField.agencyDebit = null;
		this.formField.agencyDVDebit = null;
		this.formField.accountNumberDebit = null;
		this.formField.accountNumberDVDebit = null;
	}

	choosePayment(paymentType) {
		this.formField.paymentType = paymentType;

		if (paymentType != 'CartaoCredito') {
			this.formField.showDocumentoProprietarioCartao = false;
			this.formField.cpfTitularCartao = null;
			this.formField.nomeTitularCartao = null;
			this.formField.cartaoValorCobradoTotal = null;
		} else {
			this.formField.showDocumentoProprietarioCartao = true;
		}
	}

	callForValidDataBank() {
		if (angular.equals(this.parametrosValidados, this.getParametersForValidBank())) {
			return;
		}

		this.parametrosValidados = this.getParametersForValidBank();

		const { tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV } = this.parametrosValidados;

		const isValid = this.hasField(tipoConta) && this.hasField(banco) && this.hasField(agencia) && this.hasField(conta) && this.hasField(contaDV);

		if (!isValid) {
			return;
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.dadosBancariosHelper.consultaDadosBancariosSeValidosPromisse(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV).then((response) => {
			this.applyErrorBankFields(response);
			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	defineMessage(message) {
		this.messages.showErrorMessage(this.$scope, message);
	}

	isValidNotBankSuccess() {
		const hasLimitExceeded = this.dadosBancariosHelper.remoteCallErrorLimitWasExceded();
		const message = hasLimitExceeded ? '' : this.dadosBancariosHelper.remoteCallErrorMessage;
		const hasError = hasLimitExceeded ? false : true;

		this.defineMessage(message);
		this.setErrorBankField(hasError);
	}

	isValidBankSuccess(response) {
		if (response.isValid) {
			this.defineMessage('');
			this.setErrorBankField(false);
		} else {
			this.setErrorBankField(true);
		}
	}

	applyErrorBankFields(response) {
		const isSuccessful = response.isSuccessful;

		if (isSuccessful) {
			this.isValidBankSuccess(response);
		} else {
			this.isValidNotBankSuccess();
		}

		this.dadosBancariosHelper.setPristine();
	}

	setErrorBankField(value) {
		this.validated.hasAgencyPeopleError = value;
		this.validated.agencyDebitError = value;
		this.validated.hasAgencyDVPeopleError = value;
		//this.validated.agencyDVDebitError = value;
		this.validated.hasAccountNumberPeopleError = value;
		this.validated.accountNumberDebitError = value;
		this.validated.hasAccountNumberDVPeopleError = value;
		this.validated.accountNumberDVDebitError = value;
	}

	listenRequiredRequest() {
		this.$scope.$on('stepRequired', () => {
			this.$scope.$emit('stepRequiredResponse', this.formRequired());
			console.log('Step Required - Tela Proposta Payment - ', this.formRequired());
		});
	}

	listenEditBestDayDebit() {
		this.$scope.$on('bestDayDebit', () => {
			this.formField.bestDayDebit = this.responseBestDateService.bestDayDebit;
			this.formField.dateVencDebit = this.responseBestDateService.bestDateDebit;
		});
	}

	isOrigemResgate() {
		if (this.formField.isOrigemResgate != null) {
			return this.formField.isOrigemResgate;
		} else {
			return false;
		}
	}

	isFormPristine() {
		return this.formField.stepStatusPropostaPayment === 'Não Iniciado';
	}

	checkOnLoadRequiredFields() {
		// console.log('checkOnLoadRequiredFields');

		/*if (this.checkIfHasUserInfo()) {
			this.formField.stepStatusPropostaPayment = 'Não Iniciado';
		}*/

		if (!this.isFormPristine() && this.formRequired()) {
			return;
		}

		this.formField.stepStatusPropostaPayment = 'Incompleto';
	}

	resetRequiredErrors() {
		const required = this.required;
		required.paymentError = false;
		required.checkAuthorizationDebitError = false;
		required.bankDebitError = false;
		required.agencyDebitError = false;
		//required.agencyDVDebitError = false;
		required.accountNumberDebitError = false;
		required.accountNumberDVDebitError = false;
		required.accountOwnerNameDebitError = false;
		required.accountOwnerCpfCnpjDebitError = false;
		required.bestDayDebitError = false;
		required.checkAuthorizationOwnerDebitError = false;
		required.cartaoParcelaError = false;
		required.cartaoOwnerError = false;
		required.subNationalityError = false;
		required.subSecondNationalityError = false;
		required.subBirthCountryError = false;
		required.degreeOfKinshipError = false;
		required.subProfessionError = false;
		required.subDeclarationCodeError = false;
		required.subResourceOriginError = false;
		required.resourceOriginOutrosSubsError = false;
	}

	resetValidErrors() {
		this.validated.checkAuthorizationDebitError = false;
		this.validated.checkAuthorizationOwnerDebitError = false;
		this.validated.agencyDebitError = false;
		//this.validated.agencyDVDebitError = false;
		this.validated.accountNumberDebitError = false;
		this.validated.accountNumberDVDebitError = false;
		this.validated.cartaoParcelaError = false;
		this.validated.cartaoOwnerError = false;
	}

	changeBestDay() {
		this.$scope.$emit('changeMainShowLoading', true);
		this.propostaPaymentService.getBestDayService(this.formField.bestDayDebit).then((response) => {
			this.responseBestDateService = response;

			const showPopup = response.showPopup;
			this.formField.dateVencDebit = response.bestDateDebitClient;

			if (showPopup) {
				this.dataClienteOK = response.bestDateDebitClient === response.bestDateDebit;
				let message = `Seu pagamento foi programado para ${response.bestDateDebitClient}.`;
				if (!this.dataClienteOK)
					message = `${message} Deseja manter esta data ou alterar para a data mais próxima disponível, ${response.bestDateDebit}? Caso o vencimento seja em um final de semana ou feriado, o débito ocorrerá no próximo dia útil.`;
				$('#propostaBestDateDebitMessage').html(message);
				$('#keepBetterDay').modal({ backdrop: 'static', keyboard: false });
			}

			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem('tipo_lista');
		if (propostaType == 'D') {
			return true;
		} else {
			return false;
		}
	}

	getParcelas() {
		console.log('----> iniciando busca de parcelas');

		console.log('----> this.formField completo:', this.formField);

		let valorTotal = 0;
		let valorTitulo = 0;

		console.log('----> this.formField.title1:', this.formField.title1);
		console.log('----> this.formField.title12:', this.formField.title2);
		console.log('----> this.formField.title3:', this.formField.title3);

		if (this.formField.title1 != null) {
			valorTotal = valorTotal + this.formField.title1.titleSQuantity * this.formField.title1.value;
			valorTitulo = valorTitulo + this.formField.title1.titleSQuantity * this.formField.title1.value;
		}

		console.log('----> valorTotal:', valorTotal);

		if (this.formField.title2 != null) {
			valorTotal = valorTotal + this.formField.title2.titleSQuantity * this.formField.title2.value;
			valorTitulo = valorTitulo + this.formField.title1.titleSQuantity * this.formField.title2.value;
		}

		console.log('----> valorTotal:', valorTotal);

		if (this.formField.title3 != null) {
			valorTotal = valorTotal + this.formField.title3.titleSQuantity * this.formField.title3.value;
			valorTitulo = valorTitulo + this.formField.title1.titleSQuantity * this.formField.title3.value;
		}

		console.log('----> valorTotal:', valorTotal);

		if (this.formField.DG != null && this.formField.DG.lifeTermValue != null && this.formField.DG.insuranceTerm != null) {
			valorTotal = valorTotal + this.formField.DG.lifeTermValue;
		}

		console.log('----> valorTotal com dupla garantia:', valorTotal);
		console.log('----> valorTitulo: ', valorTitulo);

		console.log('valor total das parcelas: ', valorTotal);

		this.erroParcelas = null;

		if (!this.isPropostaDuplaGarantia()) {
			this.propostaPaymentService.getParcelas(valorTotal).then((response) => {
				console.log('resultado da busca de parcelas: ', response);

				if (response.status && response.status == 'error') {
					console.log('identificado erro na busca de parcelas');
					this.erroParcelas = response.message;
				} else {
					this.parcelasResultado = response.resultado;
					this.parcelas = [];

					for (const p of response.resultado) {
						//p.label = 'R$ ' + p.valorCobrado + ' - ' + p.numeroQtdParcelas + 'x de R$ ' + p.valorParcela;
						if (p.numeroQtdParcelas > 1) p.label = `${p.numeroQtdParcelas}x R$ ${p.valorParcela}  (R$ ${p.valorCobrado})`;
						else p.label = `à vista R$ ${p.valorParcela}  (R$ ${p.valorCobrado})`;

						this.parcelas.push(p);
					}

					console.log('this.parcelas: ', this.parcelas);
				}
			});
		} else {
			this.propostaPaymentService.getParcelasDG(valorTitulo, this.formField.DG.lifeTermValue).then((response) => {
				if (response.code != 200) {
					console.log('identificado erro na busca de parcelas');
					this.erroParcelas = response.mensagem;
				} else {
					this.parcelasResultado = response.parcelas;
					this.parcelas = [];

					for (const p of response.parcelas) {
						//p.label = 'R$ ' + p.valorCobrado + ' - ' + p.numeroQtdParcelas + 'x de R$ ' + p.valorParcela;
						if (p.numeroParcela > 1) p.label = `${p.numeroParcela}x     R$ ${p.somaValorUnitario}     R$ ${p.somaValorTotal}`;
						else p.label = `à vista R$ ${p.somaValorUnitario}     R$ ${p.somaValorTotal}`;

						this.parcelas.push(p);
					}
				}
			});
		}
	}

	chooseOwnerSubscriber(ownerSubscriber) {
		console.log('ownerSubscriber: ', ownerSubscriber);
		if (ownerSubscriber == 'S') {
			this.formField.showDocumentoProprietarioCartao = true;
		} else {
			this.formField.showDocumentoProprietarioCartao = false;
		}
	}

	isDebito() {
		return this.formField.paymentType == this.debito;
	}

	setAccountOwnerSameProspect() {
		const formField = this.formField;
		const isSame = formField.isAccountOwnerDebitSameProspect;
		formField.accountOwnerNameDebit = isSame ? formField.account.Name : null;
		formField.accountOwnerCpfCnpjDebit = isSame ? formField.account.cpf : null;

		if (formField.accountOwnerNameDebit != null) {
			this.required.accountOwnerNameDebitError = false;
		}

		if (formField.accountOwnerCpfCnpjDebit != null) {
			this.required.accountOwnerCpfCnpjDebitError = false;
		}
	}

	cleanDebitFields() {
		const formField = this.formField;

		formField.checkAuthorizationDebit = null;
		formField.bankDebit = null;
		formField.agencyDebit = null;
		formField.agencyDVDebit = null;
		formField.accountNumberDebit = null;
		formField.accountNumberDVDebit = null;
		formField.accountOwnerNameDebit = null;
		formField.accountOwnerCpfCnpjDebit = null;
		formField.bestDayDebit = null;
		formField.dateVencDebit = null;
		formField.checkAuthorizationOwnerDebit = null;
		formField.isAccountOwnerDebitSameProspect = null;

		this.resetRequiredErrors();
	}

	formValidation() {
		let validFieldsOk = true;
		const formField = this.formField;

		if (this.formField.stepStatusPropostaPayment != 'Incompleto') {
			this.resetValidErrors();
		}

		const isValidCheckAuthorizationDebit = this.isValidCheckAuthorizationDebit();
		const isValidCheckAuthorizationOwnerDebit = this.isValidCheckAuthorizationOwnerDebit();
		const isDebito = this.formField.paymentType == this.debito;
		const isCredito = this.formField.paymentType == 'CartaoCredito';
		/*const isValidSubscriber = this.requireSubscriber();
		const isValidSubNationality = this.requireSubNationality();
		const isValidSubSecondNationality = this.requireSubSecondNationality();
		const isValidSubBirthCountry = this.requireSubBirthCountry();
		const isValidSubDegreeOfKinship = this.requireSubDegreeOfKinship();
		const isValidSubProfessionPF = this.requireProfessionPF();
		const isValidSubProfessionPJ = this.requireProfessionPJ();
		const isValidSubDeclarationPF = this.requireDeclarationCodePF();
		const isValidSubDeclarationPJ = this.requireDeclarationCodePJ();*/

		//Validation bank block

		const isValidAgencyPeople = isDebito ? !this.validated.hasAgencyPeopleError : true;
		const isValidAgencyDVPeople = isDebito ? !this.validated.hasAgencyDVPeopleError : true;
		const isValidAccountNumberPeople = isDebito ? !this.validated.hasAccountNumberPeopleError : true;
		const isValidAccountNumberDVPeople = isDebito ? !this.validated.hasAccountNumberDVPeopleError : true;

		//const isOwnerCartaoOk = (isCredito == true && !formField.showDocumentoProprietarioCartao) || (isCredito == true && formField.showDocumentoProprietarioCartao && formField.cpfTitularCartao != null && formField.nomeTitularCartao != null);
		//const isCartaoParcelaOk = (isCredito == true && formField.parcelaSelecionada != null && formField.parcelaSelecionada > 0);

		//Verica of campos do subscritor
		/*validFieldsOk = (validFieldsOk ? isValidSubscriber : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubNationality : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubSecondNationality : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubBirthCountry : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubDegreeOfKinship : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubProfessionPF : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubProfessionPJ : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubDeclarationPF : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidSubDeclarationPJ : validFieldsOk);*/

		validFieldsOk = validFieldsOk ? isValidCheckAuthorizationDebit : validFieldsOk;
		validFieldsOk = validFieldsOk ? isValidCheckAuthorizationOwnerDebit : validFieldsOk;
		console.log('isCredito? ', isCredito);
		console.log('Valid Fields: ', validFieldsOk);
		console.log('FormField: ', this.formField);

		return validFieldsOk;
	}

	isValidCheckAuthorizationDebit() {
		let isOk = true;
		if (this.hasField(this.formField.checkAuthorizationDebit)) {
			const formField = this.formField;
			const isCheckTrue = formField.checkAuthorizationDebit;
			const isDebitTrue = formField.paymentType == this.debito;
			isOk = isCheckTrue && isDebitTrue;
			this.validated.checkAuthorizationDebitError = !isOk;
		}
		return isOk;
	}

	isValidCheckAuthorizationOwnerDebit() {
		let isOk = true;
		if (this.hasField(this.formField.checkAuthorizationOwnerDebit)) {
			const formField = this.formField;
			const isCheckTrue = this.formField.checkAuthorizationOwnerDebit;
			const isDebitTrue = formField.paymentType == this.debito;
			isOk = isCheckTrue && isDebitTrue;
			this.validated.checkAuthorizationOwnerDebitError = !isOk;
		}
		return isOk;
	}

	isPayment(payment) {
		return this.formField.paymentType == payment;
	}

	formRequired() {
		this.resetRequiredErrors();

		let formOk = true;

		const formField = this.formField;

		const isPaymentOk = this.hasField(formField.paymentType);
		const isBoleto = formField.paymentType == 'Boleto';
		const isRecuperacaoCredito = formField.paymentType == 'Recuperação de Crédito';
		const isCheckAuthorizationDebitOk = this.hasField(formField.checkAuthorizationDebit) || isBoleto || isRecuperacaoCredito;
		const isBankDebitOk = this.hasField(formField.bankDebit) || isBoleto || isRecuperacaoCredito;
		const isAgencyDebitOk = this.hasField(formField.agencyDebit) || isBoleto || isRecuperacaoCredito;
		//const isAgencyDVDebitOk = this.hasField(formField.agencyDVDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountNumberDebitOk = this.hasField(formField.accountNumberDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountNumberDVDebitOk = this.hasField(formField.accountNumberDVDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountOwnerNameDebitOk = this.hasField(formField.accountOwnerNameDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountOwnerCpfCnpjDebitOk = this.hasField(formField.accountOwnerCpfCnpjDebit) || isBoleto || isRecuperacaoCredito;
		const isBestDayDebitOk = this.hasField(formField.bestDayDebit) || isBoleto || isRecuperacaoCredito;
		const isCheckAuthorizationOwnerOk = this.hasField(formField.checkAuthorizationOwnerDebit) || isBoleto || isRecuperacaoCredito;

		const isOwnerCartaoOk = !formField.showDocumentoProprietarioCartao || (formField.showDocumentoProprietarioCartao && formField.cpfTitularCartao != null && formField.nomeTitularCartao != null);
		const isCartaoParcelaOk = formField.paymentType == 'CartaoCredito' && formField.parcelaSelecionada != null && formField.parcelaSelecionada > 0;
		const req = this.required;

		//Aplica os erros de obrigatoriedade do campo subscritor
		const isSubscriberOk = this.requireSubscriber();
		const isSubNatOk = this.requireSubNationality();
		const isSubSecNacOki = this.requireSubSecondNationality();
		const isSubBirthCountryOk = this.requireSubBirthCountry();
		const isSubDegreeOfKinshipOk = this.requireSubDegreeOfKinship();
		const isSubProfessionPFOk = this.requireProfessionPF();
		const isSubProfessionPJOk = this.requireProfessionPJ();
		const isSubDeclarationPFOk = this.requireDeclarationCodePF();
		const isSubDeclarationPJOk = this.requireDeclarationCodePJ();
		const isSubResourceOriginOk = this.requireSubResourceOrigin();
		const isSubResourceOriginOutroOk = this.requireSubResourceOriginOutros();

		formOk = formOk ? isSubscriberOk : formOk;
		formOk = formOk ? isSubNatOk : formOk;
		formOk = formOk ? isSubSecNacOki : formOk;
		formOk = formOk ? isSubBirthCountryOk : formOk;
		formOk = formOk ? isSubDegreeOfKinshipOk : formOk;
		formOk = formOk ? isSubProfessionPFOk : formOk;
		formOk = formOk ? isSubProfessionPJOk : formOk;
		formOk = formOk ? isSubDeclarationPFOk : formOk;
		formOk = formOk ? isSubDeclarationPJOk : formOk;
		formOk = formOk ? isSubResourceOriginOk : formOk;
		formOk = formOk ? isSubResourceOriginOutroOk : formOk;
		console.log('isSubscriberOk ', isSubscriberOk);
		console.log('isSubNatOk ', isSubNatOk);
		console.log('isSubSecNacOki ', isSubSecNacOki);
		console.log('isSubBirthCountryOk ', isSubBirthCountryOk);
		console.log('isSubDegreeOfKinshipOk ', isSubDegreeOfKinshipOk);
		console.log('isSubProfessionPFOk ', isSubProfessionPFOk);
		console.log('isSubProfessionPJOk ', isSubProfessionPJOk);
		console.log('isSubDeclarationPFOk ', isSubDeclarationPFOk);
		console.log('isSubDeclarationPJOk ', isSubDeclarationPJOk);
		console.log('isSubResourceOriginOk', isSubResourceOriginOk);
		console.log('isSubResourceOriginOutroOk ', isSubResourceOriginOutroOk);

		req.paymentError = this.applyError(isPaymentOk);
		req.checkAuthorizationDebitError = this.applyError(isCheckAuthorizationDebitOk);
		req.bankDebitError = this.applyError(isBankDebitOk);
		req.agencyDebitError = this.applyError(isAgencyDebitOk);
		//req.agencyDVDebitError = this.applyError(isAgencyDVDebitOk);
		req.accountNumberDebitError = this.applyError(isAccountNumberDebitOk);
		req.accountNumberDVDebitError = this.applyError(isAccountNumberDVDebitOk);
		req.accountOwnerNameDebitError = this.applyError(isAccountOwnerNameDebitOk);
		req.accountOwnerCpfCnpjDebitError = this.applyError(isAccountOwnerCpfCnpjDebitOk);
		req.bestDayDebitError = this.applyError(isBestDayDebitOk);
		req.checkAuthorizationOwnerDebitError = this.applyError(isCheckAuthorizationOwnerOk);
		req.ownerCartaoError = this.applyError(isOwnerCartaoOk);
		req.ownerCartaoParcelaError = this.applyError(isCartaoParcelaOk);

		formOk = formOk ? isPaymentOk : formOk;

		if (!isBoleto) {
			//formOk = (formOk ? isCheckAuthorizationDebitOk : formOk);
			//formOk = (formOk ? isBankDebitOk : formOk);
			//formOk = (formOk ? isAgencyDebitOk : formOk);
			//formOk = (formOk ? isAgencyDVDebitOk : formOk);
			//formOk = (formOk ? isAccountNumberDebitOk : formOk);
			//formOk = (formOk ? isAccountNumberDVDebitOk : formOk);
			//formOk = (formOk ? isAccountOwnerNameDebitOk : formOk);
			//formOk = (formOk ? isAccountOwnerCpfCnpjDebitOk : formOk);
			//formOk = (formOk ? isBestDayDebitOk : formOk);
			//formOk = (formOk ? isCheckAuthorizationOwnerOk : formOk);
			formOk = formOk ? isOwnerCartaoOk : formOk;
			formOk = formOk ? isCartaoParcelaOk : formOk;
			console.log('isOwnerCartaoOk ', isOwnerCartaoOk);
			console.log('isCartaoParcelaOk ', isCartaoParcelaOk);
		}

		console.log('REQUIRED FORM: ', req);
		console.log('FormOK ? ', formOk);
		this.formField.stepStatusPropostaPayment = formOk ? 'Concluido' : 'Incompleto';

		return formOk;
	}

	requireSubNationality() {
		if (this.hasAccount('true')) {
			if (this.isPF('true')) {
				if (!!this.formField.subscriber.nationality) {
					console.log('Nacionalidade OK');
					this.required.subNationalityError = false;
					return true;
				} else {
					console.log('Nacionalidade do Subscritor obrigatório');
					this.required.subNationalityError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireSubSecondNationality() {
		if (this.hasAccount('true')) {
			if (this.isPF('true')) {
				if (this.formField.subscriber.secNac == 'true') {
					if (!!this.formField.subscriber.secondNationality) {
						console.log('Segunda nacionalidade OK');
						this.required.subSecondNationalityError = false;
						return true;
					} else {
						console.log('Segunda nacionalidade do Subscritor obrigatório');
						this.required.subSecondNationalityError = true;
						return false;
					}
				} else {
					this.required.subSecondNationalityError = false;
					return true;
				}
			}
		}
		return true;
	}

	requireSubBirthCountry() {
		if (this.hasAccount('true')) {
			if (this.isPF('true')) {
				if (!!this.formField.subscriber.birthCountry) {
					console.log('Pais nascimento OK');
					this.required.subBirthCountryError = false;
					return true;
				} else {
					console.log('Pais nascimento do Subscritor obrigatório');
					this.required.subBirthCountryError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireSubDegreeOfKinship() {
		if (this.hasAccount('true')) {
			if (this.isPF('true')) {
				if (!!this.formField.degreeOfKinship) {
					console.log('Grau de parentesco OK');
					this.required.degreeOfKinshipError = false;
					return true;
				} else {
					console.log('Grau de parentesco do Subscritor obrigatório');
					this.required.degreeOfKinshipError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireProfessionPJ() {
		if (this.hasAccount('true')) {
			if (this.isPJ('true')) {
				if (this.hasField(this.formField.subscriber.profession)) {
					console.log('Profissao PJ OK');
					this.required.subProfessionError = false;
					return true;
				} else {
					console.log('Profissao PJ obrigatório');
					this.required.subProfessionError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireProfessionPF() {
		if (this.hasAccount('true')) {
			if (this.isPF('true')) {
				if (this.hasField(this.formField.subscriber.profession)) {
					console.log('Profissao PF OK');
					this.required.subProfessionError = false;
					return true;
				} else {
					console.log('Profissao PF obrigatório');
					this.required.subProfessionError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireDeclarationCodePJ() {
		if (this.hasAccount('true')) {
			if (this.isPJ('true')) {
				if (this.hasField(this.formField.subscriber.declarationCode)) {
					console.log('Faturamento anual PJ OK');
					this.required.subDeclarationCodeError = false;
					return true;
				} else {
					console.log('Faturamento anual PJ obrigatório');
					this.required.subDeclarationCodeError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireDeclarationCodePF() {
		if (this.hasAccount('true')) {
			if (this.isPF('true')) {
				if (this.hasField(this.formField.subscriber.declarationCode)) {
					console.log('Faturamento anual PF OK');
					this.required.subDeclarationCodeError = false;
					return true;
				} else {
					console.log('Faturamento anual PF obrigatório');
					this.required.subDeclarationCodeError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireSubscriber() {
		if (this.formField.differentFinancialResponsible == 'true') {
			if (this.hasAccount('true')) {
				console.log('Subscritor OK');
				this.required.subscriberError = false;
				return true;
			} else {
				console.log('Subscritor obrigatório');
				this.required.subscriberError = true;
				return false;
			}
		}
		return true;
	}

	hasAccountDeclarationError() {
		return !!this.formField.declarationCode;
	}

	hasAccountProfessionError() {
		return !!this.formField.profession;
	}

	hasSubscriberError() {
		return this.required.subscriberError;
	}

	hasSubNationalityError() {
		return this.required.subNationalityError;
	}

	hasSubSecondNationalityError() {
		return this.required.subSecondNationalityError;
	}

	hasSubBirthCountryError() {
		return this.required.subBirthCountryError;
	}

	hasSubscriberProfessionError() {
		return this.required.subProfessionError;
	}

	hasSubcriberDeclarationError() {
		return this.required.subDeclarationCodeError;
	}

	hasDegreeOfKinshipError() {
		return this.required.degreeOfKinshipError;
	}

	hasField(field) {
		return typeof field !== 'undefined' && field != null && field != '';
	}

	hasErrorParcelCartaoNaoInformado() {
		return this.formField.paymentType == 'CartaoCredito' && (this.formField.parcelaSelecionada == null || this.formField.parcelaSelecionada == '');
	}

	hasErrorOwnerCartao() {
		return (
			this.formField.showDocumentoProprietarioCartao &&
			(this.formField.cpfTitularCartao == null || this.formField.cpfTitularCartao == '' || this.formField.nomeTitularCartao == null || this.formField.nomeTitularCartao == '')
		);
	}

	hasErrorPayment() {
		return this.required.paymentError;
	}

	hasErrorCheckAuthorizationDebit() {
		return this.required.checkAuthorizationDebitError || this.validated.checkAuthorizationDebitError;
	}

	hasErrorBankDebit() {
		return this.required.bankDebitError;
	}

	hasErrorAgencyDebit() {
		return this.required.agencyDebitError || this.validated.agencyDebitError;
	}

	/*
	hasErrorAgencyDVDebit() {
		return this.required.agencyDVDebitError || this.validated.agencyDVDebitError;
	}
	*/

	hasErrorAccountNumberDebit() {
		return this.required.accountNumberDebitError || this.validated.accountNumberDebitError;
	}

	hasErrorAccountNumberDVDebit() {
		return this.required.accountNumberDVDebitError || this.validated.accountNumberDVDebitError;
	}

	hasErrorAccountOwnerNamedDebit() {
		return this.required.accountOwnerNameDebitError;
	}

	hasErrorAccountOwnerCpfCnpjDebit() {
		return this.required.accountOwnerCpfCnpjDebitError;
	}

	hasErrorBestDayDebit() {
		return this.required.bestDayDebitError;
	}

	hasErrorCheckAuthorizationOwnerDebit() {
		return this.required.checkAuthorizationOwnerDebitError || this.validated.checkAuthorizationOwnerDebitError;
	}

	applyError(isValid) {
		return isValid ? false : true;
	}

	consultarCrivo() {
		const me = this;

		console.log('entrou na consulta crivo: ', me.formField.cpfTitularCartao);
		console.log('this.accountUtilService: ', this.accountUtilService);

		const cpf = me.formField.cpfTitularCartao;

		if (this.accountUtilService.isValidCpf(cpf)) {
			me.$scope.$emit('changeMainShowLoading', true);

			me.crivoHelper.getRecaptchaLoginCall().then((recaptchaLogin) => {
				console.log('response recaptchaLogin: ', recaptchaLogin);
				grecaptcha.ready(function () {
					grecaptcha.execute(recaptchaLogin, { action: 'submit' }).then(function (token) {
						console.log('---- 2. GTOKEN: ', token);

						me.crivoHelper.consultarCrivo(cpf, token, (response) => {
							if (!response.isSuccessful) {
								me.messages.showErrorMessage(me.$scope, me.crivoHelper.remoteCallErrorMessage);
								me.cepHelper.setPristine();
							} else {
								console.log('--> consultaCrivo resultado: ', response);
								console.log('--> data de nascimento: ', new Date(response.req_dataNascimento));
								console.log('--> sexo: ', response.req_sexo);

								me.formField.nomeTitularCartao = response.req_nome.slice(0, 42);

								//me.newAccountObj.personal.name.value = response.req_nome.slice(0, 42);
								//me.newAccountObj.personal.birthDate.value = new Date(response.req_dataNascimento);
								//me.newAccountObj.personal.gender.value = response.req_sexo;

								me.messages.showErrorMessage(me.$scope, '');
							}

							me.$scope.$emit('changeMainShowLoading', false);
						});
					});
				});
			});
		} else {
			me.crivoHelper.setPristine();
			me.formField.nomeTitularCartao = null;
			me.messages.showErrorMessage(me.$scope, '');
		}
	}

	searchProprietarioCartaoFocusOut() {
		console.log('focus out!!!');
	}

	searchProprietarioCartaoChanged() {
		console.log('searchProprietarioCartaoChanged!!!');
	}

	selecionarParcela() {
		if (!this.isPropostaDuplaGarantia()) {
			console.log('numeroParcela: ', this.parcelaSelecionada.numeroQtdParcelas);
			console.log('this.parcelasResultado: ', this.parcelasResultado);
			//console.log('this.Id: ', this.Id);

			for (const p of this.parcelasResultado) {
				if (p.numeroQtdParcelas == this.parcelaSelecionada.numeroQtdParcelas) {
					console.log('parcela selecionada encontrada: ', p);
					this.formField.cartaoValorLiquido = p.valorTitulo;
					this.formField.parcelaValor = p.valorParcela;
					this.formField.parcelaSelecionada = p.numeroQtdParcelas;
					this.formField.cartaoValorCobradoTotal = p.valorCobrado;
				}
			}
		} else {
			let valorTitulo = 0;

			if (this.formField.title1 != null) {
				valorTitulo = valorTitulo + this.formField.title1.titleSQuantity * this.formField.title1.value;
			}

			if (this.formField.title2 != null) {
				valorTitulo = valorTitulo + this.formField.title1.titleSQuantity * this.formField.title2.value;
			}

			if (this.formField.title3 != null) {
				valorTitulo = valorTitulo + this.formField.title1.titleSQuantity * this.formField.title3.value;
			}

			console.log('Parcela selecionada: ', this.parcelaSelecionada.numeroParcela);
			console.log('Resultado da parcela: ', this.parcelasResultado);
			//console.log('this.Id: ', this.Id);

			for (const p of this.parcelasResultado) {
				if (p.numeroParcela == this.parcelaSelecionada.numeroParcela) {
					this.formField.cartaoValorLiquido = valorTitulo;
					this.formField.parcelaValor = p.somaValorUnitario;
					this.formField.parcelaSelecionada = p.numeroParcela;
					this.formField.cartaoValorCobradoTotal = p.somaValorTotal;
					this.formField.parcelaSeguroSemJuros = p.detalheParcelaSemJuros.valorParcela;
					this.formField.parcelaCapComJuros = p.detalheParcelaComJuros.valorParcela;
				}
			}
			console.log('Forms atualizado: ', this.formField);
		}
	}

	isPJ(isSubscriber) {
		if (isSubscriber == 'false') {
			return this.hasAccount('false') && this.formField.account.isPersonAccount === false;
		} else {
			return this.hasAccount('true') && this.formField.subscriber.isPersonAccount === false;
		}
	}

	isPF(isSubscriber) {
		if (isSubscriber == 'false') {
			return this.hasAccount('false') && this.formField.account.isPersonAccount === true;
		} else {
			return this.hasAccount('true') && this.formField.subscriber.isPersonAccount === true;
		}
	}

	requireSubResourceOrigin() {
		console.log('requireResourceOrigin');

		if (this.hasAccount('true')) {
			if (this.hasSubResourceOrigin()) {
				console.log('- possui origem de recurso');
				this.required.subResourceOriginError = false;
				return true;
			} else {
				console.log('- NAO possui origem de recurso');
				this.required.subResourceOriginError = true;
				return false;
			}
		}
		return true;
	}

	hasSubResourceOrigin() {
		return !!this.formField.subscriber.resourceOrigin;
	}

	requireSubResourceOriginOutros() {
		if (this.hasAccount('true')) {
			if (this.isPF('true')) {
				if (
					this.formField.subscriber.resourceOrigin == 'Outros' &&
					(this.formField.subscriber.resourceOriginOutros == '' || this.formField.subscriber.resourceOriginOutros == null)
				) {
					this.required.resourceOriginOutrosSubsError = true;
					return false;
				}
			}
			this.required.resourceOriginOutrosSubsError = false;
			return true;
		} else {
			return true;
		}
	}

	hasAccount(isSubscriber) {
		if (isSubscriber == 'false') {
			return !!this.formField.account;
		} else {
			return !!this.formField.subscriber;
		}
	}

	isActivityDisabled(isSubscriber) {
		return (this.formField.status && this.formField.status !== 'Em andamento') || !this.hasAccount(isSubscriber);
	}

	operarCartaoCreditoEmail() {
		console.log('enviando pagamento de cartao de credito por e-mail');
	}

	searchGrauParentescoNoResultText() {
		const resultText = 'Nenhum grau de parentesco encontrado.';
		return resultText;
	}

	searchGrauParentesco(searchString) {
		return this.$parent.propostaPayment.propostaPaymentService.getGrauParentesco(searchString);
	}

	searchGrauParentescoChanged() {
		const propostaPayment = this.$parent.propostaPayment;
		propostaPayment.autoComplete.resetSelected(propostaPayment.formField, 'degreeOfKinship');
	}

	searchGrauParentescoSelect(item) {
		if (!item) {
			return;
		}

		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.formField.degreeOfKinship = item.originalObject;
		propostaPayment.requireSubDegreeOfKinship();

		console.log('Parentesco selecionado: ', propostaPayment.formField.degreeOfKinship);
	}

	searchGrauParentescoFocusOut() {
		console.log('Entrou parentesco focus out');
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.formField.degreeOfKinship);
			console.log('Valid input? ', hasValidInput);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchGrauParentesco');
			}
		}, 100);
	}

	searchNacionalidadeNoResultText() {
		const resultText = 'Nenhuma nacionalidade encontrada.';
		return resultText;
	}

	searchNacionalidade(searchString) {
		console.log('searchNacionalidade | ', searchString, ' | this : ', this);
		return this.$parent.propostaPayment.propostaPaymentService.searchNacionalidade(searchString);
	}

	searchNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const propostaPayment = this.$parent.propostaPayment;
		propostaPayment.autoComplete.resetSelected(propostaPayment.formField.subscriber, 'nationality');
	}

	searchNacionalidadeSelect(item) {
		console.log('searchNacionalidadeSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.formField.subscriber.nationality = item.originalObject;
		propostaPayment.requireSubNationality();
	}

	searchSegundaNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const propostaPayment = this.$parent.propostaPayment;
		propostaPayment.autoComplete.resetSelected(propostaPayment.formField.subscriber, 'secondNationality');
	}

	searchSegundaNacionalidadeSelect(item) {
		console.log('searchSegundaNacionalidadeSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.formField.subscriber.secondNationality = item.originalObject;
		propostaPayment.requireSubSecondNationality();
	}

	searchPaisFocusOut(context) {
		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		if (context == 'birth') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.subscriber.birthCountry);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchPaisSubs');
				}
			}, 100);
		} else if (context == 'nac') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.subscriber.nationality);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchNationalitySubs');
				}
			}, 100);
		} else if (context == 'secNac') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.subscriber.secondNationality);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchSecondNationalitySubs');
				}
			}, 100);
		}
	}

	searchPaisChangedBirth() {
		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.autoComplete.resetSelected(propostaPayment.formField.subscriber, 'birthCountry');
	}

	searchPaisChangedNac() {
		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.autoComplete.resetSelected(propostaPayment.formField.subscriber, 'nationality');
	}

	searchPaisChangedSecNac() {
		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.autoComplete.resetSelected(propostaPayment.formField.subscriber, 'secondNationality');
	}

	searchPaisSubs(searchString) {
		const propostaPayment = this.$parent.propostaPayment;

		return propostaPayment.propostaPaymentService.searchResidenciaFiscal(searchString);
	}

	searchPaisSelectBirth(item) {
		const propostaPayment = this.$parent.propostaPayment;

		if (!item) {
			return;
		}

		propostaPayment.formField.subscriber.birthCountry = item.originalObject;
		propostaPayment.requireSubBirthCountry();

		console.log('FORMS ATUAL: ', propostaPayment.formField);
		console.log('ITEM: ', item);
	}

	searchPaisNoResultText() {
		const resultText = 'Nenhum país encontrado';
		return resultText;
	}

	changeDiffFinancialResp() {
		this.autoComplete.resetSelected(this.formField, 'subscriber');
		this.autoComplete.resetSelected(this.formField, 'cpfTitularCartao');
		this.autoComplete.resetSelected(this.formField, 'nomeTitularCartao');
		console.log('Profissão subscriber limpa');
		this.autoComplete.clearInput(this.$scope, 'searchProfessionSubs');
	}

	validateDuplicatedCpf() {
		//console.log('emitindo duplicatedCpfValidation');
		this.$scope.$emit('duplicatedCpfValidation', this.formField);
	}

	searchAccountSubs(searchString) {
		console.log('searchAccount', searchString, this);

		const propostaPayment = this.$parent.propostaPayment;

		console.log('propostaPayment', propostaPayment);

		const retornoSearchAccount = propostaPayment.propostaPaymentService.searchAccount(searchString);

		console.log('retornoSearchAccount:', retornoSearchAccount);

		return retornoSearchAccount;
	}

	searchAccountSelect(item) {
		// console.log('searchAccountSelect', item);

		if (!item) {
			return;
		}

		const propostaPayment = this.$parent.propostaPayment;
		const hasAction = !!item.originalObject.action;

		if (hasAction) {
			const action = item.originalObject.action;

			propostaPayment[action](item);

			return;
		}

		//Aplica valor para contas sem o campo
		propostaPayment.formField.subscriber = item.originalObject;
		propostaPayment.requireSubscriber();

		propostaPayment.autoComplete.resetSelected(propostaPayment.formField.subscriber, 'profession');
		//propostaPayment.requireAccount();

		propostaPayment.messages.cleanMessages(propostaPayment.$scope);
		propostaPayment.validateDuplicatedCpf();

		const isPersonAccount = propostaPayment.formField.subscriber.isPersonAccount;
		const cpf = propostaPayment.formField.subscriber.cpf;

		console.log('FORMS Atual: ', propostaPayment.formField);
	}

	searchAccountFocusOut() {
		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			// console.log('timeout FocusOut', this.formField.account);

			if (!this.autoComplete.hasValidInput(this.formField.subscriber)) {
				this.autoComplete.clearInput(this.$scope, 'searchAccountSubs');
				this.autoComplete.clearInput(this.$scope, 'searchProfessionSubs');
				this.autoComplete.resetSelected(this.formField, 'subscriber');
			}
		}, 100);
		this.validateDuplicatedCpf();
	}

	searchAccountChanged() {
		const propostaPayment = this.$parent.propostaPayment;
		// console.log('searchAccountChanged', propostaPayment.formField.account, this);

		const valor = $('#searchAccount input').val();
		if (valor === '') propostaPayment.messages.cleanMessages(propostaPayment.$scope);

		propostaPayment.autoComplete.resetSelected(propostaPayment.formField, 'subscriber');
		propostaPayment.autoComplete.clearInput(propostaPayment.$scope, 'searchProfessionSubs');

		// Ao trocar o Account, limpa campos da aba Pagamento
		propostaPayment.formField.isAccountOwnerDebitSameProspect = false;
		propostaPayment.formField.accountOwnerCpfCnpjDebit = '';
		propostaPayment.formField.accountOwnerNameDebit = '';
		propostaPayment.formField.listApjs = [];
	}

	checkIfHasUserInfo() {
		let hasUserInfo = false;

		if (this.propostaSetupAccountService.getFromPropostaPayment()) {
			this.formField.subscriber = this.propostaSetupAccountService.getNewSubscriberInfo();
			hasUserInfo = true;
			console.log('NEW SUBSCRIBER', this.formField.subscriber);
		}

		//clear info from service
		this.propostaSetupAccountService.setNewSubscriberInfo(null);

		//return hasUserInfo;
	}

	modalHandle() {
		this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		const propostaType = sessionStorage.getItem('tipo_lista');
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromPropostaPayment(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromPropostaPayment(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
	}

	isSubscriber() {
		return this.formField.differentFinancialResponsible == 'true' ? true : false;
	}

	searchProfession(searchString) {
		// console.log('searchProfession', this);

		const propostaPayment = this.$parent.propostaPayment;

		const isPersonAccount = propostaPayment.isSubscriber() ? propostaPayment.formField.subscriber.isPersonAccount : propostaPayment.formField.account.isPersonAccount;

		return propostaPayment.propostaPaymentService.searchProfession(searchString, isPersonAccount);
	}

	searchProfessionSelect(item) {
		// console.log('searchProfessionSelect', item);

		if (!item) {
			return;
		}

		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.formField.profession = item.originalObject;
		propostaPayment.requireProfessionPF();
		propostaPayment.requireProfessionPJ();

		//propostaPayment.requireProfession();
	}

	searchProfessionFocusOut() {
		// console.log('searchProfessionFocusOut', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.formField.profession);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchProfession');
			}
		}, 100);
	}

	searchProfessionChanged() {
		// console.log('searchProfessionChanged', this);
		const propostaPayment = this.$parent.propostaPayment;
		propostaPayment.autoComplete.resetSelected(propostaPayment.formField, 'profession');
	}

	searchProfessionSubs(searchString) {
		// console.log('searchProfession', this);

		const propostaPayment = this.$parent.propostaPayment;

		const isPersonAccount = propostaPayment.isSubscriber() ? propostaPayment.formField.subscriber.isPersonAccount : propostaPayment.formField.account.isPersonAccount;

		return propostaPayment.propostaPaymentService.searchProfession(searchString, isPersonAccount);
	}

	searchProfessionSelectSubs(item) {
		// console.log('searchProfessionSelect', item);

		if (!item) {
			return;
		}

		const propostaPayment = this.$parent.propostaPayment;

		propostaPayment.formField.subscriber.profession = item.originalObject;
	}

	searchProfessionFocusOutSubs() {
		// console.log('searchProfessionFocusOut', this);

		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.formField.subscriber.profession);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchProfessionSubs');
			}
		}, 100);
	}

	searchProfessionChangedSubs() {
		console.log('Profissou subs mudou', this);
		const propostaPayment = this.$parent.propostaPayment;
		propostaPayment.autoComplete.resetSelected(propostaPayment.formField.subscriber, 'profession');
	}

	searchNoResultText(isSubscriber) {
		if (!this.hasAccount(isSubscriber)) {
			return;
		}

		const professionText = 'Nenhuma Atividade Principal/Profissão encontrada';

		return professionText;
	}

	operarCartaoCreditoOnline() {
		console.log('processando cartao de crédito online');

		console.log('parcela selecionada: ', this.formField.parcelaSelecionada);
		console.log('parcela valor: ', this.formField.parcelaValor);
		console.log('valor liquido: ', this.formField.cartaoValorLiquido);
		console.log('atributos correntes: ', this.formField);

		this.propostaPaymentService.getURLPagamentoCredito(this.formField).then((response) => {
			console.log('resultado da busca de url: ', response);
		});

		this.propostaId;
	}
}

module.exports = propostaPaymentController;
