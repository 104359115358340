'use strict';

class resgateFileController {
	// @ngInject
	constructor($q, $timeout, $scope, $window, feedbackMessages, resgateService, resgateFormService, resgateFileService, resgateGetForm, utilsHelper) {
		this.$q = $q;
		this.$timeout = $timeout;
		this.$scope = $scope;
		this.$window = $window;

		this.resgateService = resgateService;
		this.resgateFormService = resgateFormService;
		this.resgateGetForm = resgateGetForm;
		this.api = resgateFileService;
		this.messages = feedbackMessages;
		this.utilsHelper = utilsHelper;

		this.tooltip = null;

		this.getFormFieldStatus();
		this.checkStatusOnLoad();
		this.setFormTooltip();
	}

	hideTrashByStatus() {
		const statusToHideTrash = ['Reprovado', 'Pagamento Criticado'];

		return statusToHideTrash.indexOf(this.formField.status) >= 0;
	}

	getFormFieldStatus() {
		this.formField = this.resgateFormService.getFormField();
	}

	isTitleAquisition() {
		// @todo WTF?
		return this.formField.type.titleAquisition.value === 'true';
	}

	hasRenterApprove() {
		// @todo WTF?
		return this.formField.type.hasRenterApprove.value === 'true';
	}

	isLiberarCaucao() {
		// @todo WTF?
		return this.formField.liberarCaucao;
	}

	isRealtyDebits() {
		// @todo WTF?
		return this.formField.type.realtyDebits.value === 'true';
	}

	isCaucionado() {
		return this.formField.type.caucionado;
	}

	isType(type) {
		if (this.formField.decisionType) {
			return this.formField.decisionType.includes(type);
		}
	}

	hasFormFiles() {
		let hasForm = false;

		if (this.isTitleAquisition()) {
			hasForm = true;
		}

		if (this.isType('100-locatario')) {
			hasForm = true;
		}

		if (this.isType('cancelamento-divisao-valores')) {
			hasForm = true;
		}

		if (this.isType('divisao-de-valores')) {
			hasForm = true;
		}

		if (this.isType('execucao-caucao')) {
			hasForm = true;
		}

		if (this.formField.liberarCaucao === true) {
			hasForm = true;
		}

		return hasForm;
	}

	getFormFilesHandler() {
		this.saveCaseResgate().then((response) => {
			//colocar na propriedade do form indicando que foi clicado o baixar formulario
			this.formField.lastFormAction = 'BaixarFormulario';
			this.formField.id = response.caseId;
			this.getFormFiles();
		});
	}

	getFormFiles() {
		this.resgateGetForm.openFiles(this.formField);
	}

	saveCaseResgate() {
		const deferred = this.$q.defer();

		this.resgateService.setErrorMessage('');

		const openWin = window.open('http://www.google.com', 'directories=no,height=100,width=100,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,top=0,location=no');
		if (!openWin) {
			console.log('block');
			this.resgateService.setErrorMessage('Para baixar o formulário é necessário que desbloqueie o POPUP do seu navegador.');
		} else {
			console.log('free');
			openWin.close();
			if (this.formField.information.mainAccount.value !== null) {
				this.$scope.$emit('changeMainShowLoading', true);

				this.resgateService.saveCaseResgate().then((response) => {
					this.$scope.$emit('changeMainShowLoading', false);

					// console.log(response);

					if (response.isSuccessful) {
						this.formField.id = response.caseId;
						this.resgateService.setSuccessMessage('Resgate salvo com sucesso.');
						this.$timeout(() => {
							this.resgateService.setSuccessMessage('');
						}, 5000);

						deferred.resolve(response);
					} else {
						// console.log('error: ', response);
						this.resgateService.setErrorMessage(response.errorMessages[0]);
					}
				});

				return deferred.promise;
			}
		}
	}

	checkStatusOnLoad() {
		this.naoExibeAnexoBeneficiario(); // Garante que o método começa com os anexos de beneficiario ocultados.
		this.formField.file.form.show = true;
		this.formField.file.subscritor.show = false;
		this.formField.file.beneficiario.show = false;
		this.formField.file.legalRepresentative.show = false;
		if (this.formField.liberarCaucao === true) {
			if (this.formField.information.legalRepresentative.value === 'true') {
				this.formField.file.legalRepresentative.show = true;
				this.formField.file.legalRepresentative.document.show = true;
				this.formField.file.legalRepresentative.identityRepresentante.show = true;
				this.formField.file.legalRepresentative.other.show = true;
			} else if (this.formField.type.caucionado === true) {
				this.formField.file.legalRepresentative.show = true;
				this.formField.file.legalRepresentative.identityRenter.show = true;
				this.formField.file.legalRepresentative.other.show = true;
			}
		}
		if (this.formField.type.caucionado === false) {
			this.formField.file.subscritor.show = true;
			if (this.formField.information.mainAccount.value != null && this.formField.information.mainAccount.value.isPersonAccount === true) {
				if (this.formField.data.resgateValue.value >= 50000) {
					this.formField.file.subscritor.identity.show = true;
					this.formField.file.subscritor.cpf.show = true;
					this.formField.file.subscritor.proofAddress.show = true;
					this.formField.file.subscritor.other.show = true;
				} else {
					this.formField.file.subscritor.identity.show = true;
					this.formField.file.subscritor.other.show = true;
				}
			} else {
				this.formField.file.subscritor.cnpj.show = true;
				this.formField.file.subscritor.identityAdmin.show = true;
				this.formField.file.subscritor.constituitionDocumentation.show = true;
				this.formField.file.subscritor.other.show = true;
			}
			if (this.formField.bank.bankData.show === true) {
				if (this.formField.bank.bankData.customerOwner.value === 'false') {
					if (this.removerFormatacao(this.formField.bank.bankData.cpfcnpj.value).length <= 11) {
						if (this.formField.data.resgateValue.value >= 50000) {
							this.exibeAnexoBeneficiarioPF();
						} else {
							this.formField.file.beneficiario.show = false;
						}
					} else {
						if (this.formField.data.resgateValue.value >= 50000) {
							this.exibeAnexoBeneficiarioPJ();
						} else {
							this.formField.file.beneficiario.show = false;
						}
					}
				}
			}
		}
		if (this.formField.decisionType !== null) {
			if (
				this.formField.decisionType.includes('100-locatario') ||
				this.isType('recuperacao-credito-resgate-antecipado') ||
				this.isType('recuperacao-credito-resgate-final') ||
				this.isType('cancelamento-recuperacao-credito')
			) {
				if (this.formField.information.legalRepresentative.value === 'true') {
					this.formField.file.legalRepresentative.show = true;
					this.formField.file.legalRepresentative.document.show = true;
					this.formField.file.legalRepresentative.identityRepresentante.show = true;
					this.formField.file.legalRepresentative.other.show = true;
				} else if (this.formField.type.caucionado === true) {
					this.formField.file.legalRepresentative.show = true;
					this.formField.file.legalRepresentative.identityRenter.show = true;
					this.formField.file.legalRepresentative.other.show = true;
				}
				if (this.formField.information.mainAccount.value != null && this.formField.information.mainAccount.value.isPersonAccount === true) {
					this.formField.file.subscritor.show = true;
					if (this.formField.data.resgateValue.value >= 50000) {
						this.formField.file.subscritor.identity.show = true;
						this.formField.file.subscritor.cpf.show = true;
						this.formField.file.subscritor.proofAddress.show = true;
						this.formField.file.subscritor.other.show = true;
					} else {
						this.formField.file.subscritor.identity.show = true;
						this.formField.file.subscritor.other.show = true;
					}
				} else {
					this.formField.file.subscritor.show = true;
					this.formField.file.subscritor.cnpj.show = true;
					this.formField.file.subscritor.constituitionDocumentation.show = true;
					this.formField.file.subscritor.identityAdmin.show = true;
					this.formField.file.subscritor.other.show = true;
				}
				if (this.formField.bank.bankData.show === true && this.formField.bank.bankData.customerOwner.value === 'false') {
					if (this.removerFormatacao(this.formField.bank.bankData.cpfcnpj.value).length <= 11) {
						if (this.formField.data.resgateValue.value >= 50000) {
							this.exibeAnexoBeneficiarioPF();
						} else {
							this.formField.file.beneficiario.show = false;
						}
					} else {
						if (this.formField.data.resgateValue.value >= 50000) {
							this.exibeAnexoBeneficiarioPJ();
						} else {
							this.formField.file.beneficiario.show = false;
						}
					}
				}
			}
		}
		if (this.formField.data.debitValue.value !== 0 && this.formField.data.debitValue.value !== null && this.formField.data.debitValue.show === true) {
			this.formField.file.legalRepresentative.show = true;
			if (this.formField.information.legalRepresentative.value === 'true') {
				this.formField.file.legalRepresentative.document.show = true;
				this.formField.file.legalRepresentative.identityRepresentante.show = true;
				this.formField.file.legalRepresentative.other.show = true;
			} else if (this.formField.type.caucionado === true) {
				this.formField.file.legalRepresentative.identityRenter.show = true;
				this.formField.file.legalRepresentative.other.show = true;
			}

			if (this.formField.information.mainAccount.value != null && this.formField.information.mainAccount.value.isPersonAccount === true) {
				this.formField.file.subscritor.show = true;
				if (this.formField.data.resgateValue.value >= 50000) {
					this.formField.file.subscritor.identity.show = true;
					this.formField.file.subscritor.cpf.show = true;
					this.formField.file.subscritor.proofAddress.show = true;
					this.formField.file.subscritor.other.show = true;
				} else {
					this.formField.file.subscritor.identity.show = true;
					this.formField.file.subscritor.other.show = true;
				}
			} else {
				this.formField.file.subscritor.show = true;
				this.formField.file.subscritor.cnpj.show = true;
				this.formField.file.subscritor.constituitionDocumentation.show = true;
				this.formField.file.subscritor.identityAdmin.show = true;
				this.formField.file.subscritor.other.show = true;
			}
			if (this.formField.bank.bankData.show === true && this.formField.bank.bankData.customerOwner.value === 'false') {
				if (this.removerFormatacao(this.formField.bank.bankData.cpfcnpj.value).length <= 11) {
					if (this.formField.data.resgateValue.value >= 50000) {
						this.exibeAnexoBeneficiarioPF();
					} else {
						this.formField.file.beneficiario.show = false;
					}
				} else {
					if (this.formField.data.resgateValue.value >= 50000) {
						this.exibeAnexoBeneficiarioPJ();
					} else {
						this.formField.file.beneficiario.show = false;
					}
				}
			}
		}
		if (this.formField.decisionType !== null) {
			if (this.formField.decisionType.includes('execucao-caucao')) {
				this.formField.file.legalRepresentative.show = true;
				this.formField.file.subscritor.show = false;
				if (this.formField.information.legalRepresentative.value === 'true') {
					this.formField.file.legalRepresentative.document.show = true;
					this.formField.file.legalRepresentative.identityRepresentante.show = true;
					this.formField.file.legalRepresentative.other.show = true;
				} else if (this.formField.type.caucionado === true) {
					this.formField.file.legalRepresentative.identityRenter.show = true;
					this.formField.file.legalRepresentative.other.show = true;
				}
				if (this.removerFormatacao(this.formField.bank.bankBills.cpfcnpj.value).length <= 11) {
					if (this.formField.data.resgateValue.value >= 50000) {
						this.exibeAnexoBeneficiarioPF();
					} else {
						this.formField.file.beneficiario.show = false;
					}
				} else {
					if (this.formField.data.resgateValue.value >= 50000) {
						this.exibeAnexoBeneficiarioPJ();
					} else {
						this.formField.file.beneficiario.show = false;
					}
				}
			}
		}

		/* CAP-1751 */
		if (this.formField.bank.bankData.cpfcnpj != null) {
			const isHaveDecisionType = this.formField.decisionType != null;

			if (isHaveDecisionType && (this.isDivisaoDeValores() || this.isExecucaoCaucao())) {
				const isPessoaFisica = this.isPersonFisicaOrJuridica();
				const isTitularConta = this.formField.bank.bankData.customerOwner.value === 'true';
				const isResgateMaiorQueDezMil = this.formField.data.resgateValue.value >= 50000;

				console.log(this.formField.bank.bankData.cpfcnpj);
				console.log(this.formField.bank.bankBills.cpfcnpj);

				if (isResgateMaiorQueDezMil) {
					if (isPessoaFisica) {
						this.exibeAnexoBeneficiarioPF();
						if (this.removerFormatacao(this.formField.bank.bankBills.cpfcnpj.value).length > 11) this.exibeAnexoBeneficiarioPJ();
					} else {
						this.exibeAnexoBeneficiarioPJ();
						if (this.removerFormatacao(this.formField.bank.bankBills.cpfcnpj.value).length <= 11) this.exibeAnexoBeneficiarioPF();
					}
				} else {
					this.formField.file.beneficiario.show = false;
					this.naoExibeAnexoBeneficiario();
				}
			}
		} else {
			this.formField.file.beneficiario.show = false;
		}
		/* FIM CAP-1751 */

		/* Quando a solicitação é aberta pelo APP, não exibe os anexos do subscritor */
		if (this.formField.origin.app) {
			this.formField.file.subscritor.show = false;
			this.formField.file.subscritor.identity.show = false;
			this.formField.file.subscritor.identityAdmin.show = false;
			this.formField.file.subscritor.cnpj.show = false;
			this.formField.file.subscritor.constituitionDocumentation.show = false;
			this.formField.file.subscritor.cpf.show = false;
			this.formField.file.subscritor.proofAddress.show = false;
			this.formField.file.subscritor.other.show = false;
		}

		this.resgateFormService.setFormField(this.formField);

		this.$scope.$emit('validateUploads');
	}

	removerFormatacao(str) {
		if (str != null) {
			return (str = str.replace(/[^0-9]+/g, ''));
		} else {
			return '';
		}
	}

	isPersonFisicaOrJuridica() {
		/*@todo rever. [CAP-3037] BankData.cpfcnpj não é preenchido quando o resgate é execução da caução
		apenas o bankBills. Porem onde essa função é chamada, bankBills.cpfcnpj ja é verificado */
		const bankDataCpfCnpj = this.formField.bank.bankData.cpfcnpj.value;
		const bankBillsCpfCnpj = this.formField.bank.bankBills.cpfcnpj.value;
		if (!!bankDataCpfCnpj) {
			return this.utilsHelper.removeFormatacaoCpfCnpj(bankDataCpfCnpj).length <= 11;
		} else {
			return this.utilsHelper.removeFormatacaoCpfCnpj(bankBillsCpfCnpj).length <= 11;
		}
	}

	isDivisaoDeValores() {
		const divisaoDeValores = [];
		divisaoDeValores.push('divisao-valores-recuperacao-credito-resgate-antecipado');
		divisaoDeValores.push('divisao-valores-recuperacao-credito-resgate-final');
		divisaoDeValores.push('divisao-de-valores-resgate-antecipado');
		divisaoDeValores.push('divisao-de-valores-resgate-final');
		return divisaoDeValores.indexOf(this.formField.decisionType) > -1;
	}

	isExecucaoCaucao() {
		const execucaoCaucao = [];
		execucaoCaucao.push('execucao-caucao-resgate-final');
		execucaoCaucao.push('cancelamento-execucao-caucao');
		execucaoCaucao.push('execucao-caucao-resgate-antecipado');
		return execucaoCaucao.indexOf(this.formField.decisionType) > -1;
	}

	/* Prepara o array de campos para exibir */
	exibeAnexoBeneficiarioPF() {
		this.formField.file.beneficiario.show = true;
		this.formField.file.beneficiario.identity.show = true;
		this.formField.file.beneficiario.cpf.show = true;
		this.formField.file.beneficiario.proofAddress.show = true;
		this.formField.file.beneficiario.other.show = true;
	}

	exibeAnexoBeneficiarioPJ() {
		this.formField.file.beneficiario.show = true;
		this.formField.file.beneficiario.cnpj.show = true;
		this.formField.file.beneficiario.constituitionDocumentation.show = true;
		this.formField.file.beneficiario.identityAdmin.show = true;
		this.formField.file.beneficiario.other.show = true;
	}

	naoExibeAnexoBeneficiario() {
		this.formField.file.beneficiario.show = false;
		this.formField.file.beneficiario.identity.show = false;
		this.formField.file.beneficiario.cpf.show = false;
		this.formField.file.beneficiario.proofAddress.show = false;
		this.formField.file.beneficiario.other.show = false;
		this.formField.file.beneficiario.cnpj.show = false;
		this.formField.file.beneficiario.constituitionDocumentation.show = false;
		this.formField.file.beneficiario.identityAdmin.show = false;
		this.formField.file.beneficiario.other.show = false;
	}

	findIndex(files, indexToFind) {
		let index = null;
		for (let i = 0; i < files.length; i++) {
			if (files[i].index === indexToFind) {
				index = i;
				break;
			}
		}
		if (index !== null) {
			files.splice(index, 1);
			if (files.length === 0) {
				return null;
			} else {
				for (let i = 0; i < files.length; i++) {
					files[i].index = i;
				}
				return files;
			}
		}
		return files;
	}

	getAnnexByType(type) {
		let annex;

		if (type === 'form') {
			annex = this.formField.file.form;
		} else if (type === 'legal-document') {
			annex = this.formField.file.legalRepresentative.document;
		} else if (type === 'legal-identity') {
			annex = this.formField.file.legalRepresentative.identityRepresentante;
		} else if (type === 'legal-identity-renter') {
			annex = this.formField.file.legalRepresentative.identityRenter;
		} else if (type === 'legal-other') {
			annex = this.formField.file.legalRepresentative.other;
		} else if (type === 'subscritor-identity') {
			annex = this.formField.file.subscritor.identity;
		} else if (type === 'subscritor-identity-admin') {
			annex = this.formField.file.subscritor.identityAdmin;
		} else if (type === 'subscritor-cnpj') {
			annex = this.formField.file.subscritor.cnpj;
		} else if (type === 'subscritor-constituition') {
			annex = this.formField.file.subscritor.constituitionDocumentation;
		} else if (type === 'subscritor-cpf') {
			annex = this.formField.file.subscritor.cpf;
		} else if (type === 'subscritor-proof') {
			annex = this.formField.file.subscritor.proofAddress;
		} else if (type === 'subscritor-other') {
			annex = this.formField.file.subscritor.other;
		} else if (type === 'beneficiario-identity') {
			annex = this.formField.file.beneficiario.identity;
		} else if (type === 'beneficiario-identity-admin') {
			annex = this.formField.file.beneficiario.identityAdmin;
		} else if (type === 'beneficiario-cpf') {
			annex = this.formField.file.beneficiario.cpf;
		} else if (type === 'beneficiario-proof') {
			annex = this.formField.file.beneficiario.proofAddress;
		} else if (type === 'beneficiario-cnpj') {
			annex = this.formField.file.beneficiario.cnpj;
		} else if (type === 'beneficiario-constituition') {
			annex = this.formField.file.beneficiario.constituitionDocumentation;
		} else if (type === 'beneficiario-other') {
			annex = this.formField.file.beneficiario.other;
		}

		return annex;
	}

	removeFiles(itemIndex, type) {
		const annex = this.getAnnexByType(type);

		if (!annex.files[itemIndex].result) {
			annex.files.splice(itemIndex, 1);

			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);

		this.api.deleteAttachmentFile(annex.files[itemIndex].salesForceId).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);

				return;
			}

			annex.files.splice(itemIndex, 1);

			this.resgateFormService.setFormField(this.formField);
			this.resgateService.updateEditCaseData();
			this.$scope.$emit('validateUploads');
		});
	}

	// deleteAttachmentFile(attachmentId) {
	// 	if (!!attachmentId) {
	// 		this.$scope.$emit('changeMainShowLoading', true);
	// 		this.api.deleteAttachmentFile(attachmentId).then((response) => {
	// 			this.$scope.$emit('changeMainShowLoading', false);
	// 		});
	// 	} else {
	// 		let response;
	// 		response.isSuccessful = true;
	// 		return response;
	// 	}
	// }

	validateFilesSizes(annex) {
		let isValid = true;
		const sizeLimit = 8388608; //8MB

		for (let i = annex.length - 1; i >= 0; i--) {
			if (annex[i].size > sizeLimit) {
				isValid = false;
				break;
			}
		}

		return isValid;
	}

	validateFilesNames(annex) {
		let isValid = true;
		for (let i = annex.length - 1; i >= 0; i--) {
			if (annex[i].name.length > 80) {
				isValid = false;
			}
		}
		return isValid;
	}

	uploadFiles(files, errFiles, type) {
		/* Workaround - Remonto o array apenas com a propriedade nome,
			para contornar o caso que perder a informação do nome do arquivo */
		//const tmpData = new Array();
		//for (let i = 0; i < files.length; i++) {
		//	tmpData.push({ name: files[i].name })
		//}
		//files = tmpData;
		/* --------------------------------------------------------------------*/
		this.resgateService.setErrorMessage('');
		if (!this.validateFilesNames(files)) {
			this.resgateService.setErrorMessage('O nome do arquivo não pode ultrapassar 80 caracteres.');
			return;
		} else if (!this.validateFilesSizes(files)) {
			this.resgateService.setErrorMessage('Um dos arquivos selecionados excedeu o limite de 8MB');
			return;
		}

		console.log('arquivo : ', files);
		console.log('tipo : ', type);

		const annex = this.getAnnexByType(type);

		if (!annex.files) annex.files = [];

		annex.files = annex.files.concat(files);

		this.removeDuplicatedFiles(annex);

		// annex.filesParsed = this.parseFiles(annex.files);
		// console.log(annex.files);
		// console.log(annex.filesParsed);

		this.resgateFormService.setFormField(this.formField);

		this.$scope.$emit('validateUploads');
	}

	removeDuplicatedFiles(annex) {
		console.log('removeDuplicatedFiles', annex);

		const fileNames = [];

		for (let i = annex.files.length - 1; i >= 0; i--) {
			const fileName = annex.files[i].name;

			if (this.isDuplicatedFileName(fileNames, fileName)) {
				annex.files.splice(i, 1);
			}

			fileNames.push(fileName);
		}
	}

	isDuplicatedFileName(fileNames, fileName) {
		return fileNames.indexOf(fileName) !== -1;
	}

	setFormTooltip() {
		let tooltipHtml = '';
		const decisionType = this.formField.decisionType;

		if (decisionType) {
			for (let i = tooltipHtmlList.length - 1; i >= 0; i--) {
				const itemType = tooltipHtmlList[i].type;
				if (itemType === decisionType) {
					tooltipHtml = tooltipHtmlList[i].html;
					break;
				}
			}
		}
		console.log('setFormTooltip', decisionType, tooltipHtml);

		this.tooltip = tooltipHtml;

		return tooltipHtml;
	}

	checkFileUpload(salesforceId) {
		return typeof salesforceId == 'undefined';
	}
}

module.exports = resgateFileController;

const tooltipHtmlList = [{
		type: 'cancelamento-100-locatario',
		html: '<p>\
						Documento utilizado para liberação da Caução e pagamento do resgate, quando houver cumprimento integral das obrigações relativas ao contrato de locação.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o  pagamento do resgate seja realizado para  uma pessoa diferente do Titular,  será necessário realizar reconhecimento de firma do subscritor.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	},
	{
		type: 'cancelamento-recuperacao-credito',
		html: '<p>\
							Documento utilizado para Liberação da caução e pagamento do resgate quando cliente optar por  iniciar uma nova Garantia.\
						</p>\
						<br>\
						<p>\
							Observações:<br>\
							Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
						</p>'
	},
	{
		type: 'cancelamento-divisao-valores',
		html: '<p>\
							Documento utilizado para liberação da Caução e pagamento de débitos. Parte ou o valor integral do resgate serão utilizados para cumprir obrigações relativas ao contrato de locação.\
						</p>\
						<br>\
						<p>\
							Observações:<br>\
							Caso o  pagamento do resgate seja realizado para  uma pessoa diferente do Titular,  será necessário realizar reconhecimento de firma do subscritor.<br>\
							Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
						</p>'
	},
	{
		type: 'cancelamento-divisao-valores-recuperacao-credito',
		html: '<p>\
						Documento utilizado para Liberação da caução e pagamento do resgate quando cliente optar pela utilização de parte do valor para pagamentos de débitos e para  iniciar uma nova Garantia.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
					</p>'
	},
	{
		type: 'descaucionado-recuperacao-credito',
		html: '<p>\
						Documento utilizado para solicitação de pagamento do resgate quando a caução do título já estiver liberada, e o cliente optar por iniciar uma nova Garantia.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
					</p>'
	},
	{
		type: 'descaucionado-100-locatario',
		html: '<p>\
						Documento utilizado para solicitação de pagamento do resgate quando a caução do título já estiver liberada.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
					</p>'
	},
	{
		type: 'divisao-valores-recuperacao-credito-resgate-antecipado',
		html: '<p>\
						Documento utilizado para Liberação da caução e pagamento do resgate quando cliente optar pela utilização de parte do valor para pagamentos de débitos e para  iniciar uma nova Garantia.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
					</p>'
	},
	{
		type: 'divisao-valores-recuperacao-credito-resgate-final',
		html: '<p>\
						Documento utilizado para Liberação da caução e pagamento do resgate quando cliente optar pela utilização de parte do valor para pagamentos de débitos e para  iniciar uma nova Garantia.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
					</p>'
	},
	{
		type: 'divisao-de-valores-resgate-antecipado',
		html: '<p>\
						Documento utilizado para liberação da Caução e pagamento de débitos. Parte ou o valor integral do resgate serão utilizados para cumprir obrigações relativas ao contrato de locação.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o  pagamento do resgate seja realizado para  uma pessoa diferente do Titular,  será necessário realizar reconhecimento de firma do subscritor.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	},
	{
		type: 'divisao-de-valores-resgate-final',
		html: '<p>\
						Documento utilizado para liberação da Caução e pagamento de débitos. Parte ou o valor integral do resgate serão utilizados para cumprir obrigações relativas ao contrato de locação. \
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o  pagamento do resgate seja realizado para  uma pessoa diferente do Titular,  será necessário realizar reconhecimento de firma do subscritor.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	},
	{
		type: 'recuperacao-credito-resgate-antecipado',
		html: '<p>\
						Documento utilizado para Liberação da caução e pagmento do resgate quando cliente optar por  iniciar uma nova Garantia.\
					</p>\
					<br>\
					<p>\
						Observações: <br>\
						Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
					</p>'
	},
	{
		type: 'recuperacao-credito-resgate-final',
		html: '<p>\
						Documento utilizado para Liberação da caução e pagmento do resgate quando cliente optar por  iniciar uma nova Garantia.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o pagamento seja realizado para um terceiro, será necessário realizar reconhecimento de firma.\
					</p>'
	},
	{
		type: '100-locatario-resgate-antecipado',
		html: '<p>\
						Documento utilizado para liberação da Caução e pagamento do resgate, quando houver cumprimento integral das obrigações relativas ao contrato de locação.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o  pagamento do resgate seja realizado para  uma pessoa diferente do Titular,  será necessário realizar reconhecimento de firma do subscritor.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	},
	{
		type: '100-locatario-resgate-final',
		html: '<p>\
						Documento utilizado para liberação da Caução e pagamento do resgate, quando houver cumprimento integral das obrigações relativas ao contrato de locação.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso o  pagamento do resgate seja realizado para  uma pessoa diferente do Titular,  será necessário realizar reconhecimento de firma do subscritor.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	},
	{
		type: 'cancelamento-execucao-caucao',
		html: '<p>\
						Formulário de Execução da Caução: Documento utilizado para Executar a Caução devido a existência de débitos relativos ao descumprimento das obrigações relativas ao contrato de locação.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso a  execução seja assinada pelo Credor Caucionado (Locador), será necessário seu Reconhecimento de firma por autenticidade.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>\
					<br>\
					<p>\
						- Formulário de Declaração de débitos - Documento que discrimina os débitos cobrados na Execução da Caução.\
					</p>\
					<br>\
					<p>\
						Observação: Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	},
	{
		type: 'execucao-caucao-resgate-antecipado',
		html: '<p>\
						Formulário de Execução da Caução: Documento utilizado para Executar a Caução devido a existência de débitos relativos ao descumprimento das obrigações relativas ao contrato de locação.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso a  execução seja assinada pelo Credor Caucionado (Locador), será necessário seu Reconhecimento de firma por autenticidade.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.<br>\
					</p>\
					<br>\
					<p>\
						- Formulário de Declaração de débitos - Documento que discrimina os débitos cobrados na Execução da Caução.\
					</p>\
					<br>\
					<p>\
						Observação: Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	},
	{
		type: 'execucao-caucao-resgate-final',
		html: '<p>\
						Formulário de Execução da Caução: Documento utilizado para Executar a Caução devido a existência de débitos relativos ao descumprimento das obrigações relativas ao contrato de locação.\
					</p>\
					<br>\
					<p>\
						Observações:<br>\
						Caso a  execução seja assinada pelo Credor Caucionado (Locador), será necessário seu Reconhecimento de firma por autenticidade.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.<br>\
						Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>\
					<br>\
					<p>\
						- Formulário de Declaração de débitos - Documento que discrimina os débitos cobrados na Execução da Caução.\
					</p>\
					<br>\
					<p>\
						Observação: Em caso de assinatura por um representante legal, enviar através do campo "Outros" documento de procuração válido.\
					</p>'
	}
];
