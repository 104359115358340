'use strict';

class SorteioBankController {
	/* @ngInject */
	constructor(
		$scope,
		$window,
		sorteioFormControlService,
		sorteioBankService,
		propostaNewApi,
		dadosBancariosHelper,
		feedbackMessages,
		autoCompleteService,
		crivoHelper,
		accountUtilService
	) {
		this.$scope = $scope;
		this.$window = $window;

		this.formField = sorteioFormControlService;
		this.api = sorteioBankService;
		this.propostaNewApi = propostaNewApi;
		this.autoComplete = autoCompleteService;
		this.dadosBancariosHelper = dadosBancariosHelper;
		this.messages = feedbackMessages;
		this.accountUtilService = accountUtilService;
		this.crivoHelper = crivoHelper;

		this.formField.bank.status = 'Em Andamento';
		this.bankList = this.api.getBankList();
		this.listenApplyErrorBank();

		// Icatu - Retirar opção de recebimento em conta poupança
		this.formField.bank.fields.tipoConta.value = 'Conta Corrente';
		// Icatu - Não permitir pagamento de sorteio em conta de terceiros
		this.formField.bank.fields.clienteTitular.value = 'true';
		this.getTitularCpfConta();
	}

	listenApplyErrorBank() {
		this.$scope.$on('applyErrorBankFields', (event, response, tipo) => {
			event.stopPropagation && event.stopPropagation();
			this.applyErrorBankFields(response, tipo);
		});
	}

	requireProperty(field) {
		this.formField.requireProperty(this.formField.bank.fields, field);
	}

	validateField(field) {
		this.formField.validateField(this.formField.bank.fields, field);
	}

	nullOrEmpty(field) {
		return this.formField.nullOrEmpty(this.formField.bank.fields, field);
	}

	searchProfession(searchString) {
		console.log('searchProfession');
		const sorteioBank = this.$parent.sorteioBank;
		const cpfcnpj = sorteioBank.formField.bank.fields.cpfCnpj.value;
		const isPersonAccount = sorteioBank.accountUtilService.isValidCpf(cpfcnpj);
		return sorteioBank.propostaNewApi.searchProfession(searchString, isPersonAccount);
	}

	searchProfessionSelect(item) {
		console.log('searchProfessionSelect');
		const sorteioBank = this.$parent.sorteioBank;
		if (sorteioBank.formField.bank.fields.profissao.value === null) {
			if (!item) {
				return;
			}
			sorteioBank.formField.bank.fields.profissao.value = item.originalObject;
			sorteioBank.formField.bank.fields.profissao.hasError = false;
		}
	}

	searchProfessionFocusOut() {
		console.log('searchProfessionFocusOut');
		const hasValidInput = this.autoComplete.hasValidInput(this.formField.bank.fields.profissao.value);
		if (!hasValidInput) {
			this.autoComplete.clearInput(this.$scope, 'searchProfessionData');
		}
	}

	searchProfessionChanged() {
		console.log('searchProfessionChanged');
		const sorteioBank = this.$parent.sorteioBank;

		sorteioBank.autoComplete.resetSelected(sorteioBank.formField.bank.fields.profissao, 'value');
	}

	getTitularCpfConta() {
		console.log('Entrei no getTitularCpfConta');
		const titularConta = this.formField.bank.fields.clienteTitular.value;
		if (titularConta == 'true') {
			this.formField.bank.fields.titular.value = this.formField.information.fields.mainAccount.value.Name;
			this.formField.bank.fields.cpfCnpj.value = this.formField.information.fields.mainAccount.value.cpf;
			this.formField.bank.fields.titular.disabled = true;
			this.formField.bank.fields.cpfCnpj.disabled = true;
		} else {
			this.formField.bank.fields.titular.value = null;
			this.formField.bank.fields.cpfCnpj.value = null;
			this.formField.bank.fields.titular.disabled = false;
			this.formField.bank.fields.cpfCnpj.disabled = false;
		}
	}

	applyErrorBankFields(response, tipo) {
		console.log('--> applyErrorBankFields: ', tipo);
		const areaForm = this.formField.bank.fields;
		console.log('--> areaForm: ', areaForm);
		if (!!areaForm) {
			console.log('--> entrou no AREAFORM!!');

			this.setErrorBankField(areaForm, false);
			if (!response.isSuccessful) {
				// Erro ao chamar o serviço, se o numero maximo de tentativas for alcançado libera
				if (this.dadosBancariosHelper.remoteCallErrorLimitWasExceded()) {
					this.messages.showErrorMessage(this.$scope, '');
				} else {
					this.setErrorBankField(areaForm, true);
					this.messages.showErrorMessage(this.$scope, this.dadosBancariosHelper.remoteCallErrorMessage);
				}
			} else if (response.isValid) {
				this.messages.showErrorMessage(this.$scope, '');
			} else {
				console.log('people service: conta bancaria invalida');
				this.setErrorBankField(areaForm, true);
			}
			this.dadosBancariosHelper.setPristine();
		}
	}

	getParametersForValidBank() {
		const parameter = {};
		parameter.tipoConta = this.formField.bank.fields.tipoConta.value;
		parameter.banco = this.formField.bank.fields.banco.value;
		parameter.agencia = this.formField.bank.fields.agencia.value;
		parameter.agenciaDV = this.formField.bank.fields.agenciaDV.value;
		parameter.conta = this.formField.bank.fields.conta.value;
		parameter.contaDV = this.formField.bank.fields.contaDV.value;
		parameter.cpfCnpj = this.formField.bank.fields.cpfCnpj.value;
		return parameter;
	}

	hasField(field) {
		return typeof field !== 'undefined' && field != null && field != '';
	}

	callForValidDataBank() {
		console.log('Entrou no valida DataBank');
		console.log('banco na lista:', this.bankList);

		if (angular.equals(this.parametrosValidados, this.getParametersForValidBank())) return;
		this.parametrosValidados = this.getParametersForValidBank();
		const { tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV } = this.parametrosValidados;
		const isValid = this.hasField(tipoConta) && this.hasField(cpfCnpj) && this.hasField(banco) && this.hasField(agencia) && this.hasField(conta) && this.hasField(contaDV);
		if (!isValid) return;

		this.$scope.$emit('changeMainShowLoading', true);
		this.dadosBancariosHelper.consultaDadosBancariosSeValidosPromisse(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV).then((response) => {
			this.applyErrorBankFields(response);
			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	setErrorBankField(areaForm, value) {
		console.log('entrou setErrorBankField: ', value);
		areaForm.clienteTitular.hasError = value;
		areaForm.banco.hasError = value;
		areaForm.agencia.hasError = value;
		areaForm.agencia.hasAgenciaPeopleError = value;
		areaForm.agenciaDV.hasError = value;
		areaForm.agenciaDV.hasAgenciaDVPeopleError = value;
		areaForm.conta.hasError = value;
		areaForm.conta.hasContaPeopleError = value;
		areaForm.contaDV.hasError = value;
		areaForm.contaDV.hasContaDVPeopleError = value;
	}

	consultarCrivo() {
		const me = this;
		const cpfCnpj = me.formField.bank.fields.cpfCnpj.value;

		const isPersonal = this.accountUtilService.isValidCpf(cpfCnpj);
		const isCompany = this.accountUtilService.isValidCnpj(cpfCnpj);

		if (isPersonal || isCompany) {
			me.$scope.$emit('changeMainShowLoading', true);

			me.crivoHelper.getRecaptchaLoginCall().then((recaptchaLogin) => {
				console.log('response recaptchaLogin: ', recaptchaLogin);
				grecaptcha.ready(function () {
					grecaptcha.execute(recaptchaLogin, { action: 'submit' }).then(function (token) {
						console.log('---- 2. GTOKEN: ', token);

						me.crivoHelper.consultarCrivo(cpfCnpj, token, (response) => {
							if (!response.isSuccessful) {
								me.messages.showErrorMessage(me.$scope, me.crivoHelper.remoteCallErrorMessage);
								me.crivoHelper.setPristine();
							} else {
								me.formField.bank.fields.titular.value = isPersonal ? response.req_nome : response.req_razaoSocial;
								me.messages.showErrorMessage(me.$scope, '');
							}
							me.$scope.$emit('changeMainShowLoading', false);
						});
					});
				});
			});
		} else {
			me.crivoHelper.setPristine();
			me.formField.accountOwnerNameDebit = null;
			me.messages.showErrorMessage(me.$scope, '');
		}
	}

	changeBankOptions() {
		console.log('entrou em changeBankOptions()');
		const me = this;

		const tipoConta = me.formField.bank.fields.tipoConta.value;

		if (tipoConta === 'Conta Corrente') {
			me.bankList = me.api.getBankList();
		} else {
			me.bankList = me.api.getBankListContaPoupanca();
		}
	}
}

module.exports = SorteioBankController;
