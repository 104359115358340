'use strict';

class accountDetailController {
	// @ngInject
	constructor($scope, $stateParams, $state, sfConfig, accountDetailService, profile, breadcrumbHelper, utilsHelper) {
		this.$stateParams = $stateParams;
		this.sfConfig = sfConfig;
		this.$state = $state;
		this.$scope = $scope;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.utilsHelper = utilsHelper;

		this.accountDetailService = accountDetailService;
		this.profile = profile;
		this.imageAdd = `${sfConfig.resource}/images/ico-add.png`;
		this.$scope.$emit('changeMainShowLoading', true);

		console.log('obtendo o id passado por parametro:', $scope.id);

		$scope.id = 'id';

		console.log(' 2 - obtendo o id passado por parametro:', $scope.id);

		this.account = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				contacts: null
			},
			isPersonAccount: false
		};
		this.errorMessage = '';
		this.getUserDetails();
		this.configureBreadCrumb();
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Cadastro', '/caponline/secured#!/conta/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes do Cadastro', '#', false, true);
	}

	hasValidUrlParams() {
		return this.$stateParams && this.$stateParams.id ? true : false;
	}

	getUserDetails() {
		if (!this.hasValidUrlParams()) {
			this.$scope.$emit('changeMainShowLoading', false);
			this.$state.go('account-list');
		}

		this.accountDetailService.getAccountDetails(this.$stateParams.id).then((response) => {
			console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				console.log('getAccountDetails', response);
				this.account.isPersonAccount = response.isPersonAccount;
				this.account.details = response.isPersonAccount ? response.accountPFInfo : response.accountPJInfo;
				this.account.related.propostas = response.propostas;
				this.account.related.rentGarantee = response.garantias;
				this.account.related.contacts = response.contacts;
			}

			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	editAccount() {
		if (this.account.isPersonAccount === false) {
			this.$state.go('edit-juridical-account', { id: this.account.details.Id });
		} else {
			this.$state.go('edit-account', { id: this.account.details.Id });
		}
	}

	canEdit() {
		const isMyAccount = this.profile.accountId === this.$stateParams.id;
		let canEdit = true;

		if (isMyAccount) {
			canEdit = this.profile.permissions.myAccount.canEdit;
		}

		return canEdit;
	}
}

module.exports = accountDetailController;
