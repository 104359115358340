'use strict';

const inputErrorClass = 'has-error';

class myAccountJuridicalEditController {
	// @ngInject
	constructor($state, $stateParams, $window, $scope, $timeout, sfConfig, accountEditApi, validationFormFields, profile, autoCompleteService) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$window = $window;
		this.$scope = $scope;
		this.sfConfig = sfConfig;
		this.api = accountEditApi;
		this.valid = validationFormFields;
		this.$scope.$emit('changeMainShowLoading', false);
		this.profile = profile;
		this.isEditable = profile.permissions.myAccount.editableFields;
		this.autoComplete = autoCompleteService;
		this.$timeout = $timeout;
		console.log('isEditable', this.isEditable);
		console.log('this.autoComplete', this.autoComplete);

		if (!profile.permissions.myAccount.canEdit) {
			this.redirectToDetail();
		}

		this.account = {
			id: null,
			company: {
				cnpj: {
					value: '',
					classError: ''
				},
				companyName: {
					value: '',
					classError: ''
				},
				tradingName: {
					value: '',
					classError: ''
				},
				freeRegistration: {
					value: '',
					classError: ''
				},
				registration: {
					value: '',
					classError: ''
				},
				phone1: {
					value: '',
					classError: ''
				},
				extensionNumber1: {
					value: '',
					classError: ''
				},
				phone2: {
					value: '',
					classError: ''
				},
				extensionNumber2: {
					value: '',
					classError: ''
				}
			},

			address: {
				cep: {
					value: '',
					classError: ''
				},
				address: {
					value: '',
					classError: ''
				},
				addressNumber: {
					value: '',
					classError: ''
				},
				complement: {
					value: '',
					classError: ''
				},
				neighborhood: {
					value: '',
					classError: ''
				},
				city: {
					value: '',
					classError: ''
				},
				uf: {
					value: '',
					classError: ''
				},
				isResidenciaFiscalBrasil: {
					value: 'true',
					classError: ''
				},
				residenciaFiscal: {
					id: '',
					value: '',
					label: '',
					classError: ''
				}
			},

			representative: {
				name: {
					value: '',
					classError: ''
				},
				email: {
					value: '',
					classError: ''
				},
				cel: {
					value: '',
					classError: ''
				}
			},

			wishReceiveEmail: false
		};

		this.emailMsgError = '';
		this.phone1ErrorMsg = '';
		this.celErrorMsg = '';
		this.addressMsgError = '';
		this.cityMsgError = '';
		this.neighborhoodMsgError = '';
		this.errorMessages = [];
		this.showSave = false;

		this.showSave = true;
		this.$scope.$emit('changeMainShowLoading', true);
		this.loadAccountInformation();
	}

	loadAccountInformation() {
		const accountId = this.profile.accountId;

		this.api.getAccountDetails(accountId).then((response) => {
			console.log(response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.account.id = accountId;
				this.account.company = {
					cnpj: {
						value: response.accountPJInfo.company.cnpj,
						classError: ''
					},
					companyName: {
						value: response.accountPJInfo.company.name,
						classError: ''
					},
					tradingName: {
						value: response.accountPJInfo.company.fantasyName,
						classError: ''
					},
					freeRegistration: {
						value: response.accountPJInfo.company.isento,
						classError: ''
					},
					registration: {
						value: response.accountPJInfo.company.inscription,
						classError: ''
					},
					phone1: {
						value: response.accountPJInfo.company.mainPhone,
						classError: ''
					},
					extensionNumber1: {
						value: response.accountPJInfo.company.ramal,
						classError: ''
					},
					phone2: {
						value: response.accountPJInfo.company.alternativePhone,
						classError: ''
					},
					extensionNumber2: {
						value: response.accountPJInfo.company.ramal2,
						classError: ''
					}
				};
				this.account.address = {
					cep: {
						value: response.accountPJInfo.address.cep,
						classError: ''
					},
					address: {
						value: response.accountPJInfo.address.address,
						classError: ''
					},
					addressNumber: {
						value: response.accountPJInfo.address.addressNumber,
						classError: ''
					},
					complement: {
						value: response.accountPJInfo.address.complement,
						classError: ''
					},
					neighborhood: {
						value: response.accountPJInfo.address.neighborhood,
						classError: ''
					},
					city: {
						value: response.accountPJInfo.address.city,
						classError: ''
					},
					uf: {
						value: response.accountPJInfo.address.uf,
						classError: ''
					},
					isResidenciaFiscalBrasil: {
						value: response.accountPJInfo.address.isResidenciaFiscalBrasil ? 'true' : 'false',
						classError: ''
					},
					residenciaFiscal: {
						id: response.accountPJInfo.address.idResidenciaFiscal,
						label: response.accountPJInfo.address.residenciaFiscal,
						classError: ''
					}
				};
				this.account.representative = {
					name: {
						value: response.accountPJInfo.company.contactName,
						classError: ''
					},
					email: {
						value: response.accountPJInfo.company.email,
						classError: ''
					},
					cel: {
						value: response.accountPJInfo.company.phone,
						classError: ''
					}
				};
				this.account.wishReceiveEmail = response.accountPJInfo.company.acceptEmail;
			}
		});
	}

	saveAccount(editedAccount) {
		this.api.saveAccountJuridical(editedAccount).then((response) => {
			console.log(response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.errorMessages = response.errorMessages;
				this.$window.scrollTo(0, 0);
			} else {
				if (this.sfConfig.imobiliaria.Id == editedAccount.id) {
					this.sfConfig.imobiliaria.Name = editedAccount.company.companyName.value;
				}

				this.redirectToDetail();
			}
		});
	}

	redirectToDetail() {
		this.$state.go('account-detail', { id: this.profile.accountId });
	}

	saveAccountRequest() {
		console.log(this.account);

		if (this.validateInputs()) {
			this.errorMessages = [];

			this.saveAccount(this.account);
		} else {
			this.$scope.$emit('changeMainShowLoading', false);
			this.errorMessages = ['Revise os campos marcados em vermelho.'];
			this.$window.scrollTo(0, angular.element(document.querySelector('.has-error')).offsetTop);
		}
	}

	validateInputs() {
		let result = true;
		this.$scope.$emit('changeMainShowLoading', true);

		if (this.isEditable.company.cnpj) {
			if (!this.valid.isValidCnpj(this.account.company.cnpj.value)) {
				this.account.company.cnpj.classError = inputErrorClass;
				result = false;
			} else {
				this.account.company.cnpj.classError = '';
			}
		}

		if (this.isEditable.company.companyName) {
			if (!this.account.company.companyName.value || !this.account.company.companyName.value.replace(/\s/g, '').length) {
				this.account.company.companyName.classError = inputErrorClass;
			} else {
				this.account.company.companyName.classError = '';
			}
		}

		if (this.account.company.phone1.value && !this.valid.isValidPhoneNumber(this.account.company.phone1.value)) {
			this.account.company.phone1.classError = inputErrorClass;
			this.phone1ErrorMsg = 'Campo inválido.';
			result = false;
		} else if (!this.account.company.phone1.value) {
			this.account.company.phone1.classError = inputErrorClass;
			this.phone1ErrorMsg = 'Campo obrigatório.';
			result = false;
		} else {
			this.account.company.phone1.classError = '';
		}

		if (this.account.company.phone2.value && !this.valid.isValidPhoneNumber(this.account.company.phone2.value)) {
			this.account.company.phone2.classError = inputErrorClass;
			result = false;
		} else {
			this.account.company.phone2.classError = '';
		}

		if (this.account.company.extensionNumber2.value && !this.account.company.phone2.value) {
			this.account.company.phone2.classError = inputErrorClass;
			result = false;
		} else {
			this.account.company.phone2.classError = '';
		}

		if (this.isEditable.company.freeRegistration && this.isEditable.company.registration) {
			if (!this.account.company.freeRegistration.value && !this.account.company.registration.value) {
				this.account.company.registration.classError = inputErrorClass;
				result = false;
			} else if (this.account.company.freeRegistration.value) {
				this.account.company.registration.classError = '';
				this.account.company.registration.value = '';
			} else if (!this.account.company.freeRegistration.value && this.account.company.registration.value) {
				this.account.company.registration.classError = '';
			}
		}

		if (!this.account.address.cep.value || !this.valid.isValidCep(this.account.address.cep.value)) {
			this.account.address.cep.classError = inputErrorClass;
			result = false;
		} else {
			this.account.address.cep.classError = '';
		}

		if (!this.account.address.address.value) {
			this.account.address.address.classError = inputErrorClass;
			this.addressMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			/*else if (this.account.address.address.value.length < 5) {
			this.account.address.address.classError = inputErrorClass;
			this.addressMsgError = 'O campo precisa ter pelo 5 letras';
			result = false;
		}*/
			this.account.address.address.classError = '';
		}

		if (!this.account.address.addressNumber.value) {
			this.account.address.addressNumber.classError = inputErrorClass;
			result = false;
		} else {
			this.account.address.addressNumber.classError = '';
		}

		if (!this.account.address.neighborhood.value) {
			this.account.address.neighborhood.classError = inputErrorClass;
			this.neighborhoodMsgError = 'Campo obrigatório.';
			result = false;
		} else if (this.account.address.neighborhood.value.length < 3) {
			this.account.address.neighborhood.classError = inputErrorClass;
			this.neighborhoodMsgError = 'O campo precisa ter pelo menos 3 letras';
			result = false;
		} else {
			this.account.address.neighborhood.classError = '';
		}

		if (!this.account.address.city.value) {
			this.account.address.city.classError = inputErrorClass;
			this.cityMsgError = 'Campo obrigatório.';
			result = false;
		} else if (this.account.address.city.value.length < 3) {
			this.account.address.city.classError = inputErrorClass;
			this.cityMsgError = 'O campo precisa ter pelo menos 3 letras';
			result = false;
		} else {
			this.account.address.city.classError = '';
		}

		if (!this.account.address.uf.value) {
			this.account.address.uf.classError = inputErrorClass;
			result = false;
		} else {
			this.account.address.uf.classError = '';
		}

		if (
			this.valid.hasInvalidSequence(this.account.representative.name.value) ||
			this.valid.hasSequencedSpace(this.account.representative.name.value) ||
			!this.valid.hasLastName(this.account.representative.name.value) ||
			this.valid.hasBadExpression(this.account.representative.name.value) ||
			this.account.representative.name.value.length < 5
		) {
			this.account.representative.name.classError = inputErrorClass;
			result = false;
		} else {
			this.account.representative.name.classError = '';
		}

		if (!this.account.representative.email.value) {
			this.account.representative.email.classError = inputErrorClass;
			this.emailMsgError = 'Campo obrigatório.';
			result = false;
		} else if (!this.valid.isValidEmail(this.account.representative.email.value)) {
			this.account.representative.email.classError = inputErrorClass;
			this.emailMsgError = 'Formato de email inválido.';
			result = false;
		} else {
			this.account.representative.email.classError = '';
		}

		if (this.account.representative.cel.value && !this.valid.isValidPhoneNumber(this.account.representative.cel.value)) {
			this.account.representative.cel.classError = inputErrorClass;
			this.celErrorMsg = 'Campo inválido.';
			result = false;
		} else if (!this.account.representative.cel.value) {
			this.account.representative.cel.classError = inputErrorClass;
			this.celErrorMsg = 'Campo obrigatório.';
			result = false;
		} else {
			this.account.representative.cel.classError = '';
		}

		if (this.account.address.isResidenciaFiscalBrasil.value == 'false' && this.account.address.residenciaFiscal != null && !this.account.address.residenciaFiscal.id) {
			this.account.address.residenciaFiscal.classError = inputErrorClass;
			result = false;
		} else {
			this.account.address.residenciaFiscal.classError = '';
		}

		console.log(JSON.stringify(this.account));

		return result;
	}

	searchResidenciaFiscal(searchString) {
		console.log('searchResidenciaFiscal : ', searchString, ' | this.$parent : ', this.$parent);
		return this.$parent.myAccount.api.searchResidenciaFiscal(searchString);
	}

	searchResidenciaFiscalSelect(item) {
		console.log('searchResidenciaFiscalSelect : ', item, ' | this.$parent : ', this.$parent);

		if (!item) {
			return;
		}

		const newJuridicalAccount = this.$parent.myAccount.account;

		newJuridicalAccount.address.residenciaFiscal = item.originalObject;
	}

	searchResidenciaFiscalFocusOut() {
		console.log('searchResidenciaFiscalFocusOut', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.account.address.residenciaFiscal);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchResidenciaFiscal');
			}
		}, 100);
	}

	searchResidenciaFiscalChanged() {
		console.log('searchResidenciaFiscalChanged', this);

		const newJuridicalAccount = this.$parent.myAccount.account;
		this.$parent.myAccount.autoComplete.resetSelected(newJuridicalAccount.address, 'residenciaFiscal');
	}

	searchNoResultText() {
		const resultText = 'Nenhum país encontrado';
		return resultText;
	}
}

module.exports = myAccountJuridicalEditController;
