'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {
	$stateProvider.state('case.alteracao-dados', {
		abstract: true,
		url: '/alteracao-dados',
		views: {
			case: {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/base.html`,
				controller: 'alteracaoDadosController',
				controllerAs: 'alteracaoDados'
			}
		}
	});

	$stateProvider.state('case.alteracao-dados.detail', {
		url: '/detalhes?:id',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-detail/alteracao-dados-detail.html`,
				controller: 'alteracaoDadosDetailController',
				controllerAs: 'caseDetail'
			}
		}
	});

	$stateProvider.state('case.alteracao-dados.new', {
		url: '/nova',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-new/alteracao-dados-new.html`,
				controller: 'alteracaoDadosNewController',
				controllerAs: 'alteracaoDadosNew'
			}
		}
	});

	/*$stateProvider.state('case.alteracao-dados.data', {
		url: '/dados',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-data/data.html`,
				controller: 'alteracaoDadosDataController',
				controllerAs: 'alteracaoDadosData'
			}
		}
	});*/

	$stateProvider.state('case.alteracao-dados.alteracaoImovel', {
		url: '/dados/mudanca-imovel',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-data/alteracao-imovel.html`,
				controller: 'alteracaoImovelController',
				controllerAs: 'alteracaoImovel'
			}
		}
	});

	$stateProvider.state('case.alteracao-dados.mudancaLocador', {
		url: '/dados/mudanca-locador',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-data/mudanca-locador.html`,
				controller: 'mudancaLocadorController',
				controllerAs: 'mudancaLocador'
			}
		}
	});

	$stateProvider.state('case.alteracao-dados.alteracaoDadosCliente', {
		url: '/dados/alteracao-dados-cliente',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-data/alteracao-dados-cliente.html`,
				controller: 'alteracaoDadosClienteController',
				controllerAs: 'alteracaoDadosCliente'
			}
		}
	});

	$stateProvider.state('case.alteracao-dados.transferenciaTitularidade', {
		url: '/dados/transferencia-titularidade',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-data/transferencia-titularidade.html`,
				controller: 'transferenciaTitularidadeController',
				controllerAs: 'transferenciaTitularidade'
			}
		}
	});

	$stateProvider.state('case.alteracao-dados.inclusaoCaucao', {
		url: '/dados/inclusao-caucao',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-data/inclusao-caucao.html`,
				controller: 'inclusaoCaucaoController',
				controllerAs: 'inclusaoCaucao'
			}
		}
	});

	$stateProvider.state('case.alteracao-dados.annex', {
		url: '/anexo',
		views: {
			'case.alteracao-dados': {
				templateUrl: `${sfConfig.resource}/views/case/alteracao-dados/alteracao-dados-upload/upload.html`,
				controller: 'alteracaoDadosAnnexController',
				controllerAs: 'uploadController'
			}
		}
	});
}

module.exports = routesConfiguration;
