'use strict';

const inputErrorClass = 'has-error';

class myAccountPersonEditController {
	// @ngInject
	constructor($state, $stateParams, $window, $scope, $filter, accountEditApi, validationFormFields, profile) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$window = $window;
		this.$scope = $scope;
		this.$filter = $filter;
		this.api = accountEditApi;
		this.valid = validationFormFields;
		this.$scope.$emit('changeMainShowLoading', false);
		this.profile = profile;
		this.isEditable = profile.permissions.myAccount.editableFields;
		console.log('isEditable', this.isEditable);

		if (!profile.permissions.myAccount.canEdit) {
			this.redirectToDetail();
		}

		this.account = {
			id: null,
			personal: {
				cpf: {
					value: '',
					classError: ''
				},
				name: {
					value: '',
					classError: ''
				},
				gender: {
					value: '',
					classError: ''
				},
				birthDate: {
					value: '',
					classError: ''
				},
				pep: {
					value: '',
					classError: ''
				},
				rg: {
					value: '',
					classError: ''
				},
				emitter: {
					value: '',
					classError: ''
				},
				uf: {
					value: '',
					classError: ''
				},
				expeditionDate: {
					value: '',
					classError: ''
				},
				cel: {
					value: '',
					classError: ''
				},
				phone: {
					value: '',
					classError: ''
				},
				email: {
					value: '',
					classError: ''
				},
				nac: {
					value: '',
					classError: ''
				},
				RE: {
					value: '',
					classError: ''
				}
			},

			address: {
				cep: {
					value: '',
					classError: ''
				},
				address: {
					value: '',
					classError: ''
				},
				addressNumber: {
					value: '',
					classError: ''
				},
				complement: {
					value: '',
					classError: ''
				},
				neighborhood: {
					value: '',
					classError: ''
				},
				city: {
					value: '',
					classError: ''
				},
				uf: {
					value: '',
					classError: ''
				}
			},

			wishReceiveEmail: false
		};

		this.emailMsgError = '';
		this.phoneMsgError = '';
		this.celMsgError = '';
		this.birthDateMsgError = '';
		this.expeditionDateMsgError = '';
		this.addressMsgError = '';
		this.cityMsgError = '';
		this.neighborhoodMsgError = '';
		this.errorMessages = [];

		this.datepickerOptions = {
			minDate: new Date(1700, 1, 3)
		};

		this.showSave = true;
		this.$scope.$emit('changeMainShowLoading', true);
		this.loadAccountInformation();

		// setInterval(() => { console.log('form', this.account); }, 20000);
	}

	loadAccountInformation() {
		const accountId = this.profile.accountId;

		this.api.getAccountDetails(accountId).then((response) => {
			console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.account.id = accountId;
				let birthDate = '';
				if (response.accountPFInfo.personal.birthDate !== '') {
					const dateB = response.accountPFInfo.personal.birthDate.split('/')[0];
					const monthB = response.accountPFInfo.personal.birthDate.split('/')[1];
					const yearB = response.accountPFInfo.personal.birthDate.split('/')[2];
					birthDate = `${monthB}/${dateB}/${yearB}`;
				}
				let expeditionDate = '';
				if (response.accountPFInfo.personal.expeditionDate !== '') {
					const expD = response.accountPFInfo.personal.expeditionDate.split('/')[0];
					const expM = response.accountPFInfo.personal.expeditionDate.split('/')[1];
					const expY = response.accountPFInfo.personal.expeditionDate.split('/')[2];
					expeditionDate = `${expM}/${expD}/${expY}`;
				}
				this.account.personal = {
					cpf: {
						value: response.accountPFInfo.personal.cpf,
						classError: ''
					},
					name: {
						value: response.accountPFInfo.personal.name,
						classError: ''
					},
					gender: {
						value: response.accountPFInfo.personal.gender === 'Masculino' ? 'M' : 'F',
						classError: ''
					},
					birthDate: {
						value: response.accountPFInfo.personal.birthDate !== '' ? Date.parse(birthDate) : null,
						classError: ''
					},
					pep: {
						value: response.accountPFInfo.personal.pep ? 'true' : 'false',
						classError: ''
					},
					rg: {
						value: response.accountPFInfo.personal.rg,
						classError: ''
					},
					emitter: {
						value: response.accountPFInfo.personal.emitter,
						classError: ''
					},
					uf: {
						value: response.accountPFInfo.personal.uf,
						classError: ''
					},
					expeditionDate: {
						value: response.accountPFInfo.personal.expeditionDate !== '' ? Date.parse(expeditionDate) : null,
						classError: ''
					},
					cel: {
						value: response.accountPFInfo.personal.cel,
						classError: ''
					},
					phone: {
						value: response.accountPFInfo.personal.phone,
						classError: ''
					},
					email: {
						value: response.accountPFInfo.personal.email,
						classError: ''
					}
				};
				this.account.address = {
					cep: {
						value: response.accountPFInfo.address.cep,
						classError: ''
					},
					address: {
						value: response.accountPFInfo.address.address,
						classError: ''
					},
					addressNumber: {
						value: response.accountPFInfo.address.addressNumber,
						classError: ''
					},
					complement: {
						value: response.accountPFInfo.address.complement,
						classError: ''
					},
					neighborhood: {
						value: response.accountPFInfo.address.neighborhood,
						classError: ''
					},
					city: {
						value: response.accountPFInfo.address.city,
						classError: ''
					},
					uf: {
						value: response.accountPFInfo.address.uf,
						classError: ''
					}
				};
				this.account.wishReceiveEmail = response.accountPFInfo.personal.acceptEmail;
			}

			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	closeDatepickerOnTabPress(keyEvent) {
		if (keyEvent.which === 9) {
			$('.uib-datepicker-popup').remove();
		}
	}

	focusBirthDate() {
		$('#birthDateId').focus();
	}

	focusExpDate() {
		$('#expeditionDateId').focus();
	}

	saveAccount(editedAccount) {
		this.api.saveAccountPerson(editedAccount).then((response) => {
			console.log('saveAccount', response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.errorMessages = response.errorMessages;
				this.$window.scrollTo(0, 0);
			} else {
				this.redirectToDetail();
			}
		});
	}

	saveAccountRequest(form) {
		form.$setSubmitted();
		// console.log('form.$invalid', this.validateInputs(), form.$invalid, form);

		if (this.validateInputs() && !form.$invalid) {
			this.errorMessages = [];

			const account = angular.copy(this.account);

			//@todo gambiarra pq o back só recebe data no formato 'dd/MM/yyyy'
			account.personal.birthDate.value = this.$filter('date')(account.personal.birthDate.value, 'dd/MM/yyyy');
			account.personal.expeditionDate.value = this.$filter('date')(account.personal.expeditionDate.value, 'dd/MM/yyyy');

			this.saveAccount(account);
		} else {
			this.$scope.$emit('changeMainShowLoading', false);
			this.errorMessages = ['Revise os campos marcados em vermelho.'];
			this.$window.scrollTo(0, angular.element(document.querySelector('.has-error')).offsetTop);
		}
	}

	redirectToDetail() {
		this.$state.go('account-detail', { id: this.profile.accountId });
	}

	validateInputs() {
		this.$scope.$emit('changeMainShowLoading', true);
		let result = true;

		// this.account.personal.birthDate.value = $('#birthDateId').val();
		// this.account.personal.expeditionDate.value = $('#expeditionDateId').val();

		if (this.isEditable.personal.cpf) {
			if (!this.valid.isValidCpf(this.account.personal.cpf.value)) {
				this.account.personal.cpf.classError = inputErrorClass;
				result = false;
			} else {
				this.account.personal.cpf.classError = '';
			}
		}

		if (this.isEditable.personal.name) {
			if (
				this.valid.hasInvalidSequence(this.account.personal.name.value) ||
				this.valid.hasSequencedSpace(this.account.personal.name.value) ||
				!this.valid.hasLastName(this.account.personal.name.value) ||
				this.valid.hasBadExpression(this.account.personal.name.value) ||
				this.account.personal.name.value.length < 5
			) {
				this.account.personal.name.classError = inputErrorClass;
				result = false;
			} else {
				this.account.personal.name.classError = '';
			}
		}

		if (!this.account.personal.gender.value) {
			this.account.personal.gender.classError = inputErrorClass;
			result = false;
		} else {
			this.account.personal.gender.classError = '';
		}

		// if (!this.account.personal.rg.value || !this.valid.isValidRg(this.account.personal.rg.value)) {
		// 	this.account.personal.rg.classError = inputErrorClass;
		// 	result = false;
		// } else {
		// 	this.account.personal.rg.classError = '';
		// }

		if (this.account.personal.rg.value && !this.valid.isValidRg(this.account.personal.rg.value)) {
			this.account.personal.rg.classError = inputErrorClass;
			this.rgMsgError = 'Campo inválido.';
			result = false;
		} else if (!this.account.personal.rg.value) {
			this.account.personal.rg.classError = inputErrorClass;
			this.rgMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			this.account.personal.rg.classError = '';
		}

		if (!this.account.personal.emitter.value) {
			this.account.personal.emitter.classError = inputErrorClass;
			result = false;
		} else {
			this.account.personal.emitter.classError = '';
		}

		if (!this.account.personal.uf.value) {
			this.account.personal.uf.classError = inputErrorClass;
			result = false;
		} else {
			this.account.personal.uf.classError = '';
		}
		console.log(this.account);
		const parsedBirthDate = new Date(this.account.personal.birthDate.value),
			parsedExpeditionDate = new Date(this.account.personal.expeditionDate.value);

		if (!parsedBirthDate) {
			this.account.personal.birthDate.classError = inputErrorClass;
			this.birthDateMsgError = 'Campo obrigatório';
			result = false;
		} else if (!this.valid.isBeforeToday(parsedBirthDate) || !this.valid.userHasMinimumAge(parsedBirthDate)) {
			this.account.personal.birthDate.classError = inputErrorClass;
			this.birthDateMsgError = 'São permitidos apenas cadastros de maiores de 18 anos';
			result = false;
		} else {
			this.account.personal.birthDate.classError = '';
		}

		if (!parsedExpeditionDate) {
			this.account.personal.expeditionDate.classError = inputErrorClass;
			this.expeditionDateMsgError = 'Campo obrigatório.';
			result = false;
		} else if (!this.valid.isBeforeToday(parsedExpeditionDate)) {
			this.account.personal.expeditionDate.classError = inputErrorClass;
			this.expeditionDateMsgError = 'Campo inválido.';
			result = false;
		} else {
			this.account.personal.expeditionDate.classError = '';
		}

		if (parsedExpeditionDate < parsedBirthDate) {
			this.account.personal.birthDate.classError = inputErrorClass;
			this.account.personal.expeditionDate.classError = inputErrorClass;
			this.birthDateMsgError = 'Campo inválido.';
			this.expeditionDateMsgError = 'Campo inválido.';
			result = false;
		}

		if (this.account.personal.cel.value && !this.valid.isValidPhoneNumber(this.account.personal.cel.value)) {
			this.account.personal.cel.classError = inputErrorClass;
			this.celMsgError = 'Campo inválido.';
			result = false;
		} else if (!this.account.personal.cel.value) {
			this.account.personal.cel.classError = inputErrorClass;
			this.celMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			this.account.personal.cel.classError = '';
		}

		if (this.account.personal.phone.value && !this.valid.isValidPhoneNumber(this.account.personal.phone.value)) {
			this.account.personal.phone.classError = inputErrorClass;
			this.phoneMsgError = 'Campo inválido.';
			result = false;
		} else if (!this.account.personal.phone.value) {
			this.account.personal.phone.classError = inputErrorClass;
			this.phoneMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			this.account.personal.phone.classError = '';
		}

		if (!this.account.personal.email.value) {
			this.account.personal.email.classError = inputErrorClass;
			this.emailMsgError = 'Campo obrigatório.';
			result = false;
		} else if (!this.valid.isValidEmail(this.account.personal.email.value)) {
			this.account.personal.email.classError = inputErrorClass;
			this.emailMsgError = 'Formato de email inválido.';
			result = false;
		} else {
			this.account.personal.email.classError = '';
		}

		if (!this.account.address.cep.value) {
			this.account.address.cep.classError = inputErrorClass;
			this.cepMsgError = 'Campo obrigatório.';
			result = false;
		} else if (!this.valid.isValidCep(this.account.address.cep.value)) {
			this.account.address.cep.classError = inputErrorClass;
			this.cepMsgError = 'Campo inválido.';
			result = false;
		} else {
			this.account.address.cep.classError = '';
		}

		if (!this.account.address.address.value) {
			this.account.address.address.classError = inputErrorClass;
			this.addressMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			/*else if (this.account.address.address.value.length < 5) {
				this.account.address.address.classError = inputErrorClass;
				this.addressMsgError = 'O campo precisa ter pelo 5 letras';
				result = false;
			}*/
			this.account.address.address.classError = '';
		}

		if (!this.account.address.addressNumber.value) {
			this.account.address.addressNumber.classError = inputErrorClass;
			result = false;
		} else {
			this.account.address.addressNumber.classError = '';
		}

		if (!this.account.address.neighborhood.value) {
			this.account.address.neighborhood.classError = inputErrorClass;
			this.neighborhoodMsgError = 'Campo obrigatório.';
			result = false;
		} else if (this.account.address.neighborhood.value.length < 3) {
			this.account.address.neighborhood.classError = inputErrorClass;
			this.neighborhoodMsgError = 'O campo precisa ter pelo menos 3 letras';
			result = false;
		} else {
			this.account.address.neighborhood.classError = '';
		}

		if (!this.account.address.city.value) {
			this.account.address.city.classError = inputErrorClass;
			this.cityMsgError = 'Campo obrigatório.';
			result = false;
		} else if (this.account.address.city.value.length < 3) {
			this.account.address.city.classError = inputErrorClass;
			this.cityMsgError = 'O campo precisa ter pelo menos 3 letras';
			result = false;
		} else {
			this.account.address.city.classError = '';
		}

		if (!this.account.address.uf.value) {
			this.account.address.uf.classError = inputErrorClass;
			result = false;
		} else {
			this.account.address.uf.classError = '';
		}

		// console.log(JSON.stringify(this.account));

		return result;
	}
}

module.exports = myAccountPersonEditController;
