'use strict';

class alteracaoDadosFormControlService {
	// @ngInject
	constructor(alteracaoDadosFormValidationService, validationFormFields) {
		console.log('construtor da formservice');
		this.formValidationService = alteracaoDadosFormValidationService;
		this.valid = validationFormFields;
		this.setPristine();
		this.initRequiredFunctions();
		this.initIsValidFunctions();
		this.initStateMap();
		this.initWizardSteps();
		this.initTypeOptions();
		this.formValidationService.setFormField(this);
	}

	setPristine() {
		this.status = null;
		this.formStep = null;
		this.id = null;
		this.edit = false;
		this.lastFormAction = ''; //tem uma referência num arquivo setando essa propriedade

		this.setButtonControlPristine();
		this.setInformationPristine();
		this.setDataPristine();
		this.setAnnexPristine();
	}

	initWizardSteps() {
		this.wizardSteps = {
			first: 'information',
			second: 'data',
			last: 'annex'
		};
	}

	//@todo botar todos aqui
	initStateMap() {
		this.stateMap = {
			alteracaoImovel: 'case.alteracao-dados.alteracaoImovel',
			mudancaLocador: 'case.alteracao-dados.mudancaLocador',
			alteracaoDadosCliente: 'case.alteracao-dados.alteracaoDadosCliente',
			inclusaoCaucao: 'case.alteracao-dados.inclusaoCaucao',
			transferenciaTitularidade: 'case.alteracao-dados.transferenciaTitularidade'
		};
	}

	initRequiredFunctions() {
		this.hasRequiredFunctions = {
			information: this.hasRequiredFieldsInformationForm,
			alteracaoImovel: this.hasRequiredFieldsAlteracaoImovelForm,
			mudancaLocador: this.hasRequiredFieldsMudancaLocadorForm,
			alteracaoDadosCliente: this.hasRequiredFieldsAlteracaoDadosClienteForm,
			transferenciaTitularidade: this.hasRequiredFieldsTransferenciaTitularidadeForm,
			inclusaoCaucao: this.hasRequiredFieldsInclusaoCaucaoForm,
			annex: this.hasRequiredFieldsAnnexForm
		};
	}

	initIsValidFunctions() {
		this.isValidFunctions = {
			information: this.isValidFieldsInformationForm,
			alteracaoImovel: this.isValidFieldsAlteracaoImovelForm,
			mudancaLocador: this.isValidFieldsMudancaLocadorForm,
			alteracaoDadosCliente: this.isValidFieldsAlteracaoDadosClienteForm,
			transferenciaTitularidade: this.isValidFieldsTransferenciaTitularidadeForm,
			inclusaoCaucao: this.isValidFieldsInclusaoCaucaoForm,
			annex: this.isValidFieldsAnnexForm
		};
	}

	initTypeOptions() {
		this.allTypeOptions = {
			mudancaImovel: 'Mudança de imóvel/ locador',
			mudancaLocador: 'Mudança de Locador',
			correcaoDadosCliente: 'Alteração de Dados do Cliente',
			inclusaoCaucao: 'Inclusão da Garantia',
			transferenciaTitularidade: 'Transferência de Titularidade'
		};
	}

	resetPropertyErrors(obj) {
		for (prop in obj) {
			if (prop == 'hasError') {
				obj[prop] = false;
			} else if (typeof obj[prop] == 'object') {
				resetPropertyErrors(obj[prop]);
			}
		}
	}

	requireProperty(pageFields, propertyName) {
		const field = pageFields[propertyName];
		field.hasError = !field.value;
	}

	setButtonControlPristine() {
		this.buttonControl = {
			cancel: {
				show: true,
				disabled: true
			},
			previous: {
				show: false,
				disabled: true
			},
			next: {
				show: true,
				disabled: false
			},
			save: {
				show: true,
				disabled: true
			},
			finish: {
				show: false,
				disabled: true
			}
		};
	}

	setDataPristine() {
		this.data = {
			active: false,
			status: 'Não Iniciado',
			subCaseType: '',
			alteracaoImovel: {
				fields: {
					cep: { value: null, hasError: false, isValid: true, message: null },
					logradouro: { value: null, hasError: false },
					numero: { value: null, hasError: false },
					complemento: { value: null, hasError: false, required: false }, //@todo colocar o atributo required nos outros campos( ou não! :() )
					bairro: { value: null, hasError: false },
					cidade: { value: null, hasError: false },
					uf: { value: null, hasError: false },
					tipoImovel: { value: null, hasError: false },
					finalidade: { value: null, hasError: false },
					newLocador: { value: null, hasError: false, isValid: true },
					observacoes: { value: null, hasError: false, required: false }
				}
			},
			mudancaLocador: {
				fields: {
					newLocador: { value: null, hasError: false },
					observacoes: { value: null, hasError: false, required: false }
				}
			},
			alteracaoDadosCliente: {
				isPersonAccount: null,
				fields: {
					pf: {
						cpf: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						nome: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidName, required: false },
						sexo: { value: null, hasError: false, isValid: true, required: false },
						dataNascimento: { value: null, hasError: false, isValid: true, required: false },
						identidade: { value: null, hasError: false, isValid: true, required: false },
						orgaoEmissor: { value: null, hasError: false, isValid: true, required: false },
						uf: { value: null, hasError: false, isValid: true, required: false },
						dataExpedicao: { value: null, hasError: false, isValid: true, required: false },
						celular: { value: null, hasError: false, isValid: true, required: false },
						telefoneFixo: { value: null, hasError: false, isValid: true, required: false },
						email: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidEmail, required: false },
						observacoes: { value: null, hasError: false, required: false }
					},
					pj: {
						cnpj: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCnpj, required: false },
						nomeFantasia: { value: null, hasError: false, isValid: true, required: false },
						razaoSocial: { value: null, hasError: false, isValid: true, required: false },
						isentoInscricaoEstadual: { value: null, hasError: false, isValid: true, required: false },
						inscricaoEstadual: { value: null, hasError: false, isValid: true, required: false },
						telefoneComercial: { value: null, hasError: false, isValid: true, required: false },
						ramal: { value: null, hasError: false, isValid: true, required: false },
						telefoneComercialDois: { value: null, hasError: false, isValid: true, required: false },
						ramalDois: { value: null, hasError: false, isValid: true, required: false },
						observacoes: { value: null, hasError: false, required: false }
					}
				}
			},
			inclusaoCaucao: {
				fields: {
					cep: { value: null, hasError: false, isValid: true, message: null },
					logradouro: { value: null, hasError: false },
					numero: { value: null, hasError: false },
					complemento: { value: null, hasError: false, required: false }, //@todo colocar o atributo required nos outros campos( ou não! :() )
					bairro: { value: null, hasError: false },
					cidade: { value: null, hasError: false },
					uf: { value: null, hasError: false },
					tipoImovel: { value: null, hasError: false },
					finalidade: { value: null, hasError: false },
					newLocador: { value: null, hasError: false, isValid: true },
					administradorDiferenteLocador: { value: null, hasError: null },
					administradorImovel: { value: null, hasError: null, show: false },
					locatarioDiferenteTitular: { value: false, hasError: null, required: false },
					cpfLocatario: { value: null, hasError: null, show: false, validateFunction: this.valid.isValidCpf },
					nomeLocatario: { value: null, hasError: null, show: false, validateFunction: this.valid.isValidName },
					percentualDireito: { value: null, hasError: null, show: false },
					observacoes: { value: null, hasError: false, required: false }
				}
			},
			transferenciaTitularidade: {
				isNovoTitularPf: null,
				fields: {
					novoTitular: { value: null, hasError: false, isValid: true, required: false },
					titularSearch: { value: null, hasError: false, isValid: true, required: false },
					pf: {
						nationality: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						secondNationality: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						birthCountry: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						profession: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						secNac: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						renda: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						residenciaFiscal: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						cpf: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						nome: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidName, required: false },
						sexo: { value: null, hasError: false, isValid: true, required: false },
						dataNascimento: { value: null, hasError: false, isValid: true, required: false },
						identidade: { value: null, hasError: false, isValid: true, required: false },
						orgaoEmissor: { value: null, hasError: false, isValid: true, required: false },
						uf: { value: null, hasError: false, isValid: true, required: false },
						dataExpedicao: { value: null, hasError: false, isValid: true, required: false },
						celular: { value: null, hasError: false, isValid: true, required: false },
						telefoneFixo: { value: null, hasError: false, isValid: true, required: false },
						email: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidEmail, required: false },
						observacoes: { value: null, hasError: false, required: false },
						cep: { value: null, hasError: false, isValid: true, required: false, errorMsg: null },
						logradouro: { value: null, hasError: false, isValid: true, required: false },
						numero: { value: null, hasError: false, isValid: true, required: false },
						complemento: { value: null, hasError: false, isValid: true, required: false },
						bairro: { value: null, hasError: false, isValid: true, required: false },
						cidade: { value: null, hasError: false, isValid: true, required: false },
						ufCep: { value: null, hasError: false, isValid: true, required: false }
					},
					pj: {
						profession: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						faturamento: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpf, required: false },
						cnpj: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCnpj, required: false },
						nomeFantasia: { value: null, hasError: false, isValid: true, required: false },
						razaoSocial: { value: null, hasError: false, isValid: true, required: false },
						isentoInscricaoEstadual: { value: null, hasError: false, isValid: true, required: false },
						inscricaoEstadual: { value: null, hasError: false, isValid: true, required: false },
						telefoneComercial: { value: null, hasError: false, isValid: true, required: false },
						ramal: { value: null, hasError: false, isValid: true, required: false },
						telefoneComercialDois: { value: null, hasError: false, isValid: true, required: false },
						ramalDois: { value: null, hasError: false, isValid: true, required: false },
						observacoes: { value: null, hasError: false, required: false },
						cep: { value: null, hasError: false, isValid: true, required: false, errorMsg: null },
						logradouro: { value: null, hasError: false, isValid: true, required: false },
						numero: { value: null, hasError: false, isValid: true, required: false },
						complemento: { value: null, hasError: false, isValid: true, required: false },
						bairro: { value: null, hasError: false, isValid: true, required: false },
						cidade: { value: null, hasError: false, isValid: true, required: false },
						ufCep: { value: null, hasError: false, isValid: true, required: false }
					}
				}
			}
		};
	}

	setInformationPristine() {
		console.log('setInformationPristine;');

		this.information = {
			active: false,
			status: 'Não Iniciado',
			fields: {
				mainAccount: {
					value: null,
					hasError: false,
					isValid: true,
					loading: false,
					show: true,
					isPersonAccount: false
				},
				proposta: {
					value: null,
					hasError: false,
					loading: false,
					show: true
				},
				garantias: {
					value: null,
					hasError: false,
					loading: false,
					show: false
				},
				alteracaoType: {
					value: null,
					hasError: false,
					hasWarning: false,
					show: true
				},
				legalRepresentative: {
					value: null,
					hasError: false,
					loading: false,
					show: false
				}
			}
		};
	}

	hasRequiredFieldsInformationForm(formControl, setFieldErrors) {
		//parametro formControl é por causa do contexto
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsInformationForm(setFieldErrors);
	}

	hasRequiredFieldsAlteracaoImovelForm(formControl, setFieldErrors) {
		//parametro formControl é por causa do contexto
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsAlteracaoImovelForm(setFieldErrors);
	}

	hasRequiredFieldsMudancaLocadorForm(formControl, setFieldErrors) {
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsMudancaLocadorForm(setFieldErrors);
	}

	hasRequiredFieldsAlteracaoDadosClienteForm(formControl, setFieldErrors) {
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsAlteracaoDadosClienteForm(setFieldErrors);
	}

	hasRequiredFieldsTransferenciaTitularidadeForm(formControl, setFieldErrors) {
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsTransferenciaTitularidadeForm(setFieldErrors);
	}

	hasRequiredFieldsInclusaoCaucaoForm(formControl, setFieldErrors) {
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsInclusaoCaucaoForm(setFieldErrors);
	}

	hasRequiredFieldsAnnexForm(formControl, setFieldErrors) {
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsAnnexForm(setFieldErrors);
	}

	hasRequiredFields(page, setFieldErrors) {
		if (!page) return;
		return this.hasRequiredFunctions[page](this, setFieldErrors);
	}

	isValidFieldsInformationForm(formControl) {
		return formControl.formValidationService.isValidFieldsInformationForm();
	}

	isValidFieldsAlteracaoImovelForm(formControl) {
		return formControl.formValidationService.isValidFieldsAlteracaoImovelForm();
	}

	isValidFieldsMudancaLocadorForm(formControl) {
		return formControl.formValidationService.isValidFieldsMudancaLocadorForm();
	}

	isValidFieldsAlteracaoDadosClienteForm(formControl) {
		return formControl.formValidationService.isValidFieldsAlteracaoDadosClienteForm();
	}

	isValidFieldsTransferenciaTitularidadeForm(formControl) {
		return formControl.formValidationService.isValidFieldsTransferenciaTitularidadeForm();
	}

	isValidFieldsInclusaoCaucaoForm(formControl) {
		return formControl.formValidationService.isValidFieldsInclusaoCaucaoForm();
	}

	isValidFieldsAnnexForm(formControl) {
		return formControl.formValidationService.isValidFieldsAnnexForm();
	}

	isValidFields(page) {
		if (!page) return;
		return this.isValidFunctions[page](this);
	}

	setAnnexPristine() {
		this.annex = {
			active: false,
			status: 'Não Iniciado',
			form: this.pristineAnnexObj(),
			others: this.pristineAnnexObj(),
			legalRepresentative: {
				show: true, //todo dinamico
				document: this.pristineAnnexObj(),
				identityRenter: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			},
			locationContract: {
				show: true, //todo dinamico
				document: this.pristineAnnexObj()
			},
			subscritor: {
				show: true, //todo dinamico
				identityRenter: this.pristineAnnexObj(),
				//cpf: this.pristineAnnexObj(), CAP-3543
				//adressCheck: this.pristineAnnexObj(), CAP-3543
				otherPf: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constitutionIdentity: this.pristineAnnexObj(),
				adminIdentity: this.pristineAnnexObj(),
				otherPj: this.pristineAnnexObj()
			},
			transferenciaTitularidade: {
				show: true, //todo dinamico
				//formularioTitularidade: this.pristineAnnexObj(),
				novoContrato: this.pristineAnnexObj(),
				novoTitularDOC: this.pristineAnnexObj(),
				outros: this.pristineAnnexObj()
			}
		};

		this.annex.form.type = 'form';
		this.annex.others.type = 'caucao-others';
		this.annex.legalRepresentative.document.type = 'legalRepresentative-document';
		this.annex.legalRepresentative.identityRenter.type = 'legalRepresentative-identityRenter';
		this.annex.legalRepresentative.other.type = 'legal-other';
		this.annex.locationContract.document.type = 'location-contract-document';
		this.annex.subscritor.identityRenter.type = 'subscritor-identityRenter';
		//this.annex.subscritor.cpf.type = 'subscritor-cpf'; CAP-3543
		//this.annex.subscritor.adressCheck.type = 'subscritor-adressCheck'; CAP-3543
		this.annex.subscritor.otherPf.type = 'subscritor-otherPf';
		this.annex.subscritor.cnpj.type = 'subscritor-cnpj';
		this.annex.subscritor.constitutionIdentity.type = 'subscritor-constitutionIdentity';
		this.annex.subscritor.adminIdentity.type = 'subscritor-adminIdentity';
		this.annex.subscritor.otherPj.type = 'subscritor-otherPj';

		//this.annex.transferenciaTitularidade.formularioTitularidade.type = 'formulario-transferencia-titularidade';
		this.annex.transferenciaTitularidade.novoContrato.type = 'transferencia-novo-contrato';
		this.annex.transferenciaTitularidade.novoTitularDOC.type = 'transferencia-novo-titular-doc';
		this.annex.transferenciaTitularidade.outros.type = 'transferencia-outros';

		this.annex.others.required = false;
		this.annex.legalRepresentative.other.required = false;
		this.annex.subscritor.otherPf.required = false;
		this.annex.subscritor.otherPj.required = false;
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	setFormField(json) {
		let self = this;
		self = json;
	}

	getFormField() {
		return this;
	}

	getButtonControl() {
		return this.buttonControl;
	}

	activateTab(tab) {
		const tabs = {
			information: this.information,
			data: this.data,
			annex: this.annex
		};

		for (const t in tabs) {
			tabs[t].active = t == tab;
		}
	}

	//@todo refactory. Pode ter redundância
	setStepButtons(step) {
		if (step == this.wizardSteps.first) {
			this.buttonControl.previous.show = false;
			this.buttonControl.previous.disabled = true;
			this.buttonControl.next.show = true;
			this.buttonControl.next.disabled = false;
			this.buttonControl.finish.show = false;
			this.buttonControl.finish.disabled = true;
		} else if (step == this.wizardSteps.last) {
			this.buttonControl.previous.show = true;
			this.buttonControl.previous.disabled = false;
			this.buttonControl.next.show = false;
			this.buttonControl.next.disabled = true;
			this.buttonControl.finish.show = true;
			this.buttonControl.finish.disabled = false;
		} else {
			this.buttonControl.previous.show = true;
			this.buttonControl.previous.disabled = false;
			this.buttonControl.next.show = true;
			this.buttonControl.next.disabled = false;
			this.buttonControl.finish.show = false;
			this.buttonControl.finish.disabled = true;
		}
	}

	validateField(formField, field) {
		const fieldObj = formField[field];
		if (this.nullOrEmpty(formField, field)) {
			fieldObj.isValid = true;
		} else {
			if (!fieldObj.validateFunction) return;
			fieldObj.isValid = fieldObj.validateFunction.call(this.valid, fieldObj.value);
		}
	}

	nullOrEmpty(formField, field) {
		return formField[field].value === null || formField[field].value === '';
	}

	inscricaoEstadualRequired(context) {
		return !context.data.alteracaoDadosCliente.fields.pj.isentoInscricaoEstadual.value;
	}

	extractEditCaseData() {
		return {
			status: this.status,
			formStep: this.formStep,
			id: this.id,

			information: this.information,
			data: this.data,
			annex: this.annex,
			buttonControl: this.buttonControl
		};
	}
}

module.exports = alteracaoDadosFormControlService;
