'use strict';

class inclusaoCaucaoController {
	// @ngInject
	constructor(
		$scope,
		$window,
		$uibModal,
		$state,
		feedbackMessages,
		alteracaoDadosFormControlService,
		inclusaoCaucaoService,
		cepHelper,
		alteracaoDadosSetupAccountService,
		autoCompleteService,
		accountUtilService,
		crivoHelper
	) {
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.feedbackMessages = feedbackMessages;
		this.formField = alteracaoDadosFormControlService;
		this.api = inclusaoCaucaoService;
		this.cepHelper = cepHelper;
		this.alteracaoDadosSetupAccountService = alteracaoDadosSetupAccountService;
		this.autoComplete = autoCompleteService;
		this.accountUtilService = accountUtilService;
		this.crivoHelper = crivoHelper;
		this.crivoHelper.setPristine();

		this.cepHelper.setPristine();
		this.checkNewLocador();
		this.checkNewAdministradorImovel();
		this.setFromInclusaoCaucao(false);

		this.formField.data.status = 'Em Andamento';
		this.initComboFieldsOptions();
		this.listenDuplicatedCpfValidationResponse();

		console.log('Tipo Alteração', this.formField.information.fields.alteracaoType.value);
		console.log('Form Field:', this.formField);
	}

	setFromInclusaoCaucao(value) {
		this.alteracaoDadosSetupAccountService.setFromInclusaoCaucaoLocador(false);
		this.alteracaoDadosSetupAccountService.setFromInclusaoCaucaoAdministradorImovel(false);
	}

	checkNewLocador() {
		const newLocador = this.alteracaoDadosSetupAccountService.getNewLocador();

		if (newLocador != null) {
			this.formField.activateTab('data');
			this.formField.data.inclusaoCaucao.fields.newLocador.value = newLocador;
		}
	}

	checkNewAdministradorImovel() {
		const newAdministradorImovel = this.alteracaoDadosSetupAccountService.getAdministradorImovel();

		if (newAdministradorImovel != null) {
			this.formField.activateTab('data');
			this.formField.data.inclusaoCaucao.fields.administradorImovel.value = newAdministradorImovel;
		}
	}

	listenDuplicatedCpfValidationResponse() {
		this.$scope.$on('inclusaoCaucaoDuplicatedCpfValidationResponse', (event, response) => {
			//console.log('escutando duplicatedCpfValidationResponse')
			if (response.length === 0) {
				this.formField.data.inclusaoCaucao.fields.newLocador.isValid = true;
				this.formField.data.inclusaoCaucao.fields.administradorImovel.isValid = true;
			} else {
				for (let i = 0; i < response.length; i++) {
					if (this.formField.data.inclusaoCaucao.fields.newLocador.value != null && response[i] === this.formField.data.inclusaoCaucao.fields.newLocador.value.cpf) {
						this.feedbackMessages.showErrorMessage(this.$scope, 'CPF/CNPJ já informado na solicitação');
						this.formField.data.inclusaoCaucao.fields.newLocador.isValid = false;
					} else {
						this.formField.data.inclusaoCaucao.fields.newLocador.isValid = true;
					}

					if (
						this.formField.data.inclusaoCaucao.fields.administradorImovel.value != null &&
						response[i] === this.formField.data.inclusaoCaucao.fields.administradorImovel.value.cpf
					) {
						this.feedbackMessages.showErrorMessage(this.$scope, 'CPF/CNPJ já informado na solicitação');
						this.formField.data.inclusaoCaucao.fields.administradorImovel.isValid = false;
					} else {
						this.formField.data.inclusaoCaucao.fields.administradorImovel.isValid = true;
					}
				}
			}
		});
	}

	initComboFieldsOptions() {
		this.comboOptions = {
			tipoImovel: ['Apartamento', 'Box', 'Casa', 'Flat', 'Galpão', 'Loja', 'Quiosque', 'Sala Comercial', 'Outros'], //tipoImovel: ['Casa', 'Apartamento'],
			finalidade: ['Comercial', 'Outros', 'Residencial', 'Veraneio'] //finalidade: ['Residencial', 'Comercial']
		};
	}

	requireProperty(propertyName) {
		this.formField.requireProperty(this.formField.data.inclusaoCaucao.fields, propertyName);
	}

	locadorSearchAccount(searchString) {
		console.log('entrou no searchAccount');
		const inclusaoCaucao = this.$parent.inclusaoCaucao;
		return inclusaoCaucao.api.locadorSearchAccount(searchString);
	}

	administradorImovelSearchAccount(searchString) {
		console.log('entrou no searchAccount');
		const inclusaoCaucao = this.$parent.inclusaoCaucao;
		return inclusaoCaucao.api.administradorImovelSearchAccount(searchString);
	}

	locadorSearchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const inclusaoCaucao = this.$parent.inclusaoCaucao;
		if (inclusaoCaucao.formField.data.inclusaoCaucao.fields.newLocador.value === null) {
			if (!item) {
				return;
			}

			const hasAction = !!item.originalObject.action;

			if (hasAction) {
				const action = item.originalObject.action;

				inclusaoCaucao[action](item);

				return;
			}

			inclusaoCaucao.formField.data.inclusaoCaucao.fields.newLocador.value = item.originalObject;
			inclusaoCaucao.formField.data.inclusaoCaucao.fields.newLocador.hasError = !inclusaoCaucao.formField.data.inclusaoCaucao.fields.newLocador.value;
		}
		inclusaoCaucao.validateDuplicatedCpf();
	}

	administradorImovelSearchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const inclusaoCaucao = this.$parent.inclusaoCaucao;
		if (inclusaoCaucao.formField.data.inclusaoCaucao.fields.administradorImovel.value === null) {
			if (!item) {
				return;
			}

			const hasAction = !!item.originalObject.action;

			if (hasAction) {
				const action = item.originalObject.action;

				inclusaoCaucao[action](item);

				return;
			}

			inclusaoCaucao.formField.data.inclusaoCaucao.fields.administradorImovel.value = item.originalObject;
			inclusaoCaucao.formField.data.inclusaoCaucao.fields.administradorImovel.hasError = !inclusaoCaucao.formField.data.inclusaoCaucao.fields.administradorImovel.value;
		}
		inclusaoCaucao.validateDuplicatedCpf();
	}

	validateDuplicatedCpf() {
		this.$scope.$emit('inclusaoCaucaoDuplicatedCpfValidation', this.formField);
	}

	locadorSearchAccountChanged(field) {
		const inclusaoCaucao = this.$parent.inclusaoCaucao; //CAP-2706
		console.log('entrou no searchAccountChanged');
		inclusaoCaucao.feedbackMessages.cleanMessages(inclusaoCaucao.$scope);
		console.log('reset');
		inclusaoCaucao.autoComplete.resetSelected(inclusaoCaucao.formField.data.inclusaoCaucao.fields.newLocador, 'value');
		//resetar todos os dados
	}

	administradorImovelSearchAccountChanged(field) {
		const inclusaoCaucao = this.$parent.inclusaoCaucao;

		inclusaoCaucao.feedbackMessages.cleanMessages(inclusaoCaucao.$scope);

		inclusaoCaucao.autoComplete.resetSelected(inclusaoCaucao.formField.data.inclusaoCaucao.fields.administradorImovel, 'value');
	}

	//@todo Horrível. copiado da proposta.
	locadorModalHandle() {
		//this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromInclusaoCaucaoLocador(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromInclusaoCaucaoLocador(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
		this.autoComplete.clearInput(this.$scope, 'searchAccount');
	}

	//@todo Horrível. copiado da proposta.
	administradorImovelModalHandle() {
		//this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromInclusaoCaucaoAdministradorImovel(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromInclusaoCaucaoAdministradorImovel(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
		this.autoComplete.clearInput(this.$scope, 'administradorImovelSearchAccount');
	}

	getAddressFromCEP() {
		//const me = this;
		const cep = this.formField.data.inclusaoCaucao.fields.cep;

		if (cep.value.length == 9 && cep.value.indexOf('-') == 5) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.cepHelper.getAddressFromCEP(cep.value, (response) => {
				this.feedbackMessages.cleanMessages(this.$scope);

				this.formField.data.inclusaoCaucao.fields.logradouro.value = response.logradouro;
				this.formField.data.inclusaoCaucao.fields.bairro.value = response.bairro;
				this.formField.data.inclusaoCaucao.fields.cidade.value = response.cidade;
				this.formField.data.inclusaoCaucao.fields.uf.value = response.uf;

				if (!response.isSuccessful) {
					if (this.cepHelper.remoteCallErrorLimitWasExceded()) {
						cep.hasError = false;
						cep.value = null;
						this.feedbackMessages.showErrorMessage(this.$scope, this.cepHelper.remoteCallErrorMessage);
					} else {
						cep.isValid = false;
						this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
						this.$window.scrollTo(0, 0);
						//cep.message = response.errorMessages[0];
					}
					this.cepHelper.setPristine();
				} else {
					this.requireProperty('logradouro');
					this.requireProperty('bairro');
					this.requireProperty('cidade');
					this.requireProperty('uf');
					//this.validated.clientCepError = false;
					cep.isValid = true;
					cep.message = null;
					this.feedbackMessages.showErrorMessage(this.$scope, '');
				}

				this.$scope.$emit('changeMainShowLoading', false);
			});
		} else {
			this.cepHelper.setPristine();
			this.formField.data.inclusaoCaucao.fields.logradouro.value = null;
			this.formField.data.inclusaoCaucao.fields.bairro.value = null;
			this.formField.data.inclusaoCaucao.fields.cidade.value = null;
			this.formField.data.inclusaoCaucao.fields.uf.value = null;
		}
	}

	nullOrEmpty(field) {
		return this.formField.nullOrEmpty(this.formField.data.inclusaoCaucao.fields, field);
	}

	validateField(field) {
		this.formField.validateField(this.formField.data.inclusaoCaucao.fields, field);
	}

	changeLocatarioDiferenteTitular() {
		const affectedFields = ['cpfLocatario', 'nomeLocatario', 'percentualDireito'];
		const fields = this.formField.data.inclusaoCaucao.fields;
		for (let i = 0; i < affectedFields.length; i++) {
			fields[affectedFields[i]].show = fields.locatarioDiferenteTitular.value;
		}
	}

	changeAdministradorDiferenteLocador() {
		const fields = this.formField.data.inclusaoCaucao.fields;

		fields.administradorImovel.value = null;
		this.validateDuplicatedCpf();
		this.autoComplete.clearInput(this.$scope, 'administradorImovelSearchAccount');
		fields.administradorImovel.show = fields.administradorDiferenteLocador.value;
	}

	consultarCrivo() {
		const me = this;
		const cpfCnpj = me.formField.data.inclusaoCaucao.fields.cpfLocatario.value;

		const isPersonal = this.accountUtilService.isValidCpf(cpfCnpj);
		const isCompany = this.accountUtilService.isValidCnpj(cpfCnpj);

		if (isPersonal || isCompany) {
			me.$scope.$emit('changeMainShowLoading', true);

			me.crivoHelper.getRecaptchaLoginCall().then((recaptchaLogin) => {
				console.log('response recaptchaLogin: ', recaptchaLogin);
				grecaptcha.ready(function () {
					grecaptcha.execute(recaptchaLogin, { action: 'submit' }).then(function (token) {
						console.log('---- 2. GTOKEN: ', token);

						me.crivoHelper.consultarCrivo(cpfCnpj, token, (response) => {
							if (!response.isSuccessful) {
								me.feedbackMessages.showErrorMessage(this.$scope, me.crivoHelper.remoteCallErrorMessage);
								me.crivoHelper.setPristine();
							} else {
								me.formField.data.inclusaoCaucao.fields.nomeLocatario.value = isPersonal ? response.req_nome : response.req_razaoSocial;
								me.feedbackMessages.showErrorMessage(this.$scope, '');
							}

							me.$scope.$emit('changeMainShowLoading', false);
						});
					});
				});
			});
		} else {
			me.crivoHelper.setPristine();
			me.formField.data.inclusaoCaucao.fields.nomeLocatario.value = null;
			me.feedbackMessages.showErrorMessage(this.$scope, '');
		}
	}
}

module.exports = inclusaoCaucaoController;
