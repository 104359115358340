'use strict';

class resgateGetFormService {
	/* @ngInject */
	constructor($window) {
		this.$window = $window;
		this.form = null;
	}

	isTitleAquisition() {
		// @todo WTF?
		return this.form.type.titleAquisition.value === 'true';
	}

	hasRenterApprove() {
		// @todo WTF?
		return this.form.type.hasRenterApprove.value === 'true';
	}

	isLiberarCaucao() {
		// @todo WTF?
		return this.form.liberarCaucao;
	}

	isRealtyDebits() {
		// @todo WTF?
		return this.form.type.realtyDebits.value === 'true';
	}

	isCaucionado() {
		return this.form.type.caucionado;
	}

	openFiles(form) {
		console.log('openFiles', form);

		console.log('formField.isCartaoCreditoInferior31: ', form.isCartaoCreditoInferior31);

		let vaPaginas = [];

		this.form = form;

		if (this.isCaucionado()) {
			console.log('Caucionado');

			if (this.isRealtyDebits()) {
				console.log('Quitação de Débitos');

				if (this.hasRenterApprove()) {
					console.log('Aprovação do Locatário');

					//this.$window.open(`apex/CapOnlineCaseLiberacaoPgtoDebPDFPage?id=${this.form.id}`, '_blank');
					vaPaginas.push('CapOnlineCaseLiberacaoPgtoDebPDFPage');
				} else {
					console.log('Sem Aprovação do Locatário');

					//this.$window.open(`apex/CapOnlineCaseExecucaoCaucaoPDFPage?id=${this.form.id}`, '_blank');
					//this.$window.open(`apex/CapOnlineCaseResComunicDebPDFPage?id=${this.form.id}`, '_blank');

					vaPaginas.push('CapOnlineCaseExecucaoCaucaoPDFPage');
					vaPaginas.push('CapOnlineCaseResComunicDebPDFPage');
				}
			} else {
				console.log('Sem Quitação de Débitos: 100% Locatário');

				if (this.isLiberarCaucao()) {
					console.log('Liberação da Caução');

					//this.$window.open(`apex/CapOnlineCaseLiberacaoSemPgtoPDFPage?id=${this.form.id}`, '_blank');

					vaPaginas.push('CapOnlineCaseLiberacaoSemPgtoPDFPage');
				} else {
					//this.$window.open(`apex/CapOnlineCaseLibCalcaoSolResPDFPage?id=${this.form.id}`, '_blank');
					console.log('Sem Liberação da Caução');
					vaPaginas.push('CapOnlineCaseLibCalcaoSolResPDFPage');
				}
			}
		} else {
			console.log('Descaucionado');

			//this.$window.open(`apex/CapOnlineCaseSolResPDFPage?id=${this.form.id}`, '_blank');

			vaPaginas.push('CapOnlineCaseSolResPDFPage');
		}

		if (this.isTitleAquisition()) {
			console.log('Aquisição de título');

			//this.$window.open(`apex/CapOnlineCaseResRecuperacaoCredPDFPage?id=${this.form.id}`, '_blank');

			vaPaginas.push('CapOnlineCaseResRecuperacaoCredPDFPage');
		}

		console.log('is cartao com vigencia inferior a 31 dias ?', this.form.isCartaoCreditoInferior31);

		if (this.form.isCartaoCreditoInferior31) {
			vaPaginas = [];
			vaPaginas.push('PDFResgateCCInferior31Vig');
		}

		//vaPaginas = [];

		//vaPaginas.push('CapOnlineCaseResComunicDebPDFPage'); //-------------> Carta de Declaração de Débito _ IG (FINALIZADO)
		//vaPaginas.push('CapOnlineCaseLiberacaoPgtoDebPDFPage'); //-------------> Carta Liberação de Caução com Divisão de Valores _ IG (FINALIZADO)

		//vaPaginas.push('CapOnlineCaseMudancaLocadorPDFPage'); // ----------------> Carta de Alteração de Locador _ IG	(FINALIZADO)

		//vaPaginas.push('CapOnlineCaseLibCalcaoSolResPDFPage'); //-------------> Carta Liberação de Caução 100 Locatário _ IG (FINALIZAdo)
		//vaPaginas.push('CapOnlineCaseExecucaoCaucaoPDFPage'); //------------> Carta de Execução de Caução _ IG (FINALIZADO)

		// ----------------> Carta de Alteração do imóvel Locado _ IG - CapOnlineCaseLiberacaoSemPgtoPDFPage.pdf + CapOnlineCaseCartaCaucaoPDFPage.pdf

		//vaPaginas.push('CapOnlineCaseCartaAlteracaoImovelLocado') // ----------------> Carta de Alteração do imóvel Locado _ IG

		//vaPaginas.push('CapOnlineCaseCartaCaucaoPDFPage') // ----------------> Carta Caução _ IG (FINALIZADO)

		//falta o ultimo bloco dados do subscritor
		//vaPaginas.push('CapOnlineCaseLiberacaoSemPgtoPDFPage'); //------------> Carta Liberação de Caução sem Pagamento_IG (FINALIZADO)

		//vaPaginas.push('CapOnlineCaseSolResPDFPage') //-----------> Carta de Solicitação de Resgate_IG - Descaucionado (FINALIZADO)
		//vaPaginas.push('CapOnlineCaseResRecuperacaoCredPDFPage');
		//vaPaginas.push('PDFResgateCCInferior31Vig');

		console.log('dpc===> inicio');
		console.log(vaPaginas);

		console.log('dpc===> this.form.lastFormAction: ', this.form.lastFormAction);
		const vaCriarAnexo = !!this.form.lastFormAction && this.form.lastFormAction == 'GerarFormulario' ? 'false' : 'true';

		const nomeDaVisualForceDeAnexo = 'CapOnlineCaseExibirAnexoBasePage';
		const vaId = this.form.id;
		if (vaPaginas.length > 0) {
			for (let i = 0; i < vaPaginas.length; i++) {
				const url = `apex/${nomeDaVisualForceDeAnexo}?id=${vaId}&criarAnexo=${vaCriarAnexo}&nomeDaVisualForce=${vaPaginas[i]}`;
				console.log('url do documento:', url);
				this.$window.open(url, '_blank');
			}
		}
		//this.form.lastFormAction = '';
	}
}

module.exports = resgateGetFormService;
