'use strict';

class alteracaoDadosController {
	// @ngInject
	constructor(
		$scope,
		visualForceApi,
		$window,
		$state,
		$timeout,
		alteracaoDadosFormControlService,
		alteracaoDadosApi,
		annexHelper,
		breadcrumbHelper,
		feedbackMessages,
		utilsHelper
	) {
		console.log('construtor da controller principal');
		console.log('CATENO TESTE 2: ', alteracaoDadosFormControlService);
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$timeout = $timeout;
		this.visualForceApi = visualForceApi;

		this.api = alteracaoDadosApi;
		this.formField = alteracaoDadosFormControlService;
		this.annexHelper = annexHelper;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.feedbackMessages = feedbackMessages;
		this.utilsHelper = utilsHelper;

		this.formField.information.active = true;

		this.listenSaveAlteracaoDados();
		this.listenDuplicatedCpfValidation();
	}

	listenDuplicatedCpfValidation() {
		this.$scope.$on('inclusaoCaucaoDuplicatedCpfValidation', (event, obj) => {
			//console.log('escutando duplicatedCpfValidation');
			event.stopPropagation();

			const listCpf = [];
			if (obj.information.fields.mainAccount.value !== null && !!obj.information.fields.mainAccount.value.cpf) listCpf.push(obj.information.fields.mainAccount.value.cpf);
			if (obj.data.inclusaoCaucao.fields.newLocador.value !== null && !!obj.data.inclusaoCaucao.fields.newLocador.value.cpf)
				listCpf.push(obj.data.inclusaoCaucao.fields.newLocador.value.cpf);
			if (obj.data.inclusaoCaucao.fields.administradorImovel.value !== null && !!obj.data.inclusaoCaucao.fields.administradorImovel.value.cpf)
				listCpf.push(obj.data.inclusaoCaucao.fields.administradorImovel.value.cpf);

			const duplicatedValue = this.utilsHelper.getRepeatedElements(listCpf);
			//console.log('broadcasting duplicatedCpfValidationResponse');
			this.$scope.$broadcast('inclusaoCaucaoDuplicatedCpfValidationResponse', duplicatedValue);
		});
	}

	isFormValid(page, setFieldErrors) {
		return this.formField.hasRequiredFields(page, setFieldErrors) && this.formField.isValidFields(page);
	}

	goToTheWrigthDataPage() {
		console.log('data page: ', this.formField.information.fields.alteracaoType.value);

		switch (this.formField.information.fields.alteracaoType.value) {
		case this.formField.allTypeOptions.mudancaImovel:
			this.formField.data.subCaseType = 'alteracaoImovel';
			this.$state.go('case.alteracao-dados.alteracaoImovel');
			break;
		case this.formField.allTypeOptions.mudancaLocador:
			this.formField.data.subCaseType = 'mudancaLocador';
			this.$state.go('case.alteracao-dados.mudancaLocador');
			break;
		case this.formField.allTypeOptions.correcaoDadosCliente:
			this.formField.data.subCaseType = 'alteracaoDadosCliente';
			this.$state.go('case.alteracao-dados.alteracaoDadosCliente');
			break;
		case this.formField.allTypeOptions.transferenciaTitularidade:
			this.formField.data.subCaseType = 'transferenciaTitularidade';
			this.$state.go('case.alteracao-dados.transferenciaTitularidade');
			break;
		case this.formField.allTypeOptions.inclusaoCaucao:
			this.formField.data.subCaseType = 'inclusaoCaucao';
			this.$state.go('case.alteracao-dados.inclusaoCaucao');
			break;
		default:
		}
	}

	listenSaveAlteracaoDados() {
		// console.log('listenSaveCaseInstance');

		this.$scope.$on('saveAlteracaoDados', (event) => {
			// console.log('listened saveCaseInstance');

			event.stopPropagation();

			this.saveCaseAlteracaoDados().then((response) => {
				if (response != 'error') {
					this.$scope.$broadcast('saveAlteracaoDadosDone');
				}
			});
		});
	}

	saveCaseAlteracaoDados() {
		this.feedbackMessages.showErrorMessage(this.$scope, '');
		if (this.formField.information.fields.mainAccount.value === null) {
			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);
		return this.api.saveCaseAlteracaoDados(false).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (!response.isSuccessful) {
				this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
				return 'error';
			}
			this.formField.id = response.caseId;
			this.feedbackMessages.showSuccessMessage(this.$scope, 'Solicitação salva com sucesso.');
			this.$timeout(() => {
				this.feedbackMessages.cleanMessages(this.$scope);
			}, 5000);
		});
	}

	sendCaseAlteracaoDados() {
		this.isValidUploads() && this.saveCaseBeforeUpload();
		console.log('Uploads Validos = ', this.isValidUploads());
	}

	saveCaseBeforeUpload() {
		console.log('Entrei no saveCaseBeforeUpload ');

		this.feedbackMessages.showErrorMessage(this.$scope, '');

		if (this.formField.information.fields.mainAccount.value === null) {
			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);
		this.api.saveCaseAlteracaoDados(true).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (response.isSuccessful) {
				this.formField.id = response.caseId;
				this.checkUpload();
			} else {
				this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
			}
		});
	}

	listenValidUploads() {
		// console.log('listenValidUploads');

		this.$scope.$on('validateUploads', (event) => {
			// console.log('listened validateUploads');

			event.stopPropagation();

			this.api.setFinishDisabled(!this.isValidUploads());
		});
	}

	listenSaveCaseInstance() {
		// console.log('listenSaveCaseInstance');

		this.$scope.$on('saveCaseInstance', (event) => {
			// console.log('listened saveCaseInstance');

			event.stopPropagation();

			this.saveCaseInstance().then(() => {
				this.$scope.$broadcast('caseInstanceSaved');
			});
		});
	}

	isValidUploads() {
		let valid = true;
		const annexes = this.getCurrentAnnexesToValidate();

		for (let i = annexes.length - 1; i >= 0; i--) {
			if (annexes[i].required !== false && (!annexes[i].files || annexes[i].files.length === 0)) {
				valid = false;
				break;
			}
		}
		console.log('isValidUploads valid', valid);

		return valid;
	}

	checkUpload() {
		if (this.isValidUploads()) {
			console.log('Todos os anexos validados (checkupload)', this.isValidUploads());
			const annexes = this.getCurrentAnnexes();
			const caseId = this.formField.id;
			const gedAttachmentType = 'outros';
			const caseType = this.formField.information.fields.alteracaoType.value;

			this.$scope.$emit('changeMainShowLoading', true);

			this.annexHelper
				.uploadAnnexes(annexes, caseId, gedAttachmentType, caseType)
				.then(() => {
					this.feedbackMessages.showErrorMessage(this.$scope, '');
					this.sendCompleteInstance();
				})
				.catch((err) => {
					this.updateEditCaseData();
					console.log('Erro Anexo=====', err);
					const genericErrorMessage =
						'Um ou mais arquivos não foram anexados. Os arquivos precisam ter até 8MB, não podem possuir o mesmo nome e devem ter as extensões permitidas.';
					this.feedbackMessages.showErrorMessage(this.$scope, genericErrorMessage);

					this.$scope.$emit('changeMainShowLoading', false);
				});
		} else {
			this.feedbackMessages.showErrorMessage(this.$scope, 'Por favor anexe todos os documentos.');
		}
	}

	sendCompleteInstance() {
		this.$scope.$emit('changeMainShowLoading', true);

		this.formField.status = 'Enviado';

		const form = angular.copy(this.formField);
		this.updateEditCaseData();

		this.api.sendCaseAlteracaoDados(form).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
				return;
			}

			this.$state.go('case.alteracao-dados.detail', { id: this.formField.id });
		});
	}

	getCurrentAnnexes() {
		const annexes = [
			this.formField.annex.form,
			this.formField.annex.others,
			this.formField.annex.legalRepresentative.document,
			this.formField.annex.legalRepresentative.identityRenter,
			this.formField.annex.legalRepresentative.other,
			this.formField.annex.locationContract.document,
			this.formField.annex.subscritor.identityRenter,
			//this.formField.annex.subscritor.cpf, CAP-3543
			//this.formField.annex.subscritor.adressCheck, CAP-3543
			this.formField.annex.subscritor.otherPf,
			this.formField.annex.subscritor.cnpj,
			this.formField.annex.subscritor.constitutionIdentity,
			this.formField.annex.subscritor.adminIdentity,
			this.formField.annex.subscritor.otherPj
		];

		if (this.formField.annex.transferenciaTitularidade) {
			annexes.push(this.formField.annex.transferenciaTitularidade.novoContrato);
			annexes.push(this.formField.annex.transferenciaTitularidade.novoTitularDOC);
			annexes.push(this.formField.annex.transferenciaTitularidade.outros);
		}

		for (let i = annexes.length - 1; i >= 0; i--) {
			if (!annexes[i].show) {
				angular.extend(annexes[i], this.formField.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}

	getCurrentAnnexesToValidate() {
		const annexes = [
			this.formField.annex.form,
			this.formField.annex.others,
			this.formField.annex.legalRepresentative.document,
			this.formField.annex.legalRepresentative.identityRenter,
			this.formField.annex.legalRepresentative.other,
			this.formField.annex.locationContract.document,
			this.formField.annex.subscritor.identityRenter,
			//this.formField.annex.subscritor.cpf, CAP-3543
			//this.formField.annex.subscritor.adressCheck, CAP-3543
			this.formField.annex.subscritor.otherPf,
			this.formField.annex.subscritor.cnpj,
			this.formField.annex.subscritor.constitutionIdentity,
			this.formField.annex.subscritor.adminIdentity,
			this.formField.annex.subscritor.otherPj
			//this.formField.annex.transferenciaTitularidade.formularioTitularidade,
			//this.formField.annex.transferenciaTitularidade.outros
		];

		if (this.formField.annex.transferenciaTitularidade) {
			annexes.push(this.formField.annex.transferenciaTitularidade.novoContrato);
			annexes.push(this.formField.annex.transferenciaTitularidade.novoTitularDOC);
			annexes.push(this.formField.annex.transferenciaTitularidade.outros);
		}

		for (let i = annexes.length - 1; i >= 0; i--) {
			if (!annexes[i].show) {
				angular.extend(annexes[i], this.formField.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}

	hasRequiredFields() {
		if (this.formField.information.active === true) {
			return this.formField.hasRequiredFields('information', false);
		} else if (this.formField.data.active === true) {
			return this.formField.hasRequiredFields(this.formField.data.subCaseType, false);
		}
	}

	doNext() {
		if (this.formField.information.active === true) {
			if (this.isFormValid('information', true)) {
				this.formField.activateTab('data');
				this.formField.setStepButtons('data');
				this.$window.scrollTo(0, 0);
				this.goToTheWrigthDataPage();
				//this.$state.go('case.alteracao-dados.data');
			}
		} else if (this.formField.data.active === true) {
			if (this.isFormValid(this.formField.data.subCaseType, true)) {
				this.formField.activateTab('annex');
				this.formField.setStepButtons('annex');
				this.$window.scrollTo(0, 0);
				this.$state.go('case.alteracao-dados.annex');
			}
		}
	}

	doPrevious() {
		if (this.formField.data.active === true) {
			this.formField.activateTab('information');
			this.formField.setStepButtons('information');
			this.$window.scrollTo(0, 0);
			this.$state.go('case.alteracao-dados.new');
		} else if (this.formField.annex.active === true) {
			this.formField.activateTab('data');
			this.formField.setStepButtons('data');
			this.$window.scrollTo(0, 0);
			this.$state.go(this.formField.stateMap[this.formField.data.subCaseType]);
		}
	}

	//@todo refactory
	goTo(state) {
		if (state == 'information') {
			if (this.formField.information.active === false) {
				this.formField.activateTab('information');
				this.formField.setStepButtons('information');
				this.$window.scrollTo(0, 0);
				this.$state.go('case.alteracao-dados.new');
			}
		} else if (state == 'data') {
			if (this.formField.data.active === false && this.navStepStatus('case.alteracao-dados.data') != '') {
				if (this.isFormValid('information', true)) {
					this.formField.activateTab('data');
					this.formField.setStepButtons('data');
					this.$window.scrollTo(0, 0);
					this.goToTheWrigthDataPage();
				}
			}
		} else if (state == 'annex') {
			if (this.formField.annex.active === false && this.navStepStatus('case.alteracao-dados.annex') != '') {
				if (this.isFormValid('information', true) && this.isFormValid(this.formField.data.subCaseType, true)) {
					this.formField.activateTab('annex');
					this.formField.setStepButtons('annex');
					this.$window.scrollTo(0, 0);
					this.$state.go('case.alteracao-dados.annex');
				}
			}
		}
	}

	hasNavNewCase() {
		return this.$state.current.name !== 'case.alteracaoDados.detail';
	}

	navStepCompleteness(step) {
		const warningClass = 'fa-exclamation-triangle warning';
		const successClass = 'fa-check-circle success';
		let cssClass = '';

		const form = step === 'data' ? this.formField.data.subCaseType : step;

		const isStepValid = this.isFormValid(form, false);

		if (this.formField[step].status !== 'Não Iniciado') {
			if (!isStepValid) {
				cssClass = warningClass;
			} else {
				cssClass = successClass;
			}
		}

		return cssClass;
	}

	isDetailPage() {
		return this.$state.current.name === 'case.alteracao-dados.detail';
	}

	cancelCurrentSolicitacao() {
		if (this.formField.id !== null) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.api.cancelCase(this.formField.id).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful === false) {
					this.api.setErrorMessage(response.errorMessages[0]);
				} else {
					this.$state.go('case.list');
				}
			});
		} else {
			this.$state.go('case.list');
		}
	}

	navStepStatus(step) {
		if (step == 'information') {
			if (this.formField.information.active === true) {
				return 'act';
			} else if (this.formField.information.fields.mainAccount.value !== null) {
				return 'edt';
			} else {
				return '';
			}
		} else if (step == 'data') {
			if (this.formField.data.active === true) {
				return 'act';
			} else if (this.formField.data.status == 'Em Andamento') {
				return 'edt';
			} else {
				return '';
			}
		} else if (step == 'annex') {
			if (this.formField.annex.active === true) {
				return 'act';
			} else if (this.formField.annex.status == 'Em Andamento') {
				return 'edt';
			} else {
				return '';
			}
		}
	}

	//@todo gambiarra. refatorar pra deixar dinâmico em vez de marretar os anexos
	//Esse método serve pra remover os anexos que deram erro na hora de salvar o editCaseData
	removeFilesWithError(editCaseDataFiles) {
		const files = this.getCurrentAnnexesToValidate(editCaseDataFiles);

		for (let i = files.length - 1; i >= 0; i--) {
			for (let j = files[i].length - 1; j >= 0; j--) {
				if (!files[i][j].result) {
					files[i].splice(j, 1);
				}
			}
		}
	}

	updateEditCaseData() {
		const editCaseData = JSON.parse(JSON.stringify(this.formField.extractEditCaseData())); //@todo gambiarra. Angular.copy() não funciona bem com a classe File
		this.removeFilesWithError(editCaseData.annex);
		const request = {
			caseId: this.formField.id,
			editCaseData: JSON.stringify(editCaseData)
		};

		console.log('Request updateEditCaseData', request);

		return this.visualForceApi.invoke('CapCommunityCaseControllerExt.updateEditCaseData', request);
	}
}

module.exports = alteracaoDadosController;
