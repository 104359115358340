'use strict';

class transferenciaTitularidadeController {
	// @ngInject
	constructor($scope, $window, $timeout, feedbackMessages, alteracaoDadosFormControlService, cepHelper, transferenciaTitularidadeService, autoCompleteService) {
		this.$scope = $scope;
		this.cepHelper = cepHelper;
		this.cepHelper.setPristine();
		this.$window = $window;
		this.$timeout = $timeout;
		this.feedbackMessages = feedbackMessages;
		this.formField = alteracaoDadosFormControlService;
		this.api = transferenciaTitularidadeService;
		this.autoComplete = autoCompleteService;

		this.datepickerOptions = {
			minDate: new Date(1700, 1, 3)
		};

		this.formField.data.status = 'Em Andamento';

		console.log('##### Dados da titularidade: ', this.formField);
		this.getClienteData(this.formField.information.fields.garantias.value[0].objectId);
		//this.requireAllFields();
		this.requireNovoTitular();
		console.log('Id Titulo ====>', this.formField.information.fields.garantias.value[0].objectId);
	}

	isNovoTitularPf() {
		return this.formField.data.transferenciaTitularidade.isNovoTitularPf;
	}

	isClientePf() {
		return this.formField.information.fields.mainAccount.value.isPersonAccount;
	}

	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		return transferenciaTitularidade.api.searchAccount(searchString);
	}

	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.titularSearch.value === null) {
			//Trecho CLIENTE PF
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				transferenciaTitularidade[action](item);

				return;
			}

			const account = item.originalObject;
			if (account.isPersonAccount) {
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.titularSearch.value = account;
				if (!!account.nationality) { transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.nationality.value = account.nationality.label }
				if (!!account.secondNationality) { transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.secondNationality.value = account.secondNationality.label }
				if (!!account.birthCountry) { transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.birthCountry.value = account.birthCountry.label }
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.secNac.value = account.secNac;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf = account.isPersonAccount;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.value = account.Id;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.cpf.value = account.cpf;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.nome.value = account.Name;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.sexo.value = account.Name;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.dataNascimento.value = new Date(account.personBirthdate);
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.identidade.value = account.Name;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.orgaoEmissor.value = account.Name;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.uf.value = account.Name;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.dataExpedicao.value = account.Name;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.celular.value = account.phone;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.telefoneFixo.value = account.Name;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.email.value = account.email;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.hasError = !transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.value;
			} else {
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.titularSearch.value = account;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf = account.isPersonAccount;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.value = account.Id;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.cnpj.value = account.cpf;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.nomeFantasia.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.isentoInscricaoEstadual.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.inscricaoEstadual.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.telefoneComercial.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.ramal.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.telefoneComercialDois.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.ramalDois.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.celular.value = null;
				//transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.telefoneFixo.value = null;
				transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.hasError = !transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.value;
			}

			console.log('Novo forms apos selecao de titular: ');
			console.log(transferenciaTitularidade.formField.data.transferenciaTitularidade.fields);
		}

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchAccountChanged() {
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade; //CAP-2706
		console.log('entrou no searchAccountChanged');

		//Reset novo titular pf
		transferenciaTitularidade.feedbackMessages.cleanMessages(transferenciaTitularidade.$scope);
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.titularSearch.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.cpf.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.nationality.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.secondNationality.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.birthCountry.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.profession.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.secNac.value = 'false';
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.renda.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.nome.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.sexo.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.dataNascimento.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.identidade.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.orgaoEmissor.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.uf.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.dataExpedicao.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.celular.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.telefoneFixo.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.email.value = null;

		//Reset novo endereço pf
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.cep.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.logradouro.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.numero.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.orgaoEmissor.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.complemento.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.bairro.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.cidade.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.ufCep.value = null;

		//Reset novo titular pj
		transferenciaTitularidade.feedbackMessages.cleanMessages(transferenciaTitularidade.$scope);
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.titularSearch.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.novoTitular.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.cnpj.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.profession.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.faturamento.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.nomeFantasia.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.isentoInscricaoEstadual.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.inscricaoEstadual.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.telefoneComercial.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.ramal.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.telefoneComercialDois.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.ramalDois.value = null;

		//Reset novo endereço pj
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.cep.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.logradouro.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.numero.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.complemento.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.bairro.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.cidade.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.ufCep.value = null;

		console.log('reset');
		//resetar todos os dados
		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}

	}

	searchAccountFocusOut() {

		this.$timeout(() => {
			// console.log('timeout FocusOut', this.formField.account);

			if (!!this.formField.data.transferenciaTitularidade.fields.accountSearch.value) {
				if (!this.autoComplete.hasValidInput(this.formField.data.transferenciaTitularidade.fields.accountSearch.value)) {
					console.log('Focus Out PF');
					this.autoComplete.clearInput(this.$scope, 'searchAccount');
				}
			}
		}, 100);
	}

	//@todo Horrível. copiado da proposta.
	modalHandle() {
		//this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromtransferenciaTitularidade(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromtransferenciaTitularidade(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
	}

	getTituloEndereco(id) {
		this.api.getTituloEndereco(id).then((response) => {
				this.formField.data.transferenciaTitularidade.currentData.endereco = response.data;
			})
			.catch((response) => {
				console.log('erro ao trazer dados do título');
			});
	}

	getAddressFromCEP() {

		if (this.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {

			const me = this;
			const cep = me.formField.data.transferenciaTitularidade.fields.pf.cep.value;
			me.formField.data.transferenciaTitularidade.fields.pf.cep.errorMsg = null;

			if (cep.length == 9 && cep.indexOf('-') == 5) {
				me.$scope.$emit('changeMainShowLoading', true);
				me.cepHelper.getAddressFromCEP(cep, (response) => {
					me.formField.data.transferenciaTitularidade.fields.pf.logradouro.value = response.logradouro;
					me.formField.data.transferenciaTitularidade.fields.pf.bairro.value = response.bairro;
					me.formField.data.transferenciaTitularidade.fields.pf.cidade.value = response.cidade;
					me.formField.data.transferenciaTitularidade.fields.pf.ufCep.value = response.uf;

					if (!response.isSuccessful) {
						//me.newAccountObj.address.cep.classError = inputErrorClass;
						if (me.cepHelper.remoteCallErrorLimitWasExceded()) {
							//me.newAccountObj.address.cep.classError = '';
							//me.formField.data.transferenciaTitularidade.fields.pf.cep.hasError = false;
							me.feedbackMessages.showErrorMessage(me.$scope, me.cepHelper.remoteCallErrorMessage);
						} else {
							me.formField.data.transferenciaTitularidade.fields.pf.cep.hasError = true;
							me.formField.data.transferenciaTitularidade.fields.pf.cep.errorMsg = 'Ocorreu um erro ao buscar o cep atual, verifique se o cep é válido.';
							//me.formField.data.transferenciaTitularidade.fields.pf.cep.errorMsg = response.errorMessages[0];
						}
						me.cepHelper.setPristine();
					} else {
						//me.newAccountObj.address.cep.classError = '';
						me.formField.data.transferenciaTitularidade.fields.pf.cep.hasError = false;
						me.formField.data.transferenciaTitularidade.fields.pf.logradouro.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pf.bairro.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pf.cidade.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pf.ufCep.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pf.cep.errorMsg = null;
						me.feedbackMessages.showErrorMessage(me.$scope, '');
					}

					this.$scope.$emit('changeMainShowLoading', false);
				});
			} else {
				me.cepHelper.setPristine();
				me.formField.data.transferenciaTitularidade.fields.pf.logradouro.value = null;
				me.formField.data.transferenciaTitularidade.fields.pf.bairro.value = null;
				me.formField.data.transferenciaTitularidade.fields.pf.cidade.value = null;
				me.formField.data.transferenciaTitularidade.fields.pf.ufCep.value = null;
			}

			me.requireAllFieldsPf();

		} else if (this.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {

			const me = this;
			const cep = me.formField.data.transferenciaTitularidade.fields.pj.cep.value;
			me.formField.data.transferenciaTitularidade.fields.pj.cep.errorMsg = null;

			if (cep.length == 9 && cep.indexOf('-') == 5) {
				me.$scope.$emit('changeMainShowLoading', true);
				me.cepHelper.getAddressFromCEP(cep, (response) => {
					me.formField.data.transferenciaTitularidade.fields.pj.logradouro.value = response.logradouro;
					me.formField.data.transferenciaTitularidade.fields.pj.bairro.value = response.bairro;
					me.formField.data.transferenciaTitularidade.fields.pj.cidade.value = response.cidade;
					me.formField.data.transferenciaTitularidade.fields.pj.ufCep.value = response.uf;

					if (!response.isSuccessful) {
						//me.newAccountObj.address.cep.classError = inputErrorClass;
						if (me.cepHelper.remoteCallErrorLimitWasExceded()) {
							console.log('Erro na api, limite excedido?');
							//me.newAccountObj.address.cep.classError = '';
							//me.formField.data.transferenciaTitularidade.fields.pj.cep.hasError = false;
							me.feedbackMessages.showErrorMessage(me.$scope, me.cepHelper.remoteCallErrorMessage);
						} else {
							me.formField.data.transferenciaTitularidade.fields.pj.cep.hasError = true;
							console.log('Erro na api - cep invalido? ', response.errorMessages[0]);
							//me.formField.data.transferenciaTitularidade.fields.pj.cep.errorMsg = response.errorMessages[0];
							me.formField.data.transferenciaTitularidade.fields.pj.cep.errorMsg = 'Ocorreu um erro ao buscar o cep atual, verifique se o cep é válido.';
						}
						me.cepHelper.setPristine();
					} else {
						//me.newAccountObj.address.cep.classError = '';
						me.formField.data.transferenciaTitularidade.fields.pj.cep.hasError = false;
						me.formField.data.transferenciaTitularidade.fields.pj.logradouro.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pj.bairro.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pj.cidade.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pj.ufCep.hasError = null;
						me.formField.data.transferenciaTitularidade.fields.pj.cep.errorMsg = null;
						me.feedbackMessages.showErrorMessage(me.$scope, '');
					}

					this.$scope.$emit('changeMainShowLoading', false);
				});
			} else {
				me.cepHelper.setPristine();
				me.formField.data.transferenciaTitularidade.fields.pj.logradouro.value = null;
				me.formField.data.transferenciaTitularidade.fields.pj.bairro.value = null;
				me.formField.data.transferenciaTitularidade.fields.pj.cidade.value = null;
				me.formField.data.transferenciaTitularidade.fields.pj.ufCep.value = null;
			}

			me.requireAllFieldsPj();

		}

	}

	getClienteData(id) {
		this.api
			.getClienteData(id)
			.then((response) => {
				this.formField.data.transferenciaTitularidade.currentData = response.data;

				this.getTituloEndereco(id);

				console.log('##### OBJETO DO PUG TRANSFERENCIA TITULARIDADD: ', this.formField.data.transferenciaTitularidade.currentData);
			})
			.catch((response) => {
				console.log('erro ao trazer dados do título');
			});
		//this.formField.data.transferenciaTitularidade.currentData = this.api.getClienteData(id).data;
	}

	searchResidenciaFiscal(searchString) {
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		return transferenciaTitularidade.api.searchResidenciaFiscal(searchString);
	}

	searchResidenciaFiscalSelect(item) {

		if (!item) {
			return;
		}

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;

		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.residenciaFiscal.value = item.originalObject.label;
		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}

	}

	searchResidenciaFiscalFocusOut() {
		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.formField.data.transferenciaTitularidade.fields.pf.residenciaFiscal.value);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchResidenciaFiscal');
			}
		}, 100);
	}

	searchResidenciaFiscalChanged() {

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.residenciaFiscal.value = null;
		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}

	}

	searchNoResultText() {
		const resultText = 'Nenhum país encontrado';
		return resultText;
	}

	requireProperty(field) {
		if (this.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			this.formField.requireProperty(this.formField.data.transferenciaTitularidade.fields.pf, field);
		} else {
			this.formField.requireProperty(this.formField.data.transferenciaTitularidade.fields.pj, field);
		}
	}

	requireNovoTitular() {
		this.formField.requireProperty(this.formField.data.transferenciaTitularidade.fields, 'novoTitular');
	}

	searchNacionalidade(searchString) {
		console.log('searchNacionalidade | ', searchString, ' | this : ', this);
		return this.$parent.transferenciaTitularidade.api.searchNacionalidade(searchString);
	}

	searchNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.nationality.value = null;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchNacionalidadeSelect(item) {
		console.log('searchNacionalidadeSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;

		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.nationality.value = item.originalObject.label;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchSegundaNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.secondNationality.value = null;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchSegundaNacionalidadeSelect(item) {
		console.log('searchSegundaNacionalidadeSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;

		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.secondNationality.value = item.originalObject.label;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchPaisFocusOut(context) {
		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		if (context == 'birth') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.data.transferenciaTitularidade.fields.pf.birthCountry.value);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchPais');
				}
			}, 100);
		} else if (context == 'nac') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.data.transferenciaTitularidade.fields.pf.nationality.value);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchNacionalidade');
				}
			}, 100);
		} else if (context == 'secNac') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.data.transferenciaTitularidade.fields.pf.secondNationality.value);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchSegundaNacionalidade');
				}
			}, 100);
		}
	}

	searchPaisChangedBirth() {
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		console.log('Entrou no reset birthCountry');

		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.birthCountry.value = null;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchPais(searchString) {
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;

		return transferenciaTitularidade.api.searchPais(searchString);
	}

	searchPaisSelectBirth(item) {
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;

		if (!item) {
			return;
		}

		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.birthCountry.value = item.originalObject.label;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchPaisNoResultText() {
		const resultText = 'Nenhum país encontrado.';
		return resultText;
	}

	searchNacionalidadeNoResultText() {
		const resultText = 'Nenhuma nacionalidade encontrada.';
		return resultText;
	}

	searchProfessionSelect(item) {
		// console.log('searchProfessionSelect', item);

		if (!item) {
			return;
		}

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.profession.value = item.originalObject;
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.profession.value = item.originalObject;
			transferenciaTitularidade.requireAllFieldsPj();
		}

	}

	searchProfessionChanged() {
		// console.log('searchProfessionChanged', this);
		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pf.profession.value = null;
		transferenciaTitularidade.formField.data.transferenciaTitularidade.fields.pj.profession.value = null;

		if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === true) {
			transferenciaTitularidade.requireAllFieldsPf();
		} else if (transferenciaTitularidade.formField.data.transferenciaTitularidade.isNovoTitularPf === false) {
			transferenciaTitularidade.requireAllFieldsPj();
		}
	}

	searchProfession(searchString) {
		// console.log('searchProfession', this);

		const transferenciaTitularidade = this.$parent.transferenciaTitularidade;
		const isPersonAccount = transferenciaTitularidade.isNovoTitularPf();

		return transferenciaTitularidade.api.searchProfession(searchString, isPersonAccount);
	}

	searchProfessionFocusOut() {
		// console.log('searchProfessionFocusOut', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.formField.data.transferenciaTitularidade.fields.pf.profession.value);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchProfession');
			}
		}, 100);
	}

	requireAllFieldsPf() {
		this.requireNovoTitular();
		this.requireProperty('nationality');
		if (this.formField.data.transferenciaTitularidade.fields.pf.secNac.value == 'true') {
			this.requireProperty('secondNationality');
		}
		this.requireProperty('birthCountry');
		this.requireProperty('profession');
		this.requireProperty('renda');
		this.requireProperty('cpf');
		this.requireProperty('nome');
		this.requireProperty('sexo');
		this.requireProperty('dataNascimento');
		this.requireProperty('identidade');
		this.requireProperty('orgaoEmissor');
		this.requireProperty('uf');
		this.requireProperty('dataExpedicao');
		//this.requireProperty('celular');
		//this.requireProperty('telefoneFixo');
		this.requireProperty('email');
		this.requireProperty('cep');
		this.requireProperty('logradouro');
		this.requireProperty('numero');
		//this.requireProperty('complemento');
		this.requireProperty('bairro');
		this.requireProperty('cidade');
		this.requireProperty('ufCep');
	}

	requireAllFieldsPj() {
		this.requireNovoTitular();
		this.requireProperty('faturamento');
		this.requireProperty('profession');
		this.requireProperty('cnpj');
		//this.requireProperty('nomeFantasia');
		//this.requireProperty('isentoInscricaoEstadual');
		//this.requireProperty('inscricaoEstadual');
		this.requireProperty('telefoneComercial');
		//this.requireProperty('celular');
		//this.requireProperty('telefoneFixo');
		this.requireProperty('cep');
		this.requireProperty('logradouro');
		this.requireProperty('numero');
		//this.requireProperty('complemento');
		this.requireProperty('bairro');
		this.requireProperty('cidade');
		this.requireProperty('ufCep');
	}

	validateField(field) {
		if (this.formField.data.transferenciaTitularidade.fields.pf[field]) {
			this.formField.validateField(this.formField.data.transferenciaTitularidade.fields.pf, field);
		} else if (this.formField.data.transferenciaTitularidade.fields.pj[field]) {
			this.formField.validateField(this.formField.data.transferenciaTitularidade.fields.pj, field);
		}
	}

	nullOrEmpty(field) {
		if (this.formField.data.transferenciaTitularidade.fields.pf[field]) {
			return this.formField.nullOrEmpty(this.formField.data.transferenciaTitularidade.fields.pf, field);
		} else if (this.formField.data.transferenciaTitularidade.fields.pj[field]) {
			return this.formField.nullOrEmpty(this.formField.data.transferenciaTitularidade.fields.pj, field);
		} else {
			console.log('erro no null or empty');
		}
	}
	//@todo ver se pode melhorar. copiado da account

	closeDatepickerOnTabPress(keyEvent) {
		if (keyEvent.which === 9) {
			$('.uib-datepicker-popup').remove();
		}
	}

	focusBirthDate() {
		$('#birthDateId').focus();
	}

	focusExpDate() {
		$('#expeditionDateId').focus();
	}
}

module.exports = transferenciaTitularidadeController;
