'use strict';

class resgateBankController {
	// @ngInject
	constructor($scope, resgateFormService, propostaNewApi, resgateBankService, autoCompleteService, dadosBancariosHelper, feedbackMessages, accountUtilService, crivoHelper) {
		this.$scope = $scope;
		this.resgateFormService = resgateFormService;
		this.propostaNewApi = propostaNewApi;
		this.autoComplete = autoCompleteService;
		this.messages = feedbackMessages;
		this.resgateBankService = resgateBankService;
		this.dadosBancariosHelper = dadosBancariosHelper;
		this.crivoHelper = crivoHelper;
		this.accountUtilService = accountUtilService;

		this.bankList = resgateBankService.getBankList();
		this.bankListDestino = resgateBankService.getBankList();

		console.log('bankListDestino: ', this.bankListDestino);

		this.getFormFieldStatus();
		this.listenApplyErrorBank();
		this.formField.bank.bankData.show = false;
		this.formField.bank.bankBills.show = false;
		this.checkStatusOnLoad();

		// Icatu - Retirar opção de recebimento em conta poupança
		if (
			this.formField.bank.bankData.accountType.value === null ||
			this.formField.bank.bankData.accountType.value === undefined ||
			this.formField.bank.bankData.accountType.value === ''
		) {
			this.formField.bank.bankData.accountType.value = 'Conta Corrente';
		}

		if (
			this.formField.bank.bankBills.accountType.value === null ||
			this.formField.bank.bankBills.accountType.value === undefined ||
			this.formField.bank.bankBills.accountType.value === ''
		) {
			this.formField.bank.bankBills.accountType.value = 'Conta Corrente';
		}

		// Icatu - Não permitir resgate em conta de terceiros
		this.formField.bank.bankData.customerOwner.value = 'true';
		this.changeCustomerOwner();
	}

	checkStatusOnLoad() {
		if (
			this.formField.decisionType === '100-locatario-resgate-final' ||
			this.formField.decisionType === '100-locatario-resgate-antecipado' ||
			this.formField.decisionType === 'cancelamento-100-locatario' ||
			this.formField.decisionType === 'descaucionado-100-locatario' ||
			this.formField.decisionType === 'divisao-valores-recuperacao-credito-resgate-antecipado' ||
			this.formField.decisionType === 'divisao-valores-recuperacao-credito-resgate-final' ||
			this.formField.decisionType === 'divisao-de-valores-resgate-antecipado' ||
			this.formField.decisionType === 'divisao-de-valores-resgate-final' ||
			this.formField.decisionType === 'cancelamento-divisao-valores' ||
			this.formField.decisionType === 'cancelamento-divisao-valores-recuperacao-credito' ||
			this.formField.decisionType === 'recuperacao-credito-resgate-antecipado' ||
			this.formField.decisionType === 'recuperacao-credito-resgate-final' ||
			this.formField.decisionType === 'cancelamento-recuperacao-credito' ||
			this.formField.decisionType === 'descaucionado-recuperacao-credito' ||
			this.formField.decisionType === 'cancelamento-recuperacao-credito'
		) {
			this.formField.bank.bankData.show = true;
			this.formField.bank.bankData.customerOwner.show = true;
			this.formField.bank.bankData.accountType.show = true;
			this.formField.bank.bankData.bank.show = true;
			this.formField.bank.bankData.agency.show = true;
			this.formField.bank.bankData.agencyDV.show = true;
			this.formField.bank.bankData.accountNumber.show = true;
			this.formField.bank.bankData.accountNumberDV.show = true;
			this.formField.bank.bankData.accountOwnerName.show = true;
			this.formField.bank.bankData.cpfcnpj.show = true;
		}

		if (
			this.formField.decisionType === 'divisao-valores-recuperacao-credito-resgate-antecipado' ||
			this.formField.decisionType === 'divisao-valores-recuperacao-credito-resgate-final' ||
			this.formField.decisionType === 'divisao-de-valores-resgate-antecipado' ||
			this.formField.decisionType === 'divisao-de-valores-resgate-final' ||
			this.formField.decisionType === 'cancelamento-divisao-valores' ||
			this.formField.decisionType === 'cancelamento-divisao-valores-recuperacao-credito' ||
			this.formField.decisionType === 'cancelamento-execucao-caucao' ||
			this.formField.decisionType === 'execucao-caucao-resgate-antecipado' ||
			this.formField.decisionType === 'execucao-caucao-resgate-final'
		) {
			this.formField.bank.bankBills.show = true;
			this.formField.bank.bankBills.accountType.show = true;
			this.formField.bank.bankBills.bank.show = true;
			this.formField.bank.bankBills.agency.show = true;
			this.formField.bank.bankBills.agencyDV.show = true;
			this.formField.bank.bankBills.accountNumber.show = true;
			this.formField.bank.bankBills.accountNumberDV.show = true;
			this.formField.bank.bankBills.accountOwnerName.show = true;
			this.formField.bank.bankBills.cpfcnpj.show = true;
			this.formField.bank.bankBills.professionalActivity.show = true;
			this.formField.bank.bankBills.income.show = true;
		}
	}

	getFormFieldStatus() {
		this.formField = this.resgateFormService.getFormField();
	}

	changeField() {
		this.resgateFormService.setFormField(this.formField);
	}

	changeCustomerOwner() {
		if (this.formField.bank.bankData.customerOwner.value === 'false') {
			this.formField.bank.bankData.professionalActivity.show = true;
			this.formField.bank.bankData.income.show = true;
			this.formField.bank.bankData.accountOwnerName.value = null;
			this.formField.bank.bankData.cpfcnpj.value = null;
		} else {
			this.formField.bank.bankData.accountOwnerName.value = this.formField.data.customerData.name.value;
			this.formField.bank.bankData.cpfcnpj.value = this.formField.data.customerData.cpfcnpj.value;
			this.formField.bank.bankData.professionalActivity.show = false;
			this.formField.bank.bankData.income.show = false;
		}
	}

	searchProfessionBankBills(searchString) {
		console.log('searchProfession');
		const resgateBank = this.$parent.resgateBank;
		const cpfcnpj = resgateBank.formField.bank.bankBills.cpfcnpj.value;
		const isPersonAccount = resgateBank.accountUtilService.isValidCpf(cpfcnpj);

		return resgateBank.propostaNewApi.searchProfession(searchString, isPersonAccount);
	}

	searchProfessionSelect(item) {
		console.log('searchProfessionSelect');
		const resgateBank = this.$parent.resgateBank;
		if (resgateBank.formField.bank.bankBills.professionalActivity.value === null) {
			if (!item) {
				return;
			}
			resgateBank.formField.bank.bankBills.professionalActivity.value = item.originalObject;
			resgateBank.formField.bank.bankBills.professionalActivity.hasError = false;
		}
	}

	searchProfessionFocusOut() {
		console.log('searchProfessionFocusOut');
		const hasValidInput = this.autoComplete.hasValidInput(this.formField.bank.bankBills.professionalActivity.value);
		if (!hasValidInput) {
			this.autoComplete.clearInput(this.$scope, 'searchProfessionBills');
		}
	}

	searchProfessionChanged() {
		console.log('searchProfessionChanged');
		const resgateBank = this.$parent.resgateBank;

		resgateBank.autoComplete.resetSelected(resgateBank.formField.bank.bankBills.professionalActivity, 'value');
	}

	searchProfessionBankData(searchString) {
		console.log('searchProfession');
		const resgateBank = this.$parent.resgateBank;
		const cpfcnpj = resgateBank.formField.bank.bankData.cpfcnpj.value;
		const isPersonAccount = resgateBank.accountUtilService.isValidCpf(cpfcnpj);

		// Ler o campo CPF CNPJ, pelo tamanho dos caracteres...
		// Identificar se é um CPF... entao é true
		// Se verificar que é CNPJ falso.
		//debugger;

		return resgateBank.propostaNewApi.searchProfession(searchString, isPersonAccount);
	}

	searchProfessionSelectBankData(item) {
		console.log('searchProfessionSelect');
		const resgateBank = this.$parent.resgateBank;
		if (resgateBank.formField.bank.bankData.professionalActivity.value === null) {
			if (!item) {
				return;
			}
			resgateBank.formField.bank.bankData.professionalActivity.value = item.originalObject;
			resgateBank.formField.bank.bankData.professionalActivity.hasError = false;
		}
	}

	searchProfessionFocusOutBankData() {
		console.log('searchProfessionFocusOut');
		const hasValidInput = this.autoComplete.hasValidInput(this.formField.bank.bankData.professionalActivity.value);
		if (!hasValidInput) {
			this.autoComplete.clearInput(this.$scope, 'searchProfessionData');
		}
	}

	searchProfessionChangedBankData() {
		console.log('searchProfessionChanged');
		const resgateBank = this.$parent.resgateBank;

		resgateBank.autoComplete.resetSelected(resgateBank.formField.bank.bankData.professionalActivity, 'value');
	}

	listenApplyErrorBank() {
		this.$scope.$on('applyErrorBankFields', (event, response, tipo) => {
			event.stopPropagation && event.stopPropagation();
			this.applyErrorBankFields(response, tipo);
		});
	}

	applyErrorBankFields(response, tipo) {
		let areaForm = null;
		if (tipo === 'dados-bancarios') areaForm = this.formField.bank.bankData;
		else if (tipo === 'pagamento-debitos') areaForm = this.formField.bank.bankBills;
		if (!!areaForm) {
			this.setErrorBankField(areaForm, false);
			if (!response.isSuccessful) {
				// Erro ao chamar o serviço, se o numero maximo de tentativas for alcançado libera
				if (this.dadosBancariosHelper.remoteCallErrorLimitWasExceded()) {
					this.messages.showErrorMessage(this.$scope, '');
				} else {
					this.setErrorBankField(areaForm, true);
					this.messages.showErrorMessage(this.$scope, this.dadosBancariosHelper.remoteCallErrorMessage);
				}
			} else if (response.isValid) {
				this.messages.showErrorMessage(this.$scope, '');
			} else {
				console.log('people service: conta bancaria invalida');
				this.setErrorBankField(areaForm, true);
			}
			this.dadosBancariosHelper.setPristine();
		}
	}

	setErrorBankField(areaForm, value) {
		areaForm.agency.hasError = value;
		areaForm.agency.hasAgencyPeopleError = value;
		areaForm.agencyDV.hasError = value;
		areaForm.agencyDV.hasAgencyDVPeopleError = value;
		areaForm.accountNumber.hasError = value;
		areaForm.accountNumber.hasAccountNumberPeopleError = value;
		areaForm.accountNumberDV.hasError = value;
		areaForm.accountNumberDV.hasAccountNumberDVPeopleError = value;
	}

	delegateCrivo(tipo) {
		let areaForm = null;
		if (tipo === 'dados-bancarios') areaForm = this.formField.bank.bankData;
		else if (tipo === 'pagamento-debitos') areaForm = this.formField.bank.bankBills;
		if (!!areaForm) {
			this.consultarCrivo(areaForm);
		}
	}

	consultarCrivo(areaForm) {
		return;
		/*
		const me = this;
		const cpfCnpj = areaForm.cpfcnpj.value;

		const isPersonal = this.accountUtilService.isValidCpf(cpfCnpj);
		const isCompany = this.accountUtilService.isValidCnpj(cpfCnpj);

		if (isPersonal || isCompany) {
			me.$scope.$emit('changeMainShowLoading', true);

			me.crivoHelper.getRecaptchaLoginCall().then((recaptchaLogin) => {
				console.log('response recaptchaLogin: ', recaptchaLogin);
				grecaptcha.ready(function () {
					grecaptcha.execute(recaptchaLogin, { action: 'submit' }).then(function (token) {
						console.log('---- 2. GTOKEN: ', token);

						me.crivoHelper.consultarCrivo(cpfCnpj, token, (response) => {
							if (!response.isSuccessful) {
								me.messages.showErrorMessage(me.$scope, me.crivoHelper.remoteCallErrorMessage);
								me.crivoHelper.setPristine();
							} else {
								areaForm.accountOwnerName.value = isPersonal ? response.req_nome : response.req_razaoSocial;
								me.messages.showErrorMessage(me.$scope, '');
							}

							me.$scope.$emit('changeMainShowLoading', false);
						});
					});
				});
			});
		} else {
			me.crivoHelper.setPristine();
			areaForm.accountOwnerName.value = null;
			me.messages.showErrorMessage(this.$scope, '');
		}
			*/
	}

	getParametersForValidBank(tipo) {
		let areaForm = null;
		const parameter = {};
		if (tipo === 'dados-bancarios') areaForm = this.formField.bank.bankData;
		else if (tipo === 'pagamento-debitos') areaForm = this.formField.bank.bankBills;
		if (!!areaForm) {
			parameter.tipoConta = areaForm.accountType.value;
			parameter.banco = areaForm.bank.value;
			parameter.agencia = areaForm.agency.value;
			parameter.agenciaDV = areaForm.agencyDV.value;
			parameter.conta = areaForm.accountNumber.value;
			parameter.contaDV = areaForm.accountNumberDV.value;
			parameter.cpfCnpj = areaForm.cpfcnpj.value;
		}
		return parameter;
	}

	hasField(field) {
		return typeof field !== 'undefined' && field != null && field != '';
	}

	callForValidDataBank(tipo) {
		if (this.formField.isCartaoCreditoInferior31) return true;

		if (angular.equals(this.parametrosValidados, this.getParametersForValidBank(tipo))) return;
		this.parametrosValidados = this.getParametersForValidBank(tipo);
		const { tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV } = this.parametrosValidados;
		const isValid = this.hasField(tipoConta) && this.hasField(cpfCnpj) && this.hasField(banco) && this.hasField(agencia) && this.hasField(conta) && this.hasField(contaDV);
		if (!isValid) return;

		this.$scope.$emit('changeMainShowLoading', true);
		this.dadosBancariosHelper.consultaDadosBancariosSeValidosPromisse(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV).then((response) => {
			this.applyErrorBankFields(response, tipo);
			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	changeBankOptions() {
		console.log('entrou em changeBankOptions()');
		const me = this;

		const tipoConta = me.formField.bank.bankData.accountType.value;

		console.log('--> tipoConta: ', tipoConta);

		me.bankList = me.resgateBankService.getBankList();

		/*
		if (tipoConta === 'Conta Corrente') {
			me.bankList = me.resgateBankService.getBankList();
		} else {
			me.bankList = me.resgateBankService.getBankListContaPoupanca();
		}
		*/
	}

	changeBankOptionsDestino() {
		console.log('entrou em changeBankOptionsDestino()');
		const me = this;

		const tipoConta = me.formField.bank.bankBills.accountType.value;

		console.log('--> tipoConta: ', tipoConta);

		me.bankListDestino = me.resgateBankService.getBankList();
	}
}

module.exports = resgateBankController;
