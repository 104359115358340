'use strict';

class resgateDataController {
	// @ngInject
	constructor(resgateFormService, autoCompleteService, $scope, sobjectQueryService, resgateService, resgateDataService, $state, propostaNewApi, crivoHelper, accountUtilService) {
		this.resgateFormService = resgateFormService;
		this.sobjectQueryService = sobjectQueryService;
		this.resgateService = resgateService;
		this.resgateDataService = resgateDataService;
		this.autoComplete = autoCompleteService;
		this.propostaNewApi = propostaNewApi;
		this.crivoHelper = crivoHelper;
		this.accountUtilService = accountUtilService;

		this.$scope = $scope;
		this.$state = $state;
		this.getFormFieldStatus();
		this.formField.data.valNominalResgate = 0;
		this.formField.data.propostaValue.show = false;
		if (this.resgateService.getIsEdit() === true) {
			if (this.formField.data.realtyInformation.contractDate.value !== null) {
				$('#contratoLocacao').val(this.formField.data.realtyInformation.contractDate.value);
			}
		} else {
			if (this.formField.data.realtyInformation.contractDate.value !== null) {
				$('#contratoLocacao').val(this.formField.data.realtyInformation.contractDate.value);
			}
		}
		if (this.formField.information.mainAccount.value === null) {
			this.$state.go('resgate.new');
		}
		this.checkStatusOnLoad();
		this.loadRealtyData();
		if (this.formField.data.resgateValue.value !== null) {
			if (this.formField.type.debitValue.value !== null) {
				this.formField.data.debitValue.value = this.formField.type.debitValue.value;
				if (this.formField.type.proposta.value !== null) {
					this.formField.data.propostaValue.value = this.formField.type.proposta.value.value;
					this.formField.data.propostaValue.show = true;
				}
			} else if (this.formField.type.proposta.value !== null) {
				this.formField.data.propostaValue.value = this.formField.type.proposta.value.value;
				this.formField.data.propostaValue.show = true;
			}
		}

		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
			if (this.formField.information.garantias.value[i] != null) {
				if (this.formField.information.garantias.value[i].selected) {
					this.formField.data.valNominalResgate += this.formField.information.garantias.value[i].value;
				}
			}
		}

		if (this.formField.isHaveProblemBank) {
			this.formField.data.realtyInformation.contractDate.disabled = true;
		}

		//@todo gambiarra por causa do jquery
		const that = this;

		$('.input-datepicker')
			.datepicker()
			.on('changeDate', function (ev) {
				$(this).datepicker('hide');

				that.hasRequiredContractDate();
				that.$scope.$apply();
			});
	}

	//@todo gambiarra por causa do jquery
	hasRequiredContractDate() {
		// console.log('hasRequiredContractDate()');

		this.$scope.$emit('resgateHasRequired', 'ContractDate');
	}

	getFormFieldStatus() {
		this.formField = this.resgateFormService.getFormField();
	}

	checkStatusOnLoad() {
		let valorResgate = null;
		let tituloId = null;

		if (this.formField.information.garantias.value !== null) {
			if (this.formField.information.garantias.value.length > 0) {
				tituloId = this.formField.information.garantias.value[0].objectId;
				valorResgate = this.formField.information.garantias.value[0].valorResgate;
			}
		}

		// vigencia = false quando <= 30
		if (this.formField.type.caucionado === false && this.formField.type.vigencia === false) {
			if (this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value === 'false') {
				this.formField.decisionType = 'cancelamento-100-locatario';
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.debitValue.value = null;
				this.formField.data.valueRemain.show = false;
				this.formField.data.valueRemain.value = null;
			} else if (this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value === 'true') {
				this.formField.decisionType = 'cancelamento-recuperacao-credito';
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.propostaValue.show = true;
				this.formField.data.propostaValue.value =
					typeof this.formField.type.proposta.value.value != 'undefined' && this.formField.type.proposta.value.value !== null ? this.formField.type.proposta.value.value : 0;
				this.formField.data.valueRemain.show = true;
				this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.propostaValue.value;
			}
		} else if (this.formField.type.caucionado === false && this.formField.type.vigencia === true) {
			if (this.formField.type.resgateType.show === true && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value === 'true') {
				this.formField.decisionType = 'descaucionado-recuperacao-credito';
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.propostaValue.show = true;
				this.formField.data.propostaValue.value =
					typeof this.formField.type.proposta.value.value != 'undefined' && this.formField.type.proposta.value.value !== null ? this.formField.type.proposta.value.value : 0;
				this.formField.data.valueRemain.show = true;
				this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.propostaValue.value;
			} else if (this.formField.type.resgateType.show === true && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value === 'false') {
				this.formField.decisionType = 'descaucionado-100-locatario';
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.debitValue.value = null;
				this.formField.data.propostaValue.show = false;
				this.formField.data.propostaValue.value = null;
				this.formField.data.valueRemain.show = false;
				this.formField.data.valueRemain.value = null;
			}
		} else if (this.formField.type.caucionado === true && this.formField.type.vigencia === false) {
			if (
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'false' &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'true'
			) {
				this.formField.decisionType = 'cancelamento-recuperacao-credito';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.propostaValue.show = true;
				this.formField.data.propostaValue.value =
					typeof this.formField.type.proposta.value.value != 'undefined' && this.formField.type.proposta.value.value !== null ? this.formField.type.proposta.value.value : 0;
				this.formField.data.valueRemain.show = true;
				this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.propostaValue.value;
			} else if (
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'true' &&
				this.formField.type.hasRenterApprove.show === true &&
				this.formField.type.hasRenterApprove.value === 'false'
			) {
				this.formField.decisionType = 'cancelamento-execucao-caucao';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.debitValue.value = null;
				this.formField.data.debitValue.disabled = true;
				this.formField.data.propostaValue.show = false;
				this.formField.data.propostaValue.value = null;
				this.formField.data.valueRemain.show = false;
				this.formField.data.valueRemain.value = null;
				this.formField.data.valueRemain.disabled = true;
			} else if (
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'true' &&
				this.formField.type.hasRenterApprove.value === 'true' &&
				this.formField.type.hasRenterApprove.show === true &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'false'
			) {
				this.formField.decisionType = 'cancelamento-divisao-valores';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = true;
				this.formField.data.debitValue.value = this.formField.type.debitValue.value;
				this.formField.data.propostaValue.show = false;
				this.formField.data.propostaValue.value = null;
				// console.log('debitValue disabled');
				this.formField.data.debitValue.disabled = false;
				this.formField.data.valueRemain.show = true;
				if (this.formField.data.resgateValue.value < this.formField.data.debitValue.value) {
					this.formField.data.valueRemain.hasError = true;
					this.formField.data.valueRemain.value = 0;
				} else {
					this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.debitValue.value;
				}
				this.formField.data.valueRemain.disabled = true;
			} else if (
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'false' &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'false'
			) {
				this.formField.decisionType = 'cancelamento-100-locatario';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.debitValue.value = 0;
				this.formField.data.debitValue.disabled = true;
				this.formField.data.propostaValue.show = false;
				this.formField.data.propostaValue.value = null;
				this.formField.data.valueRemain.show = false;
				this.formField.data.valueRemain.value = 0;
				this.formField.data.valueRemain.disabled = true;
			} else if (
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'true' &&
				this.formField.type.hasRenterApprove.value === 'true' &&
				this.formField.type.hasRenterApprove.show === true &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'true'
			) {
				this.formField.decisionType = 'cancelamento-divisao-valores-recuperacao-credito';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.propostaValue.show = true;
				this.formField.data.propostaValue.value =
					typeof this.formField.type.proposta.value.value != 'undefined' && this.formField.type.proposta.value.value !== null ? this.formField.type.proposta.value.value : 0;
				this.formField.data.debitValue.show = true;
				this.formField.data.debitValue.value = this.formField.type.debitValue.value;
				// console.log('debitValue disabled');
				this.formField.data.debitValue.disabled = false;
				this.formField.data.valueRemain.show = true;
				if (this.formField.data.resgateValue.value < this.formField.data.debitValue.value + this.formField.data.propostaValue.value) {
					this.formField.data.valueRemain.hasError = true;
					this.formField.data.valueRemain.value = 0;
				} else {
					this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.debitValue.value - this.formField.data.propostaValue.value;
				}
				this.formField.data.valueRemain.disabled = true;
			}
		} else if (this.formField.type.caucionado === true && this.formField.type.vigencia === true) {
			if (
				this.formField.type.resgateType.show === true &&
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'true' &&
				this.formField.type.hasRenterApprove.show === true &&
				this.formField.type.hasRenterApprove.value === 'false'
			) {
				this.formField.decisionType = this.formField.type.resgateType.value === 'Resgate antecipado' ? 'execucao-caucao-resgate-antecipado' : 'execucao-caucao-resgate-final';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = false;
				this.formField.data.debitValue.value = null;
				this.formField.data.debitValue.disabled = true;
				this.formField.data.propostaValue.show = false;
				this.formField.data.propostaValue.value = null;
				this.formField.data.valueRemain.show = false;
				this.formField.data.valueRemain.value = null;
				this.formField.data.valueRemain.disabled = true;
			} else if (
				this.formField.type.resgateType.show === true &&
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'true' &&
				this.formField.type.hasRenterApprove.show === true &&
				this.formField.type.hasRenterApprove.value === 'true' &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'true'
			) {
				this.formField.decisionType =
					this.formField.type.resgateType.value === 'Resgate antecipado' ?
					'divisao-valores-recuperacao-credito-resgate-antecipado' :
					'divisao-valores-recuperacao-credito-resgate-final';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.propostaValue.show = true;
				this.formField.data.propostaValue.value =
					typeof this.formField.type.proposta.value.value != 'undefined' && this.formField.type.proposta.value.value !== null ? this.formField.type.proposta.value.value : 0;
				this.formField.data.debitValue.show = true;
				this.formField.data.debitValue.value = this.formField.type.debitValue.value;
				// console.log('debitValue disabled');
				this.formField.data.debitValue.disabled = false;
				this.formField.data.valueRemain.show = true;
				if (this.formField.data.resgateValue.value < this.formField.data.debitValue.value + this.formField.data.propostaValue.value) {
					this.formField.data.valueRemain.hasError = true;
					this.formField.data.valueRemain.value = 0;
				} else {
					this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.debitValue.value - this.formField.data.propostaValue.value;
				}
				this.formField.data.valueRemain.disabled = true;
			} else if (
				this.formField.type.resgateType.show === true &&
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'true' &&
				this.formField.type.hasRenterApprove.show === true &&
				this.formField.type.hasRenterApprove.value === 'true' &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'false'
			) {
				this.formField.decisionType = this.formField.type.resgateType.value === 'Resgate antecipado' ? 'divisao-de-valores-resgate-antecipado' : 'divisao-de-valores-resgate-final';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.debitValue.show = true;
				this.formField.data.debitValue.value = this.formField.type.debitValue.value;
				// @todo WTF!?!?!?
				// true true 'true' true 'true' true 'false'
				/*
					this.formField.type.resgateType.show === true && 
					this.formField.type.realtyDebits.show === true && 
					this.formField.type.realtyDebits.value === 'true' && 
					this.formField.type.hasRenterApprove.show === true && 
					this.formField.type.hasRenterApprove.value === 'true' && 
					this.formField.type.titleAquisition.show === true && 
					this.formField.type.titleAquisition.value === 'false'
				*/
				/*
				// console.log(
						'debitValue disabled',
						this.formField.type.resgateType.show,
						this.formField.type.realtyDebits.show,
						this.formField.type.realtyDebits.value,
						this.formField.type.hasRenterApprove.show,
						this.formField.type.hasRenterApprove.value,
						this.formField.type.titleAquisition.show,
						this.formField.type.titleAquisition.value
					);
				*/
				this.formField.data.propostaValue.show = false;
				this.formField.data.propostaValue.value = null;
				this.formField.data.debitValue.disabled = true;
				this.formField.data.valueRemain.show = true;
				if (this.formField.data.resgateValue.value < this.formField.data.debitValue.value) {
					this.formField.data.valueRemain.hasError = true;
					this.formField.data.valueRemain.value = 0;
				} else {
					this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.debitValue.value;
				}
				this.formField.data.valueRemain.disabled = true;
			} else if (
				this.formField.type.resgateType.show === true &&
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'false' &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'true'
			) {
				this.formField.decisionType =
					this.formField.type.resgateType.value === 'Resgate antecipado' ? 'recuperacao-credito-resgate-antecipado' : 'recuperacao-credito-resgate-final';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.propostaValue.show = true;
				this.formField.data.propostaValue.value =
					typeof this.formField.type.proposta.value.value != 'undefined' && this.formField.type.proposta.value.value !== null ? this.formField.type.proposta.value.value : 0;
				this.formField.data.debitValue.show = false;
				this.formField.data.debitValue.value = null;
				// console.log('debitValue disabled');
				this.formField.data.debitValue.disabled = false;
				this.formField.data.valueRemain.show = true;
				this.formField.data.valueRemain.value = this.formField.data.resgateValue.value - this.formField.data.propostaValue.value;
				this.formField.data.valueRemain.disabled = true;
			} else if (
				this.formField.type.resgateType.show === true &&
				this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'false' &&
				this.formField.type.titleAquisition.show === true &&
				this.formField.type.titleAquisition.value === 'false'
			) {
				this.formField.decisionType = this.formField.type.resgateType.value === 'Resgate antecipado' ? '100-locatario-resgate-antecipado' : '100-locatario-resgate-final';
				this.formField.data.resgateValue.value = valorResgate;
				this.formField.data.resgateValue.show = true;
				this.formField.data.resgateValue.disabled = true;
				this.formField.data.propostaValue.show = false;
				this.formField.data.propostaValue.value = null;
				this.formField.data.debitValue.show = false;
				this.formField.data.debitValue.value = 0;
				this.formField.data.debitValue.disabled = true;
				this.formField.data.valueRemain.show = false;
				this.formField.data.valueRemain.value = 0;
				this.formField.data.valueRemain.disabled = true;
			}
		}
	}

	isFirstAccess() {
		// Identifica se é o primeiro acesso na aba, pelos valores preenchidos
		return (
			!this.formField.data.realtyInformation.contractDate.value &&
			!this.formField.data.customerData.observations &&
			!this.formField.data.customerData.email.value &&
			!this.formField.data.customerData.income.value &&
			!this.formField.data.customerData.professionalActivity.value
		);
	}

	loadRealtyData() {
		/* Só permite a execução se for o primeiro acesso */
		if (!this.isFirstAccess()) return false;

		this.$scope.$emit('changeMainShowLoading', true);

		let tituloId = '';
		let valorResgate = '';
		if (this.formField.information.garantias.value !== null) {
			if (this.formField.information.garantias.value.length > 0) {
				tituloId = this.formField.information.garantias.value[0].objectId;
				valorResgate = this.formField.information.garantias.value[0].valorResgate;
			}
		}
		console.log('tituloId=', tituloId);
		console.log('valorResgate=', valorResgate);

		//TODO: Obtendo o valor do resgate
		//--------------------------------------------------------------------
		console.log('----> obtendo o valor do resgate');

		this.formField.data.resgateValue.value = valorResgate;

		//this.resgateDataService.loadDataFromPropostaId(this.formField.information.proposta.value.objectId).then((response) => {
		this.resgateDataService.loadDataFromTituloId(tituloId).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			// console.log(response);
			if (response.isSuccessful === true) {
				this.formField.data.realtyInformation.cep = response.proposta.cep;
				this.formField.data.realtyInformation.address = response.proposta.address;
				this.formField.data.realtyInformation.addressNumber = response.proposta.addressNumber;
				this.formField.data.realtyInformation.addressComplement = response.proposta.addressComplement;
				this.formField.data.realtyInformation.neighborhood = response.proposta.neighborhood;
				this.formField.data.realtyInformation.city = response.proposta.city;
				this.formField.data.realtyInformation.uf = response.proposta.uf;
				this.formField.data.realtyInformation.renterName = response.proposta.renterName;
				this.formField.data.realtyInformation.contractDate.value = response.proposta.contractDate;
				if (
					this.formField.decisionType === 'cancelamento-100-locatario' ||
					this.formField.decisionType === 'cancelamento-recuperacao-credito' ||
					this.formField.decisionType === 'cancelamento-divisao-valores' ||
					this.formField.decisionType === 'cancelamento-100-locatario' ||
					this.formField.decisionType === 'cancelamento-divisao-valores-recuperacao-credito' ||
					this.formField.decisionType === 'cancelamento-recuperacao-credito' ||
					this.formField.decisionType === 'descaucionado-recuperacao-credito' ||
					this.formField.decisionType === 'descaucionado-100-locatario' ||
					this.formField.decisionType === 'divisao-valores-recuperacao-credito-resgate-antecipado' ||
					this.formField.decisionType === 'divisao-valores-recuperacao-credito-resgate-final' ||
					this.formField.decisionType === 'divisao-de-valores-resgate-antecipado' ||
					this.formField.decisionType === 'divisao-de-valores-resgate-final' ||
					this.formField.decisionType === 'recuperacao-credito-resgate-antecipado' ||
					this.formField.decisionType === 'recuperacao-credito-resgate-final' ||
					this.formField.decisionType === '100-locatario-resgate-antecipado' ||
					this.formField.decisionType === '100-locatario-resgate-final'
				) {
					this.formField.data.customerData.name.value = response.customer.name;
					this.formField.data.customerData.cpfcnpj.value = response.customer.cpfcnpj;
					this.formField.data.customerData.inscription = response.customer.inscription;
					this.formField.data.customerData.emitter = response.customer.emitter;
					this.formField.data.customerData.professionalActivity.value = null; //(typeof response.customer.profissao === 'undefined') ? null : response.customer.profissao;
					this.formField.data.customerData.professionalActivity.show = true;
					this.formField.data.customerData.phone.value =
						typeof this.formField.information.mainAccount.value.phone === 'undefined' ? null : this.formField.information.mainAccount.value.phone;
					this.formField.data.customerData.phone.show = true;
					this.formField.data.customerData.email.value =
						typeof this.formField.information.mainAccount.value.email === 'undefined' ? null : this.formField.information.mainAccount.value.email;
					this.formField.data.customerData.email.show = true;
					this.formField.data.customerData.income.show = true;
					this.formField.data.customerData.show = true;
					this.formField.data.representantData.show = false;
					this.formField.data.debitDeclaration.show = false;
				} else if (
					this.formField.decisionType === 'cancelamento-execucao-caucao' ||
					this.formField.decisionType === 'execucao-caucao-resgate-antecipado' ||
					this.formField.decisionType === 'execucao-caucao-resgate-final'
				) {
					this.formField.data.representantData.show = true;
					this.formField.data.debitDeclaration.show = true;
					this.formField.data.representantData.name.value = null;
					this.formField.data.representantData.cpfcnpj.value = null;
					this.formField.data.customerData.name.value = response.customer.name;
					this.formField.data.customerData.cpfcnpj.value = response.customer.cpfcnpj;
					this.formField.data.customerData.show = false;
				}
			}
		});
	}

	changeDebitValue() {
		const value =
			this.formField.data.resgateValue.value -
			(this.formField.data.debitValue.value !== null && typeof this.formField.data.debitValue.value != 'undefined' ? this.formField.data.debitValue.value : 0) -
			(this.formField.data.propostaValue.value !== null && typeof this.formField.data.propostaValue.value != 'undefined' ? this.formField.data.propostaValue.value : 0);
		if (value < 0) {
			this.formField.data.valueRemain.value = 0;
			this.formField.data.valueRemain.hasError = true;
		} else {
			this.formField.data.valueRemain.value = value;
			this.formField.data.valueRemain.hasError = false;
		}
	}

	addDebit() {
		this.formField.data.debitDeclaration.debitsSelected.value.push({
			venc: $('#vencimentoId').val(),
			comp: this.formField.data.debitDeclaration.competencia.value,
			value: this.formField.data.debitDeclaration.debitValue.value,
			valueString: this.formatMoney(this.formField.data.debitDeclaration.debitValue.value),
			multa: this.formField.data.debitDeclaration.multaValue.value === null ? 0 : this.formField.data.debitDeclaration.multaValue.value,
			multaString: this.formField.data.debitDeclaration.multaValue.value === null ? 'R$ 0,00' : this.formatMoney(this.formField.data.debitDeclaration.multaValue.value),
			others: this.formField.data.debitDeclaration.othersValue.value === null ? 0 : this.formField.data.debitDeclaration.othersValue.value,
			othersString: this.formField.data.debitDeclaration.othersValue.value === null ? 'R$ 0,00' : this.formatMoney(this.formField.data.debitDeclaration.othersValue.value),
			description: this.formField.data.debitDeclaration.description.value,
			id: this.formField.data.debitDeclaration.debitsSelected.value.length === 0 ?
				1 : this.formField.data.debitDeclaration.debitsSelected.value[this.formField.data.debitDeclaration.debitsSelected.value.length - 1].id + 1
		});
		this.formField.data.debitDeclaration.debitsSelected.show = true;
		this.cleanDebitDeclaration();
		this.sumValues();
	}

	sumValues() {
		if (this.formField.data.debitDeclaration.debitsSelected.value.length > 0) {
			let totalValue = 0;
			let totalMulta = 0;
			let totalAcrescimo = 0;
			for (let i = 0; i < this.formField.data.debitDeclaration.debitsSelected.value.length; i++) {
				totalValue += this.formField.data.debitDeclaration.debitsSelected.value[i].value;
				totalMulta += this.formField.data.debitDeclaration.debitsSelected.value[i].multa;
				totalAcrescimo += this.formField.data.debitDeclaration.debitsSelected.value[i].others;
			}
			this.formField.data.debitDeclaration.totalValue.value = totalValue;
			this.formField.data.debitDeclaration.totalMulta = totalMulta;
			this.formField.data.debitDeclaration.totalAcrescimo = totalAcrescimo;
			this.formField.data.debitDeclaration.totalValue.hasError = false;
		} else {
			this.formField.data.debitDeclaration.totalValue.value = 0;
			this.formField.data.debitDeclaration.totalMulta = 0;
			this.formField.data.debitDeclaration.totalAcrescimo = 0;
		}
	}

	removeDebit(id) {
		let index = null;
		for (let i = 0; i < this.formField.data.debitDeclaration.debitsSelected.value.length; i++) {
			if (this.formField.data.debitDeclaration.debitsSelected.value[i].id == id) {
				index = i;
				break;
			}
		}
		if (index !== null) {
			this.formField.data.debitDeclaration.debitsSelected.value.splice(index, 1);
			if (this.formField.data.debitDeclaration.debitsSelected.value.length === 0) {
				this.formField.data.debitDeclaration.debitsSelected.show = false;
			}
		}
		this.sumValues();
	}

	closeDatepickerOnTabPress(keyEvent) {
		if (keyEvent.which === 9) {
			$('.input-datepicker').datepicker('hide');
		}
	}

	focusVencimento() {
		$('#vencimentoId').focus();
	}

	focusContrato() {
		$('#contratoLocacao').focus();
	}

	isValidDebitToInput() {
		return (
			$('#vencimentoId').val() !== null &&
			this.formField.data.debitDeclaration.competencia.value !== null &&
			this.formField.data.debitDeclaration.debitValue.value !== null &&
			this.formField.data.debitDeclaration.description.value !== null
		);
	}

	cleanDebitDeclaration() {
		$('#vencimentoId').val('');
		this.formField.data.debitDeclaration.vencimento.value = null;
		this.formField.data.debitDeclaration.competencia.value = null;
		this.formField.data.debitDeclaration.debitValue.value = null;
		this.formField.data.debitDeclaration.multaValue.value = null;
		this.formField.data.debitDeclaration.othersValue.value = null;
		this.formField.data.debitDeclaration.description.value = null;
	}

	consultarCrivo() {
		const me = this;
		const cpfCnpj = me.formField.data.representantData.cpfcnpj.value;

		const isPersonal = this.accountUtilService.isValidCpf(cpfCnpj);
		const isCompany = this.accountUtilService.isValidCnpj(cpfCnpj);

		if (isPersonal || isCompany) {
			me.$scope.$emit('changeMainShowLoading', true);

			me.crivoHelper.getRecaptchaLoginCall().then((recaptchaLogin) => {
				console.log('response recaptchaLogin: ', recaptchaLogin);
				grecaptcha.ready(function () {
					grecaptcha.execute(recaptchaLogin, { action: 'submit' }).then(function (token) {
						console.log('---- 2. GTOKEN: ', token);

						me.crivoHelper.consultarCrivo(cpfCnpj, token, (response) => {
							if (!response.isSuccessful) {
								me.resgateService.setErrorMessage(me.crivoHelper.remoteCallErrorMessage);
								me.crivoHelper.setPristine();
							} else {
								me.formField.data.representantData.name.value = isPersonal ? response.req_nome : response.req_razaoSocial;
								me.resgateService.setErrorMessage('');
							}

							me.$scope.$emit('changeMainShowLoading', false);
						});
					});
				});
			});
		} else {
			me.crivoHelper.setPristine();
			me.formField.data.representantData.name.value = null;
			me.resgateService.setErrorMessage('');
		}
	}

	searchProfession(searchString) {
		// console.log('searchProfession');
		const resgateData = this.$parent.resgateData;

		return resgateData.propostaNewApi.searchProfession(searchString, resgateData.formField.information.mainAccount.value.isPersonAccount);
	}

	searchProfessionSelect(item) {
		// console.log('searchProfessionSelect');
		const resgateData = this.$parent.resgateData;
		if (resgateData.formField.data.customerData.professionalActivity.value === null) {
			if (!item) {
				return;
			}
			resgateData.formField.data.customerData.professionalActivity.value = item.originalObject;
			resgateData.formField.data.customerData.professionalActivity.hasError = false;
		}
	}

	searchProfessionFocusOut() {
		// console.log('searchProfessionFocusOut');
		const hasValidInput = this.autoComplete.hasValidInput(this.formField.data.customerData.professionalActivity.value);
		if (!hasValidInput) {
			this.autoComplete.clearInput(this.$scope, 'searchProfession');
		}
	}

	searchProfessionChanged() {
		// console.log('searchProfessionChanged');
		const resgateData = this.$parent.resgateData;

		resgateData.autoComplete.resetSelected(resgateData.formField.data.customerData.professionalActivity, 'value');
	}

	// http://geradormemes.com/media/created/7vusmw.jpg
	formatMoney(value) {
		if (value === 0) {
			return 'R$ 0,00';
		}
		let c = 2;
		let d = ',';
		let t = '.';
		let n = value;
		c = isNaN((c = Math.abs(c))) ? 2 : c;
		d = d == undefined ? '.' : d;
		t = t == undefined ? ',' : t;
		const s = n < 0 ? '-' : '';
		const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
		let j = (j = i.length) > 3 ? j % 3 : 0;
		const frist = j ? `${i.substr(0, j)}${t}` : '';
		const second = i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`);
		const third = c ?
			`${d}${Math.abs(n - i)
					.toFixed(c)
					.slice(2)}` :
			'';
		return `R$ ${s}${frist}${second}${third}`;
	}
}

module.exports = resgateDataController;
